import { configureStore } from "@reduxjs/toolkit";
import searchSlice from "./features/search/slice.js";
import propertySlice from "./features/property/slice.js";
import imageCacheSlice from "./features/imageCache/slice.js";
import userSlice from "./features/user/slice.js";
import proposalSlice from "./features/proposal/slice.js";

export const store = configureStore({
  reducer: {
    search: searchSlice,
    property: propertySlice,
    imageCache: imageCacheSlice,
    user: userSlice,
    proposal: proposalSlice,
  },
  devTools: import.meta.env.MODE !== "production",
});
