import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isFulfilled } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import { MdOutlineEdit } from "react-icons/md";
import { IMAGE_SIZE_LIMIT, UPLOAD_PHOTO_TYPE } from "@src/utils/constants";
import { getInitials, isEmptyAfterTrim } from "@src/utils/misc";
import { uploadPhoto } from "@src/utils/photos";
import { updateUserDetails } from "@src/store/features/user/service";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { Toast } from "@capacitor/toast";

export default function EditProfileForm({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  profileImage,
  setProfileImage,
  onClickOverlay,
}) {
  const dispatch = useDispatch();
  const imageInputRef = useRef(null);
  const user = useSelector((state) => state.user.user);
  const isUpdatingUserDetails = useSelector(
    (state) => state.user.isUpdatingUserDetails,
  );

  const [validationErrors, setValidationErrors] = useState({});
  const [isUploadingPhoto, setIsUploadingPhoto] = useState(false);

  const handleImageChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const isLargerThanLimit = file?.size / (1000 * 1000) > IMAGE_SIZE_LIMIT;
      if (isLargerThanLimit) {
        toast.error(`Image is beyond the ${IMAGE_SIZE_LIMIT}MB limit`);
        return;
      }

      setIsUploadingPhoto(true);
      try {
        const response = await uploadPhoto({
          file,
          type: UPLOAD_PHOTO_TYPE.USER_PROFILE,
        });

        setIsUploadingPhoto(false);
        if (response === "invalid file type" || !response) {
          toast.error("This isn't a supported format. Try a different one?");
          return;
        }
        const image = URL.createObjectURL(file);
        setProfileImage(image);
      } catch (error) {
        toast.error("Failed to change your image");
      }
    }
  };

  const handleEditImageClick = async () => {
    if (isUploadingPhoto) return;

    try {
      const image = await Camera.getPhoto({
        quality: 90,
        webUseInput: true,
        allowEditing: true,
        resultType: CameraResultType.Uri,
        source: CameraSource.Prompt,
      });

      if (image) {
        setIsUploadingPhoto(true);
        // If your `uploadPhoto` function expects a file, you may need to fetch the image data.
        const response = await uploadPhoto({
          file: new File(
            [await fetch(image.webPath).then((res) => res.blob())],
            image.path?.split("/").pop() || String(Math.random()), // You can customize the name as needed
            { type: `image/${image.format}` }, // Ensure the type matches your file format
          ),
          type: UPLOAD_PHOTO_TYPE.USER_PROFILE,
        });

        console.log("response xm", response);

        setIsUploadingPhoto(false);

        if (response === "invalid file type" || !response) {
          toast.error("This isn't a supported format. Try a different one?");

          return;
        }

        setProfileImage(response);
      }
    } catch (error) {
      if (error.message !== "User cancelled photos app") {
        console.error("Error capturing photo:", error);
      }
      setIsUploadingPhoto(false);
    }

    //imageInputRef.current.click();
  };

  const validateNames = () => {
    const errors = {};
    let isValid = true;
    if (isEmptyAfterTrim(firstName)) {
      isValid = false;
      errors.firstName = "Please enter first name";
    }
    if (isEmptyAfterTrim(lastName)) {
      isValid = false;
      errors.lastName = "Please enter last name";
    }
    setValidationErrors(errors);
    return isValid;
  };

  const handleSave = async () => {
    if (!validateNames() || isUpdatingUserDetails) return;
    const updatedUserInfo = {};

    if (firstName !== user?.first_name) {
      updatedUserInfo.first_name = firstName.trim();
    }

    if (lastName !== user?.last_name) {
      updatedUserInfo.last_name = lastName.trim();
    }

    if (profileImage !== user?.profile_picture_url) {
      updatedUserInfo.profile_picture_url = profileImage;
    }

    if (Object.keys(updatedUserInfo)?.length) {
      const response = await dispatch(updateUserDetails(updatedUserInfo));
      if (isFulfilled(response)) {
        // toast.success("Profile updated successfully!");
        const showHelloToast = async () => {
          await Toast.show({
            text: "Profile updated successfully!",
          });
        };
        showHelloToast();
        onClickOverlay();
      } else {
        toast.error("We couldn't update your profile.");
      }
    } else {
      onClickOverlay();
    }
  };

  return (
    <div className="flex flex-col items-center gap-4">
      <p className="w-full border-b border-b-neutral-200 py-3 pb-2.5 text-center font-attention text-xl text-neutral-700">
        Edit Profile
      </p>
      <main className="flex w-full flex-col items-center p-6">
        <div
          className="relative flex h-36 w-36 items-center justify-center rounded-full border-2 bg-sage-400 shadow-lg"
          onClick={handleEditImageClick}
        >
          {profileImage ? (
            <img
              src={profileImage}
              className="z-10 h-full w-full rounded-full object-cover"
              alt="profile-image"
            />
          ) : null}
          <p className="absolute font-content text-5xl text-grey-700">
            {getInitials({
              firstName:
                user?.first_name || user?.last_name
                  ? user?.first_name
                  : user?.email,
              lastName: user?.last_name,
            })}
          </p>
          <div className="absolute bottom-1 right-1.5 z-20 flex aspect-square h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-black/60">
            <MdOutlineEdit className="h-5 w-5 fill-white" />
          </div>
          <input
            type="file"
            accept=".png, .jpg, .jpeg, .gif"
            ref={imageInputRef}
            onChange={handleImageChange}
            hidden
          />
          {isUploadingPhoto ? (
            <div className="absolute z-10 flex h-32 w-32 animate-pulse items-center justify-center rounded-full bg-black/60 text-white">
              <div className="h-8 w-8 animate-spin rounded-full border-4 border-neutral-600 border-r-primary-500" />
            </div>
          ) : null}
        </div>
        <div className="mt-5 w-full">
          <label
            htmlFor="firstName"
            className="text-sm font-medium text-neutral-700"
          >
            First Name
          </label>
          <div className="mt-1 rounded-md ring-0 ring-transparent transition-shadow focus-within:ring-2 focus-within:ring-[#8E9991]/40 focus-within:ring-offset-2">
            <input
              id="firstName"
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className="peer inline-block w-full resize-none rounded-md border border-[#8E9991] p-3 font-content outline-0 transition-all focus:border-[#8E9991]/50"
              placeholder="First name"
              disabled={isUpdatingUserDetails}
            />
          </div>

          {validationErrors?.firstName ? (
            <motion.div
              key="popup-container"
              initial={{ opacity: 0, scale: 1, y: -10 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
            >
              <p className="mt-1 text-sm text-red-500">
                {validationErrors?.firstName}
              </p>
            </motion.div>
          ) : null}
        </div>

        <div className="mt-3 w-full">
          <label
            htmlFor="lastName"
            className="text-sm font-medium text-neutral-700"
          >
            Last Name
          </label>
          <div className="mt-1 rounded-md ring-0 ring-transparent transition-shadow focus-within:ring-2 focus-within:ring-[#8E9991]/40 focus-within:ring-offset-2">
            <input
              id="lastName"
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="peer inline-block w-full resize-none rounded-md border border-[#8E9991] p-3 font-content outline-0 transition-all focus:border-[#8E9991]/50"
              placeholder="Last name"
              disabled={isUpdatingUserDetails}
            />
          </div>

          {validationErrors?.lastName ? (
            <motion.div
              key="popup-container"
              initial={{ opacity: 0, scale: 1, y: -10 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
            >
              <p className="mt-1 text-sm text-red-500">
                {validationErrors?.lastName}
              </p>
            </motion.div>
          ) : null}
        </div>

        <div
          className={`${validationErrors?.lastName ? "mt-2" : "mt-8"} flex w-full flex-row gap-5`}
        >
          <button
            className="mx-auto flex w-full justify-center gap-2 rounded-md bg-primary-500 p-3 px-6 pl-4 font-semibold text-white"
            onClick={handleSave}
            disabled={isUpdatingUserDetails}
          >
            {isUpdatingUserDetails ? (
              <div className="h-6 w-6 animate-spin rounded-full border border-2 border-black/10 border-t-white transition duration-100" />
            ) : (
              "Save"
            )}
          </button>
          <button
            className="mx-auto flex w-full justify-center gap-2 rounded-md bg-neutral-100 p-3 px-6 pl-4 font-semibold text-neutral-500"
            onClick={onClickOverlay}
            disabled={isUpdatingUserDetails}
          >
            Cancel
          </button>
        </div>
      </main>
    </div>
  );
}
