function SparkleIcon({className}) {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 12 12">
            <path className="st0" d="M6,0.8c0.2,0,0.4,0.2,0.5,0.4l0.6,2C7.3,4,8,4.7,8.9,4.9l2,0.6c0.3,0.1,0.4,0.4,0.4,0.6c0,0.2-0.2,0.3-0.4,0.4
	l-2,0.6C8,7.3,7.3,8,7.1,8.9l-0.6,2c-0.1,0.3-0.4,0.4-0.6,0.4c-0.2,0-0.3-0.2-0.4-0.4l-0.6-2C4.7,8,4,7.3,3.1,7.1l-2-0.6
	C0.9,6.4,0.7,6.1,0.8,5.9c0-0.2,0.2-0.3,0.4-0.4l2-0.6C4,4.7,4.7,4,4.9,3.1l0.6-2C5.6,0.9,5.8,0.8,6,0.8z"/>
</svg>
    )
}

export default SparkleIcon
