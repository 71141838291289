function MenuIcon({ className }) {
  return (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 12 12"
    >
      <path className="st0" d="M0,0h12v12H0V0z" fill="none" />
      <path d="M1.5,7.8h9v-1h-9V7.8z M1.5,5.2h9v-1h-9V5.2z" />
    </svg>
  );
}

export default MenuIcon;
