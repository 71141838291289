import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { App } from "@capacitor/app";

//This handles native app deep linking
const AppUrlListener = () => {
  const navigate = useNavigate();

  useEffect(() => {
    let listener;

    const setupListener = async () => {
      listener = await App.addListener("appUrlOpen", (event) => {
        const slug = event.url.split(".com").pop();
        if (slug) {
          navigate(slug);
        }
      });
    };

    setupListener();

    // Clean up the listener on component unmount
    return () => {
      if (listener && typeof listener.remove === "function") {
        listener.remove();
      }
    };
  }, [navigate]);

  return null;
};

export default AppUrlListener;
