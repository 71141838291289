import SelectArchitecturalStyles from "@src/components/selectArchitecturalStyles/SelectArchitecturalStyles.jsx";

const edit_screens = [
  {
    owner_id: "cover_photo_url",
    label: "Cover Photo",
    prompt: {
      title: "Let's add a cover photo that captures your home’s unique charm.",
      description: "",
    },
  },
  {
    owner_id: "owner_given_style",
    label: "Architectural Style",
    prompt: {
      title: "What is your home’s architectural style?",
      description: "",
    },
  },
  {
    owner_id: "owner_perks",
    label: "Perks",
    prompt: {
      title: "What bonus features make your home special?",
      description: "",
    },
  },
  {
    owner_id: "owner_ownership_cycle",
    label: "Ownership Phase",
    prompt: {
      title: "Which phase of homeownership are you in?",
      description: "",
    },
  },
  {
    owner_id: "owner_selling_choice",
    label: "Timeline",
    prompt: {
      title: "When if ever, do you plan to sell your home?",
      description: "",
    },
  },
  {
    owner_id: "owner_expected_value",
    label: "Value",
    prompt: {
      title: "What offer would motivate you to sell today?",
      description:
        "Other real estate sites try to estimate your home's worth. Unlisted lets you decide.",
    },
  },
];

export default edit_screens;
