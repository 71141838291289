import BaseModal from "@src/components/baseModal/BaseModal";
import { TermsOfUse } from "@src/pages";

export default function TermsOfUseModal({ open, onClickOverlay }) {
  return (
    <BaseModal open={open} onClickOverlay={onClickOverlay} contentWrapperStyles="bg-white pt-4 overflow-y-scroll">
      <TermsOfUse />
    </BaseModal>
  );
}
