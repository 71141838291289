function FAQIcon({className}) {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
            <path
                d="M479.85-311.54q13.3 0 22.42-9.11 9.11-9.12 9.11-22.43 0-13.3-9.11-22.42-9.12-9.12-22.42-9.12-13.31 0-22.43 9.12-9.11 9.12-9.11 22.42 0 13.31 9.11 22.43 9.12 9.11 22.43 9.11ZM459.23-444h38.62q.77-13.15 2.65-22.85 1.88-9.69 6.88-19.15 5-9.46 12.89-18.96 7.88-9.5 19.58-21.19 28.84-28.85 41.8-50.04 12.97-21.19 12.97-48.12 0-46.84-32.16-75.5-32.15-28.65-78.54-28.65-42.69 0-73.5 22.77-30.8 22.77-45.04 54.61l36.77 15.23q10.08-23.92 28.77-39.65 18.7-15.73 50.54-15.73 37.77 0 56.16 20.65Q556-649.92 556-623.62q0 20.08-11 36.77-11 16.7-30.85 35.08-14.69 14-25.19 26.73-10.5 12.73-17.5 25.42-7 12.7-9.61 26.12-2.62 13.42-2.62 29.5ZM480-96.92 376.92-200h-152.3q-27.62 0-46.12-18.5Q160-237 160-264.62v-510.76q0-27.62 18.5-46.12Q197-840 224.62-840h510.76q27.62 0 46.12 18.5Q800-803 800-775.38v510.76q0 27.62-18.5 46.12Q763-200 735.38-200h-152.3L480-96.92ZM224.62-240h168.92L480-153.54 566.46-240h168.92q10.77 0 17.7-6.92 6.92-6.93 6.92-17.7v-510.76q0-10.77-6.92-17.7-6.93-6.92-17.7-6.92H224.62q-10.77 0-17.7 6.92-6.92 6.93-6.92 17.7v510.76q0 10.77 6.92 17.7 6.93 6.92 17.7 6.92ZM480-520Z"/>
        </svg>
    )
}

export default FAQIcon
