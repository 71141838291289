import { Navigate } from "react-router-dom";
import { getAuthTokenFromStorage } from "@src/utils/misc";

const LoggedInRedirect = ({ children }) => {
  const isLoggedIn = !!getAuthTokenFromStorage();

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  return children;
};

export default LoggedInRedirect;
