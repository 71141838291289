function FeedbackMenuIcon({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 -960 960 960"
      width="24px"
    >
      <path d="M480-113.85q-27.62 0-48.04-18.5-20.42-18.5-24.27-46.11h144.62q-3.85 27.61-24.27 46.11-20.42 18.5-48.04 18.5ZM340-249.23v-40h280v40H340ZM342.31-360q-56.69-37.15-89.5-94.23Q220-511.31 220-580q0-108.85 75.58-184.42Q371.15-840 480-840t184.42 75.58Q740-688.85 740-580q0 68.69-32.81 125.77T617.69-360H342.31ZM354-400h252q45-32 69.5-79T700-580q0-92-64-156t-156-64q-92 0-156 64t-64 156q0 54 24.5 101t69.5 79Zm126 0Z" />
    </svg>
  );
}

export default FeedbackMenuIcon;
