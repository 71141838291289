import { motion } from "framer-motion";
import * as Slider from "@radix-ui/react-slider";
import { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  addProposalVoucher,
  createProposal,
  updateProposal,
} from "@src/api/proposal";
import { createPaymentIntent } from "@src/api/payment";
import PropertyProfileImage from "@src/components/propertyProfile/propertyProfileImage/PropertyProfileImage.jsx";
import { MdArrowForward } from "react-icons/md";
import { BsMailboxFlag } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

export default function ProposalDetails({
  packageOptions,
  proposalFormData,
  setProposalFormData,
  currentProposalData,
  setCurrentProposalData,
  setIsReadyForPayment,
  setPaymentId,
}) {
  const navigate = useNavigate();
  const buyerMessageRef = useRef(null);
  const packageRef = useRef(null);
  const [rangeLabel, setRangeLabel] = useState(
    `I'm looking to move in the next year`,
  );
  const [buyerMessageError, setBuyerMessageError] = useState("");
  const [packageError, setPackageError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const property = useSelector((state) => state.property.currentRoutedProperty);
  const isOwned = property?.owner_id;
  const yearMarkers = ["Now", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const onChangeValue = (event) => {
    setBuyerMessageError("");
    setProposalFormData({
      ...proposalFormData,
      buyerMessage: event.target.value,
    });
  };

  const handleValueChange = (newValue) => {
    setProposalFormData({
      ...proposalFormData,
      timelineMin: newValue[0],
      timelineMax: newValue[1],
    });

    const [start, end] = newValue;

    let label = "";
    if (start === 0 && end === 0) {
      label = `I'm looking to move soon`;
    } else if (start === 0 || start === end) {
      label = `I'm looking to move in the next ${end > 1 ? end : ""} year${end > 1 ? "s" : ""}`;
    } else {
      label = `I'm looking to move in ${start} to ${end} years`;
    }

    setRangeLabel(label);
  };

  const handleProposalSubmit = async (e) => {
    e && e.preventDefault();
    if (proposalFormData.buyerMessage === "") {
      setBuyerMessageError("Please enter your proposal message.");
    }

    /**
     * Since we're not sending a package for properties which are already claimed,
     * we need to check if the package is selected or not only when it is not claimed.
     */
    if (!isOwned) {
      if (!proposalFormData.packageType && packageOptions?.length > 1) {
        setPackageError("Please select a package to send.");
      }
      if (!proposalFormData.packageType && packageOptions?.length === 1) {
        const updatedProposalFormData = {
          ...proposalFormData,
          packageType: packageOptions?.[0]?.id,
        };
        proposalFormData = updatedProposalFormData;
        setProposalFormData(updatedProposalFormData);
      }
    }

    if (proposalFormData.buyerMessage === "") {
      buyerMessageRef?.current?.scrollIntoView({ behavior: "smooth" });
      return;
    }
    if (
      !proposalFormData.packageType &&
      packageOptions?.length > 1 &&
      !isOwned
    ) {
      packageRef?.current?.scrollIntoView({ behavior: "smooth" });
      return;
    }

    try {
      setIsLoading(true);
      let response;
      if (!currentProposalData?.id) {
        response = await createProposal(proposalFormData);

        /**
         * Take the user to the conversation page if the property is owned.
         * Added the paid check just in case.
         */
        if (isOwned && response?.paid) {
          navigate(`/conversation/${response?.id}`, { replace: true });
          return;
        }

        const payment = await createPaymentIntent(response?.id);
        setPaymentId(payment?.payment_id);
      } else if (
        currentProposalData?.package_type !== proposalFormData?.packageType ||
        currentProposalData?.buyer_message !== proposalFormData?.buyerMessage ||
        currentProposalData?.move_timeline_max !==
          proposalFormData?.timelineMax ||
        currentProposalData?.move_timeline_min !== proposalFormData?.timelineMin
      ) {
        response = await updateProposal({
          proposalId: currentProposalData?.id ?? response?.id,
          proposal: {
            package_type:
              packageOptions?.length > 1
                ? proposalFormData?.packageType
                : packageOptions[0]?.id,
            buyer_message: proposalFormData.buyerMessage,
            move_timeline_max: proposalFormData.timelineMax,
            move_timeline_min: proposalFormData.timelineMin,
          },
        });

        if (currentProposalData?.voucher_code) {
          response = await addProposalVoucher({
            proposalId: currentProposalData?.id,
            voucherCode: currentProposalData?.voucher_code,
          });
        }
      }

      if (response?.id) setCurrentProposalData(response);

      const selectedPackageOption = packageOptions.find(
        ({ id }) => id === proposalFormData.packageType,
      );
      const eventParams = {
        currency: "USD",
        value: selectedPackageOption?.price,
        items: [
          {
            item_name: selectedPackageOption?.title,
          },
        ],
      };

      ReactGA.event("begin_checkout", eventParams);
      setIsReadyForPayment(true);
    } catch (error) {
      setIsReadyForPayment(false);
    }

    setIsLoading(false);
  };

  return (
    <>
      <div className="mt-6 w-[min(calc(100%-2rem),50rem)]">
        <section className="relative mb-8 h-full max-h-60 w-full md:max-h-80">
          <div className="h-full w-full overflow-hidden rounded-md">
            {property && <PropertyProfileImage property={property} />}
          </div>

          <div className="absolute bottom-0 left-1/2 flex h-44 w-44 -translate-x-1/2 translate-y-1/2 items-center justify-center rounded-full bg-white bg-opacity-50">
            <div className="flex h-[calc(100%-3rem)] w-[calc(100%-3rem)] items-center justify-center rounded-full bg-white">
              <BsMailboxFlag className="h-10 w-10 text-[#535D56]" />
            </div>
          </div>
        </section>
        <div className="relative z-10">
          {property && (
            <h2 className="flex flex-col gap-1 text-center font-attention">
              <span className="text-xl md:text-2xl">Reach out to</span>
              <div className="flex flex-col gap-1">
                <span className="text-2xl font-semibold capitalize md:text-3xl">
                  {property?.address_line_1?.toLowerCase()}
                </span>
                <span className="text-sm capitalize text-neutral-600 md:text-base">{`${property.city ? property.city.toLowerCase() + ", " : " "}${property.state ? property.state + " " : ""}${property?.zip ? property.zip : ""}`}</span>
              </div>
            </h2>
          )}
        </div>
        <form onSubmit={handleProposalSubmit} className="">
          <div className="mt-12 flex flex-col gap-1 md:gap-4">
            <div ref={buyerMessageRef}>
              <h3 className="mb-3 flex items-center gap-2 font-attention text-neutral-800 md:gap-3">
                <div className="full flex h-6 w-6 items-center justify-center rounded-full bg-primary-500 text-xl text-white md:h-8 md:w-8 md:text-2xl">
                  <span className="-translate-y-[0.15em]">1</span>
                </div>
                <span className="text-xl text-sage-800 md:text-2xl">
                  Introduce yourself
                </span>
              </h3>
              <p className="mt-4 font-content text-neutral-500">
                Write a personal note describing your interest in the home.
                Please do not reveal personal information about yourself or your
                family.
              </p>
              {buyerMessageError ? (
                <motion.div
                  key="popup-container"
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.8 }}
                  transition={{ duration: 0.3 }}
                >
                  <p className="mt-1 text-red-500">{buyerMessageError}</p>
                </motion.div>
              ) : null}
              <textarea
                value={proposalFormData.buyerMessage}
                onChange={onChangeValue}
                placeholder="Your message to the home owner"
                className={`${buyerMessageError ? "mt-2" : "mt-4"} h-[180px] w-full resize-y rounded-md bg-[#fefefe] p-2 p-4 font-content text-sm opacity-80 ring-1 ring-black/20 focus:outline-none`}
              />
            </div>
            <div className="mt-12">
              <h3 className="mb-3 flex items-center gap-2 font-attention text-neutral-800 md:gap-3">
                <div className="full flex h-6 w-6 items-center justify-center rounded-full bg-primary-500 text-xl text-white md:h-8 md:w-8 md:text-2xl">
                  <span className="-translate-y-[0.15em]">2</span>
                </div>
                <span className="text-xl text-sage-800 md:text-2xl">
                  Let them know your timing
                </span>
              </h3>
              <p className="mt-4 font-content text-neutral-500">
                Home planning is often long-term and imprecise. Provide a
                general timeline for your next move to help the owner assess the
                opportunity.
              </p>

              <div className="mt-6 sm:max-w-sm">
                <p className="mb-5 flex font-content font-semibold text-neutral-600">
                  {rangeLabel}
                </p>
                <div className="mb-4 flex justify-between text-xs text-neutral-500">
                  {yearMarkers.map((year, index) => {
                    const isInRange =
                      index >= proposalFormData.timelineMin &&
                      index <= proposalFormData.timelineMax;
                    return (
                      <span
                        key={index}
                        className={`flex-1 text-center font-light ${
                          isInRange ? "text-sage-800" : "text-neutral-400"
                        }`}
                      >
                        {year}
                      </span>
                    );
                  })}
                </div>
                <Slider.Root
                  onValueChange={handleValueChange}
                  min={0}
                  max={10}
                  step={1}
                  // minStepsBetweenThumbs={1}
                  value={[
                    proposalFormData.timelineMin,
                    proposalFormData.timelineMax,
                  ]}
                  className="relative flex h-5 w-full touch-none select-none items-center"
                  defaultValue={[
                    proposalFormData.timelineMin,
                    proposalFormData.timelineMax,
                  ]}
                >
                  <Slider.Track className="relative h-2 grow rounded-full bg-gray-200">
                    <Slider.Range className="absolute h-full rounded-full bg-primary-500" />
                  </Slider.Track>
                  <Slider.Thumb className="block h-7 w-7 rounded-full border border-gray-300 bg-white" />
                  <Slider.Thumb className="block h-7 w-7 rounded-full border border-gray-300 bg-white" />
                </Slider.Root>
              </div>
            </div>
            {!isOwned ? (
              <div className="mt-12" ref={packageRef}>
                <h3 className="mb-3 flex items-center gap-2 font-attention text-neutral-800 md:gap-3">
                  <div className="full flex h-6 w-6 items-center justify-center rounded-full bg-primary-500 text-xl text-white md:h-8 md:w-8 md:text-2xl">
                    <span className="-translate-y-[0.2em]">3</span>
                  </div>
                  <span className="text-xl text-sage-800 md:text-2xl">
                    Sweeten the deal
                  </span>
                </h3>
                <p className="mt-4 font-content text-neutral-500">
                  Include a thoughtful, locally-sourced gift when we deliver
                  your personally crafted note. The owner will receive a unique
                  invitation code to connect directly with you online via
                  Unlisted.
                </p>
                <p className="mt-4 font-content text-neutral-500">
                  Please select a package to send.
                </p>
                <div className="mt-2 flex flex-col gap-4">
                  {packageError ? (
                    <motion.div
                      key="popup-container"
                      initial={{ opacity: 0, scale: 0.8 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 0.8 }}
                      transition={{ duration: 0.3 }}
                    >
                      <p className="text-red-500">{packageError}</p>
                    </motion.div>
                  ) : null}
                  {packageOptions?.map((item) => (
                    <div
                      key={item?.id}
                      onClick={() => {
                        setPackageError("");
                        setProposalFormData({
                          ...proposalFormData,
                          packageType: item?.id,
                        });
                      }}
                      className={`md: relative mt-10 flex w-full flex-col gap-6 overflow-hidden rounded-md ring-1 ring-black/20 transition-all ${proposalFormData.packageType !== item?.id ? "cursor-pointer hover:bg-gold-200/40 hover:shadow-md" : ""} sm:flex-row sm:items-center md:max-w-3xl ${proposalFormData.packageType === item?.id ? "bg-gold-200 shadow-lg" : "bg-white shadow"}`}
                    >
                      <div
                        className={`absolute right-3 top-3 flex h-5 w-5 items-center justify-center rounded-full bg-white ring-1 transition-all ${proposalFormData.packageType === item?.id ? "ring-primary-500/60" : "ring-black/20"}`}
                      >
                        <div
                          className={`h-3 w-3 rounded-full bg-primary-500 transition-all ${proposalFormData.packageType === item?.id ? "block" : "hidden"}`}
                        ></div>
                      </div>
                      {item?.sample_link ? (
                        <img
                          src={item?.sample_link}
                          alt={item?.title}
                          className="w-full border-b border-b-black/20 sm:max-w-xs sm:border-r sm:border-b-transparent sm:border-r-black/20"
                        />
                      ) : null}
                      <div className="px-5 pb-5 sm:pt-5">
                        <h4 className="mb-2 font-attention text-xl text-sage-800">
                          {item?.title}
                        </h4>
                        <p className="text-balance font-content text-sm text-neutral-500">
                          {item?.display_text}
                        </p>
                        <p className="mt-4 font-attention text-xl text-grey-500">
                          ${item?.price}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
          <div className="mt-12 border-t border-t-neutral-300 pt-6">
            <button
              type="submit"
              value="Submit"
              disabled={isLoading}
              className="relative flex w-full cursor-pointer justify-center gap-2 rounded-md bg-primary-500 p-3 px-5 pr-4 text-sm font-semibold text-white shadow-md shadow-sage-700/20 disabled:pointer-events-none disabled:opacity-40 disabled:shadow-none sm:max-w-fit"
            >
              {isLoading && (
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                  <div className="h-6 w-6 animate-spin rounded-full border border-2 border-black/10 border-t-white transition duration-100" />
                </div>
              )}
              <span className={`flex gap-2 ${isLoading ? "opacity-0" : ""}`}>
                Continue to {isOwned ? "chat" : "payment"}
                <MdArrowForward className="text-xl" />
              </span>
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
