export default function LockIconOutlined({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <path
        d="M9.92325 31.5C9.252 31.5 8.68025 31.264 8.208 30.792C7.736 30.3198 7.5 29.748 7.5 29.0768V15.9233C7.5 15.252 7.736 14.6803 8.208 14.208C8.68025 13.736 9.252 13.5 9.92325 13.5H12V10.5C12 8.82875 12.5823 7.411 13.7468 6.24675C14.911 5.08225 16.3288 4.5 18 4.5C19.6713 4.5 21.089 5.08225 22.2533 6.24675C23.4178 7.411 24 8.82875 24 10.5V13.5H26.0768C26.748 13.5 27.3198 13.736 27.792 14.208C28.264 14.6803 28.5 15.252 28.5 15.9233V29.0768C28.5 29.748 28.264 30.3198 27.792 30.792C27.3198 31.264 26.748 31.5 26.0768 31.5H9.92325ZM9.92325 30H26.0768C26.346 30 26.5673 29.9135 26.7405 29.7405C26.9135 29.5673 27 29.346 27 29.0768V15.9233C27 15.654 26.9135 15.4327 26.7405 15.2595C26.5673 15.0865 26.346 15 26.0768 15H9.92325C9.654 15 9.43275 15.0865 9.2595 15.2595C9.0865 15.4327 9 15.654 9 15.9233V29.0768C9 29.346 9.0865 29.5673 9.2595 29.7405C9.43275 29.9135 9.654 30 9.92325 30ZM18 24.75C18.6328 24.75 19.1659 24.5331 19.5994 24.0994C20.0331 23.6659 20.25 23.1328 20.25 22.5C20.25 21.8673 20.0331 21.3341 19.5994 20.9006C19.1659 20.4669 18.6328 20.25 18 20.25C17.3673 20.25 16.8341 20.4669 16.4006 20.9006C15.9669 21.3341 15.75 21.8673 15.75 22.5C15.75 23.1328 15.9669 23.6659 16.4006 24.0994C16.8341 24.5331 17.3673 24.75 18 24.75ZM13.5 13.5H22.5V10.5C22.5 9.25 22.0625 8.1875 21.1875 7.3125C20.3125 6.4375 19.25 6 18 6C16.75 6 15.6875 6.4375 14.8125 7.3125C13.9375 8.1875 13.5 9.25 13.5 10.5V13.5Z"
        fill="#979797"
      />
    </svg>
  );
}
