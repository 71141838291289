import { getRequest, patchRequest, postRequest } from "@src/utils/axiosClient";

export async function createProposal(proposal) {
  try {
    const response = await postRequest({
      url: "/proposals/",
      payload: {
        buyer_message: proposal.buyerMessage,
        move_timeline_max: proposal.timelineMax,
        move_timeline_min: proposal.timelineMin,
        package_type: proposal.packageType,
        property_id: proposal.propertyId,
      },
    });

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function updateProposal({ proposalId, proposal }) {
  try {
    const response = await patchRequest({
      url: `/proposal/${proposalId}`,
      payload: proposal,
    });

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function getProposalPackages() {
  try {
    const response = await getRequest({ url: "/packages/" });

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function completeProposalWithZeroPayment(proposalId) {
  try {
    const response = await postRequest({
      url: `/proposals/${proposalId}/complete/`,
    });

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function addProposalVoucher({ proposalId, voucherCode }) {
  try {
    const response = await postRequest({
      url: `/proposals/${proposalId}/add_voucher/`,
      payload: { voucher_code: voucherCode },
    });

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function removeProposalVoucher(proposalId) {
  try {
    const response = await postRequest({
      url: `/proposals/${proposalId}/remove_voucher/`,
    });

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function getLatestProposalOfProperty({ propertyId, paid = true }) {
  try {
    const response = await getRequest({
      url: "/api/v1/proposals/latest/",
      params: { property_id: propertyId, paid },
    });

    return response.data;
  } catch (error) {
    /**
     * Returning the error instead of "throw" is intentional
     * If the proposal does not exist, the Backend returns a 404 error
     * Which fails the promise and the service goes into the error block
     * That's not what we want, so we return the error and let the service handle it
     */
    return error.response.data;
  }
}
