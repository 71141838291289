import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getRoutedPropertyById,
  getDisplayPropertyById,
  updateProperty,
  updatePropertyMainImage,
} from "./service.js";

const initialState = {
  currentDisplayProperty: null,
  currentRoutedProperty: null,
  currentEditableItemId: null,
  isShowingEditableItemPanel: false,
  isLoadingRoutedProperty: true,
  showConfetti: false,
};

// Create an async thunk to fetch a property by id

// Create a slice
export const slice = createSlice({
  name: "property",
  initialState,
  reducers: {
    // setCurrentDisplayProperty: (state, action) => {
    //   state.currentDisplayProperty = action.payload;
    // },

    setCurrentEditableItemId: (state, action) => {
      state.currentEditableItemId = action.payload;
    },

    setPropertyPropValue: (state, action) => {
      if (state.currentDisplayProperty) {
        state.currentDisplayProperty[action.payload.key] = action.payload.value;
      }
      if (state.currentRoutedProperty) {
        state.currentRoutedProperty[action.payload.key] = action.payload.value;
      }
    },

    openEditableItemPanel: (state) => {
      state.isShowingEditableItemPanel = true;
    },

    closeEditableItemPanel: (state) => {
      state.isShowingEditableItemPanel = false;
    },
    setPropertyLikedStatus: (state, { payload }) => {
      if (state.currentDisplayProperty?.id === payload.propertyId) {
        state.currentDisplayProperty.liked = payload.liked;
      }
      if (state.currentRoutedProperty?.id === payload.propertyId) {
        state.currentRoutedProperty.liked = payload.liked;
      }
    },
    setPropertyOwner: (state, { payload }) => {
      if (state.currentDisplayProperty?.id === payload.propertyId) {
        state.currentDisplayProperty.owner_id = payload.ownerId;
      }
      if (state.currentRoutedProperty?.id === payload.propertyId) {
        state.currentRoutedProperty.owner_id = payload.ownerId;
      }
    },
    changePropertyPhotoUrl: (state, { payload }) => {
      if (state.currentDisplayProperty?.photos[payload]) {
        state.currentDisplayProperty.photos[payload] =
          state.currentDisplayProperty.photos[payload]?.replace?.(
            /-(large|medium|thumbnail)$/,
            "",
          );
      }
      if (state.currentRoutedProperty?.photos[payload]) {
        state.currentRoutedProperty.photos[payload] =
          state.currentRoutedProperty.photos[payload]?.replace?.(
            /-(large|medium|thumbnail)$/,
            "",
          );
      }
    },

    setShowConfetti: (state, action) => {
      state.showConfetti = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDisplayPropertyById.fulfilled, (state, action) => {
        state.currentDisplayProperty = action.payload;
      })
      .addCase(getRoutedPropertyById.pending, (state) => {
        state.isLoadingRoutedProperty = true;
      })
      .addCase(getRoutedPropertyById.fulfilled, (state, action) => {
        state.isLoadingRoutedProperty = false;
        state.currentRoutedProperty = action.payload;
      })
      .addCase(getRoutedPropertyById.rejected, (state) => {
        state.isLoadingRoutedProperty = false;
      })
      .addCase(updateProperty.fulfilled, (state, action) => {})
      .addCase(updatePropertyMainImage.fulfilled, (state, action) => {
        console.log("returned in the slice", action.payload);
        if (state.currentDisplayProperty) {
          state.currentDisplayProperty.cover_photo_url = action.payload.get_url;
        }
        if (state.currentRoutedProperty) {
          state.currentRoutedProperty.cover_photo_url = action.payload.get_url;
        }
      });
  },
});

export const {
  setCurrentDisplayProperty,
  setCurrentEditableItemId,
  openEditableItemPanel,
  closeEditableItemPanel,
  setPropertyPropValue,
  setPropertyLikedStatus,
  setPropertyOwner,
  changePropertyPhotoUrl,
  setShowConfetti,
} = slice.actions;

export default slice.reducer;
