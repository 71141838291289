import React, { useEffect, useRef, useState } from "react";
import { formatNumber } from "@src/utils/misc.js";

function PropertyProfileEditorTexAreaInput({
  id,
  value = "",
  onChange,
  placeholder = "",
}) {
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState(value);
  const [textareaHeight, setTextareaHeight] = useState("auto");

  // Sync the prop value with internal state whenever the value changes
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    // if (inputRef.current) {
    //   inputRef.current.focus();
    // }
    setInputValue(value);
  }, []);

  useEffect(() => {
    const handleViewportResize = () => {
      const viewportHeight = window.visualViewport.height;

      if (viewportHeight < window.innerHeight * 0.7) {
        setTextareaHeight(`${viewportHeight * 0.6}px`);
      } else {
        setTextareaHeight(`${viewportHeight * 0.4}px`);
      }
    };

    if (window.visualViewport) {
      window.visualViewport.addEventListener("resize", handleViewportResize);
    }

    handleViewportResize();

    return () => {
      if (window.visualViewport) {
        window.visualViewport.removeEventListener(
          "resize",
          handleViewportResize,
        );
      }
    };
  }, []);

  const handleInputChange = (e) => {
    setInputValue(e.target.value); // Update internal state
    if (onChange) {
      onChange(e.target.value); // Call the parent onChange handler (Redux action or other)
    }
  };

  return (
    <div className="mx-auto w-[min(calc(100%-5rem),28rem)]">
      <textarea
        ref={inputRef}
        rows="5"
        onChange={handleInputChange}
        placeholder={placeholder}
        className="mt-7 w-[min(calc(100%-5rem),28rem)] w-full resize-none whitespace-normal text-wrap rounded-sm p-3 font-content text-neutral-900 shadow-md shadow-neutral-100 outline-none ring-1 ring-neutral-300 transition-all focus:ring-primary-500"
        id={id}
        value={inputValue}
        style={{ height: textareaHeight }}
      />
    </div>
  );
}

export default PropertyProfileEditorTexAreaInput;
