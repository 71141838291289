import React from "react";
import { MdChevronRight } from "react-icons/md";
import SelectableTag from "./SelectableTag.jsx";

function TagsValueDisplay({ label, value = [], onClick }) {
  return (
    <button
      className="flex cursor-pointer flex-row items-center justify-between gap-4"
      onClick={() => onClick()}
    >
      <div className="flex flex-col gap-3">
        <header className="flex flex-row justify-between">
          <label className="font-semibold text-gray-600">{label}</label>
        </header>
        {value.length === 0 && (
          <p className="text-left text-gray-500">None Selected</p>
        )}
        {value.length > 0 && (
          <div className="flex flex-row flex-wrap gap-2">
            {value.map((tag, index) => {
              return (
                <SelectableTag emoji={tag.emoji} text={tag.text} key={index} />
              );
            })}
          </div>
        )}
      </div>
      <MdChevronRight className="shrink-0 self-center text-2xl text-neutral-500" />
    </button>
  );
}

export default TagsValueDisplay;
