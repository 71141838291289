import React from "react";
import { motion } from "framer-motion";

export default function SavedChangesIcon({ className }) {
  const pathVariants = {
    hidden: {
      y: "-100%",
      opacity: 0,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.25,
      },
    },
  };
  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      className={className}
    >
      <motion.path
        variants={pathVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        d="m425-407-85.56-85.56Q328.5-503.5 313-503.5q-15.5 0-26.5 11t-11 26.5q0 15.5 10.82 26.32L398.5-327.5q11.32 11.5 26.41 11.5t26.59-11.5l222-222q11-11 11-26.5t-11-26.5q-11-11-26.5-11t-26.37 10.87L425-407Zm55 317q-80.91 0-152.07-30.76-71.15-30.77-123.79-83.5Q151.5-257 120.75-328.09 90-399.17 90-480q0-80.91 30.76-152.07 30.77-71.15 83.5-123.79Q257-808.5 328.09-839.25 399.17-870 480-870q80.91 0 152.07 30.76 71.15 30.77 123.79 83.5Q808.5-703 839.25-631.91 870-560.83 870-480q0 80.91-30.76 152.07-30.77 71.15-83.5 123.79Q703-151.5 631.91-120.75 560.83-90 480-90Zm0-75q131.5 0 223.25-91.75T795-480q0-131.5-91.75-223.25T480-795q-131.5 0-223.25 91.75T165-480q0 131.5 91.75 223.25T480-165Zm0-315Z"
        stroke="black" // Add a stroke color
        strokeWidth="2" // Adjust stroke width as needed
      />
    </motion.svg>
  );
}
