import { createAsyncThunk } from "@reduxjs/toolkit";
import { getProposalData } from "@src/api/chat";

export const getProposalDetails = createAsyncThunk(
  "proposal/proposal_details",
  async (proposalId, { rejectWithValue }) => {
    try {
      const response = await getProposalData(proposalId);

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
