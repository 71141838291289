import React from "react";

function BorderFlourish({ className }) {
  return (
    <div className={className}>
      <div className="absolute inset-x-[25%] inset-y-1 z-0 bg-white"></div>
      <svg
        width="69"
        height="17"
        viewBox="0 0 69 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        stroke="currentColor"
        className="relative"
      >
        <g clipPath="url(#clip0_2598_11296)">
          {/*<path*/}
          {/*  d="M0.404785 8.5H21.6732"*/}
          {/*  strokeWidth="1"*/}
          {/*  strokeMiterlimit="10"*/}
          {/*  strokeLinecap="round"*/}
          {/*  strokeLinejoin="round"*/}
          {/*/>*/}
          <path
            d="M21.6521 3.99593L17.1509 8.49719L21.6521 12.9985L26.1534 8.49719L21.6521 3.99593Z"
            strokeWidth="1"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28.4864 1.68307L21.6694 8.5L28.4864 15.3169L35.3033 8.5L28.4864 1.68307Z"
            strokeWidth="1"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M34.2647 0.38544L26.1729 8.47729L34.2647 16.5692L42.3566 8.47729L34.2647 0.38544Z"
            strokeWidth="1"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g clipPath="url(#clip1_2598_11296)">
          {/*<path*/}
          {/*  d="M68.353 8.5L47.0846 8.5"*/}
          {/*  strokeWidth="1"*/}
          {/*  strokeMiterlimit="10"*/}
          {/*  strokeLinecap="round"*/}
          {/*  strokeLinejoin="round"*/}
          {/*/>*/}
          <path
            d="M47.1057 13.0041L51.6069 8.50281L47.1057 4.00155L42.6044 8.50281L47.1057 13.0041Z"
            strokeWidth="1"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M40.2714 15.3169L47.0884 8.5L40.2714 1.68307L33.4545 8.5L40.2714 15.3169Z"
            strokeWidth="1"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M34.4931 16.6146L42.585 8.52271L34.4931 0.430849L26.4013 8.5227L34.4931 16.6146Z"
            strokeWidth="1"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_2598_11296">
            <rect width="42.7576" height="17" fill="white" />
          </clipPath>
          <clipPath id="clip1_2598_11296">
            <rect
              width="42.7576"
              height="17"
              fill="white"
              transform="translate(68.7578 17) rotate(-180)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

export default BorderFlourish;
