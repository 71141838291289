import React from "react";

function ArchitecturalStyleIcon({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 -960 960 960"
      width="24px"
      fill="currentColor"
    >
      <path d="M200-160v-365.77l-77.38 59.62-24.16-31.7L200-576v-105.54h40V-607l240-183 381.54 292.15-24.16 31.47L760-525.77V-160H200Zm40-40h184.62v-190.77h110.76V-200H720v-356L480-739 240-556v356Zm-40-529.23q0-36.15 23.08-61.92 23.07-25.77 66.15-25.77 26.23 0 37.73-13.81 11.5-13.81 11.5-33.89h40q0 36.16-23.08 61.93-23.07 25.77-66.15 25.77-26.23 0-37.73 13.8-11.5 13.81-11.5 33.89h-40ZM240-200h480-480Z" />
    </svg>
  );
}

export default ArchitecturalStyleIcon;
