const selling_choices = [
  {
    id: 1,
    title: "I'm in my forever home",
  },
  {
    id: 2,
    title: "I might be ready in a few years",
  },
  {
    id: 3,
    title: "I'm not sure",
  },
  {
    id: 4,
    title: "I'd love to sell my home soon",
  },
];

export default selling_choices;
