import React from "react";

function ZillowLogo({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 110 24.09"
    >
      <script xmlns="" />
      <g clipPath="url(#a)">
        <path
          d="M14.29 7.345c.3-.067.614.049.795.297l1.11 1.508a.774.774 0 0 1-.167 1.085c-2.277 1.674-4.744 3.9-6.163 5.526-.034.038-.009.044.014.035 2.525-.935 8.329-2.315 11.124-2.702V9.608l.003.002c0-.456-.21-.894-.57-1.18l-.185-.148-8.807-6.96a1.507 1.507 0 0 0-1.865 0S3.158 6.387.57 8.43C.21 8.716 0 9.15 0 9.609v2.84C6.255 9.44 12.573 7.731 14.29 7.344Z"
          fill="#0041D9"
        />
        <path
          d="M5.915 20.835a.77.77 0 0 1-.889-.214 86.356 86.356 0 0 1-1.272-1.565.778.778 0 0 1 .02-.992c1.221-1.41 4.872-5.362 6.55-6.478.037-.024.027-.048-.014-.038-2.057.555-8.022 2.766-10.31 3.888v6.72c0 .832.672 1.504 1.505 1.504H19.5c.83 0 1.505-.672 1.505-1.502v-5.422c-3.065.433-11.066 2.415-15.09 4.102v-.003Z"
          fill="#0041D9"
        />
        <path
          d="M49.753 9.274h-4.182v14.388h4.182V9.274ZM26.892 23.662v-2.357l9.71-13.58v-.281h-9.543v-3.76h15.79V6.04l-9.542 13.582v.28h9.822v3.76H26.892v.001Zm25.87 0V2.982h4.168v20.68h-4.168Zm7.186 0V2.982h4.168v20.68h-4.168Zm6.036-7.186c0-4.622 3.173-7.618 7.977-7.618 4.803 0 7.976 2.996 7.978 7.618 0 4.648-3.202 7.618-7.977 7.618s-7.978-2.995-7.978-7.617v-.001Zm11.769 0c0-2.382-1.574-4.061-3.792-4.061s-3.82 1.68-3.82 4.062c0 2.381 1.574 4.09 3.821 4.09 2.248 0 3.792-1.71 3.792-4.09l-.002-.001Zm8.505 7.186L81.938 9.29h4.235l2.917 10.302h.28L91.92 9.29h4.152l2.748 10.3h.28l2.859-10.303h4.123l-4.346 14.372h-5.413l-2.188-9.514h-.28l-2.16 9.516h-5.44.002Zm21.875-15.547h-.18V6.82h.608c.305 0 .503.155.503.393 0 .22-.186.374-.446.386h-.024l.507.516h-.228l-.484-.507h-.257v.507l.001-.001Zm0-1.147v.494h.428c.2 0 .323-.094.323-.248 0-.153-.123-.245-.323-.245h-.428v-.001Z"
          fill="#111116"
        />
        <path
          d="M108.457 9.013a1.541 1.541 0 0 1-1.522-1.244 1.541 1.541 0 0 1 .424-1.395 1.537 1.537 0 0 1 1.394-.424 1.54 1.54 0 0 1 1.216 1.216 1.532 1.532 0 0 1-.424 1.394 1.53 1.53 0 0 1-1.091.453h.003Zm-.004-2.874a1.32 1.32 0 0 0-.939.389 1.325 1.325 0 0 0-.364 1.2 1.329 1.329 0 0 0 2.243.678 1.329 1.329 0 0 0-.942-2.269l.002.002Zm-63.03-.936c0-1.25.932-2.177 2.24-2.177 1.307 0 2.238.926 2.238 2.177 0 1.252-.931 2.177-2.239 2.177-1.307 0-2.239-.901-2.24-2.177h.001Z"
          fill="#111116"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h110v24H0z" />
        </clipPath>
      </defs>
      <script xmlns="" />
    </svg>
  );
}

export default ZillowLogo;
