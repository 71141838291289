import OtpInput from "react-otp-input";

export default function OtpInputField({
  value,
  setValue,
  numberOfInputs = 6,
  className,
  separator,
}) {
  return (
    <OtpInput
      value={value}
      onChange={setValue}
      numInputs={numberOfInputs}
      renderSeparator={separator}
      renderInput={(props) => <input {...props} className={className} />}
    />
  );
}
