import { Fragment, useMemo } from "react";
import MoreListItem from "@src/components/moreListItem/MoreListItem";
import { useSelector } from "react-redux";

function More() {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  const items = useMemo(
    () => [
      {
        itemName: "My account",
        navigationUrl: "/user-profile?backTo=more",
        loginRequired: true,
      },
      // {
      //   itemName: "About",
      //   navigationUrl: "/about?backTo=more",
      //   loginRequired: true,
      // },
      {
        itemName: "FAQs",
        navigationUrl: "/faqs?backTo=more",
        loginRequired: true,
      },
      {
        itemName: "Privacy policy",
        navigationUrl: "/privacypolicy?backTo=more",
        loginRequired: false,
      },
      {
        itemName: "California privacy policy",
        navigationUrl: "/californiaprivacypolicy?backTo=more",
        loginRequired: false,
      },
      {
        itemName: "Terms and conditions",
        navigationUrl: "/terms-of-use?backTo=more",
        loginRequired: false,
      },
      {
        itemName: "Report abuse",
        navigationUrl: "/reportabuse?backTo=more",
        loginRequired: false,
      },
      {
        itemName: "Contact Us",
        navigationUrl: "/contactus?backTo=more",
        loginRequired: false,
      },
    ],
    [],
  );

  return (
    <div className="mx-auto w-full px-6 pt-20 md:max-w-5xl">
      <h2 className="text-neutral-70 mt-4 font-attention text-2xl">More</h2>
      <div className="mt-6">
        {items.map((item, index) => (
          <Fragment key={index}>
            {item.loginRequired && !isLoggedIn ? null : (
              <MoreListItem
                key={index}
                itemName={item.itemName}
                navigationUrl={item.navigationUrl}
              />
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
}

export default More;
