import React from "react";
import BackButtonLink from "@src/components/backButtonLink/BackButtonLink.jsx";

function ContactUs() {
  return (
    <>
      <BackButtonLink className="pt-24" />
      <div className="mx-auto w-full px-6 pb-12 pt-32 md:max-w-4xl">
        <h1 className="py-2 font-attention text-3xl text-gray-900">
          Contact Us
        </h1>
        <p className="py-2 font-content text-sm font-normal text-gray-900">
          Have feedback? Want to learn more? Need some help using Unlisted? We’d
          love to hear from you. Our team monitors this email address daily and
          will reply within 1-2 business days.
        </p>
        <p className="py-2 font-content text-sm font-normal text-gray-900">
          Email:{" "}
          <a
            href="mailto:hello@unlistedhomes.com"
            className="text-sage-600 underline"
          >
            hello@unlistedhomes.com
          </a>
        </p>
      </div>
    </>
  );
}

export default ContactUs;
