import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

import bubbleMailerBackground from "@src/assets/bubble_mailer_background.png";
import OtpInputField from "@src/components/otpInputField/OtpInputField";
import UnlistedLogo from "@src/assets/svgs/UnlistedLogo";
import { getPropertyDataByInviteCode } from "@src/api/property";
import CottageIcon from "@src/assets/svgs/CottageIcon";

export default function InviteHomeOwner({ isMassMailer = false }) {
  const navigate = useNavigate();

  const [invitationCode, setInvitationCode] = useState("");
  const [isFindingProperty, setIsFindingProperty] = useState(false);
  const [error, setError] = useState("");

  const title = useMemo(() => {
    if (isMassMailer) {
      return <p>Welcome to Unlisted.</p>;
    }

    return (
      <>
        <p>How exciting!</p>
        <p>Someone loves your home.</p>
      </>
    );
  }, [isMassMailer]);

  const getPropertyData = async () => {
    setIsFindingProperty(true);
    setError("");
    try {
      const response = await getPropertyDataByInviteCode(invitationCode);

      const navigateTo = isMassMailer
        ? `/profile/${response?.id}?property_id=${response?.id}`
        : `/conversation/${response?.proposal_id}?property_id=${response?.id}`;
      navigate(navigateTo);
    } catch (error) {
      setError(
        "This code doesn't seem to exist. Double check that everything is typed correctly?",
      );
    }
    setIsFindingProperty(false);
  };

  useEffect(() => {
    if (invitationCode.length === 6 && !isFindingProperty) getPropertyData();
  }, [invitationCode]);

  return (
    <section className="z-10 flex h-screen w-full justify-center overflow-hidden text-center">
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{ backgroundImage: `url(${bubbleMailerBackground})` }}
      >
        <div className="absolute inset-0 bg-[#0000006E]" />
        <div className="background-gradient-invitation absolute inset-0" />
      </div>

      <div className="absolute mt-20 flex w-full justify-center">
        <UnlistedLogo className="w-[119px] fill-current" fill="#EEF2EF" />
      </div>

      <div
        className={`mx-5 mb-[31px] flex flex-col items-center justify-center rounded-2xl bg-grey-100 px-8 pb-[34px] pt-[37px] opacity-95 ${isFindingProperty ? "min-h-[269px] min-w-[303px] self-center" : "self-end"}`}
      >
        <CottageIcon />
        {isFindingProperty ? (
          <div className="flex flex-col font-content text-lg text-black">
            <p>Finding your property...</p>
          </div>
        ) : (
          <>
            <div className="flex flex-col font-attention text-2xl text-black">
              {title}
            </div>
            <div className="mt-[47px] flex flex-col items-center gap-[13px]">
              <p className="font-content font-semibold">
                {isMassMailer
                  ? "Please enter your invite code."
                  : "To reply, enter your invite code."}
              </p>
              <OtpInputField
                className="user-select-none focus:border-border-gold-500 flex h-[47px] min-w-[40px] gap-2 rounded-lg border-[1px] border-gold-500 bg-white bg-opacity-50 font-content text-[23px] font-semibold leading-[34px] focus:outline-none"
                separator={<div className="w-[9px]"></div>}
                value={invitationCode}
                setValue={setInvitationCode}
              />
              {error ? (
                <motion.div
                  key="popup-container"
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.8 }}
                  transition={{ duration: 0.3 }}
                >
                  <p className="mt-2 text-sm text-red-500">{error}</p>
                </motion.div>
              ) : null}
            </div>
          </>
        )}
      </div>
    </section>
  );
}
