import { useState } from "react";
import PropertyProfileImage from "@src/components/propertyProfile/propertyProfileImage/PropertyProfileImage";
import { useSearchParams } from "react-router-dom";
import PropertyProfileFavoriteButton from "@src/components/propertyProfile/propertyProfileButtons/PropertyProfileFavoriteButton";
import { abbreviateNumber } from "@src/utils/misc.js";
import PropertyProfileHeadline from "@src/components/propertyProfile/propertyProfileHeadline/PropertyProfileHeadline.jsx";
import PropertyProfileSubheading from "@src/components/propertyProfile/propertyProfileSubheading/PropertyProfileSubheading.jsx";
import PropertyProfileDetails from "@src/components/propertyProfile/propertyProfileDetails/PropertyProfileDetails.jsx";
import PropertyProfileClaimedBadge from "@src/components/propertyProfile/propertyProfileClaimedBadge/PropertyProfileClaimedBadge.jsx";

export default function MapItem({ property }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isHidden, setIsHidden] = useState(false); // New state to track if item should be hidden

  const estimatedValue =
    property.owner_expected_value ||
    property.estimated_value ||
    property.estimated_value_one ||
    0;

  // If the item is hidden, do not render anything
  if (isHidden) return null;

  return (
    <div
      className="flex h-full min-h-[211.8px] w-full min-w-[240px] cursor-pointer snap-center flex-col overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-neutral-300"
      onClick={() => setSearchParams({ showing: property?.id })}
    >
      <div className="relative aspect-video min-h-32 w-full">
        <PropertyProfileImage
          property={property}
          onFallback={() => setIsHidden(true)} // Hide this item if aerial view is displayed
          showAerialView={false}
        />
        <div className="absolute right-2 top-2">
          <PropertyProfileFavoriteButton
            propertyId={property?.id}
            isLiked={property?.liked}
          />
        </div>
        <div className="absolute left-2 top-2">
          <PropertyProfileClaimedBadge property={property} />
        </div>
      </div>
      <div className="flex flex-row justify-between gap-4 p-1 px-3 pb-3 capitalize">
        <div data-text className="flex flex-col overflow-hidden">
          <PropertyProfileHeadline
            property={property}
            className="font-attention font-light text-neutral-800"
            shouldTruncate
          />
          <PropertyProfileSubheading
            property={property}
            className="truncate text-xs text-neutral-700"
            shouldTruncate
          />
          <div className="mt-1">
            <PropertyProfileDetails
              property={property}
              fontSize="text-[0.7rem]"
              textColor="text-neutral-700"
              gapSize="gap-1"
            />
          </div>
        </div>
        {estimatedValue ? (
          <div data-price className="flex shrink-0 flex-col items-center">
            <div className="font-attention text-lg text-[#1a1a1a]">
              <sup>$</sup>
              {abbreviateNumber(estimatedValue)}
            </div>
            <span className="text-[0.6rem] text-neutral-400">Est.</span>
          </div>
        ) : null}
      </div>
    </div>
  );
}
