function MoreIcon({className}) {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
            <path
                d="M200-200v-436l-70.31-154.62 36.62-17.07L243.38-638h473.24l77.07-169.69 36.62 17.07L760-636v436H200Zm200-260h160q8.5 0 14.25-5.76t5.75-14.27q0-8.51-5.75-14.24T560-500H400q-8.5 0-14.25 5.76T380-479.97q0 8.51 5.75 14.24T400-460ZM240-240h480v-358H240v358Zm0 0v-358 358Z"/>
        </svg>
    )
}

export default MoreIcon
