import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useMemo, useState } from "react";
import SelectPerks from "@src/components/selectPerks/SelectPerks.jsx";
import FilterApplyButton from "@src/components/filters/FilterApplyButton.jsx";
import { setIsTraditionalSearchingFailure } from "@src/store/features/search/slice.js";

function FilterPerksInput({ onApplyFilter }) {
  const dispatch = useDispatch();
  const { lastSearchValues, isFilterSearching, isTraditionalSearchingFailure } =
    useSelector((state) => state.search);
  const initialPerksValues = lastSearchValues?.selectedPropertyPerks || [];
  const [localPropertyPerksInputValues, setLocalPropertyPerksInputValues] =
    useState(initialPerksValues);

  function arraysEqual(a, b) {
    if (a?.length !== b?.length) return false;
    const aSorted = [...a].sort();
    const bSorted = [...b].sort();
    for (let i = 0; i < aSorted.length; i++) {
      if (aSorted[i] !== bSorted[i]) return false;
    }
    return true;
  }

  const isAnySelectorChanged = useMemo(() => {
    const isPropertyPerksChanged = !arraysEqual(
      localPropertyPerksInputValues,
      lastSearchValues?.selectedPropertyPerks,
    );

    return isPropertyPerksChanged;
  }, [localPropertyPerksInputValues, lastSearchValues]);

  const applyAndSearch = () => {
    const searchValues = {};
    localPropertyPerksInputValues.forEach((id) => {
      searchValues[id] = true;
    });
    onApplyFilter({
      valuesToSave: { selectedPropertyPerks: localPropertyPerksInputValues },
      valuesToSearch: searchValues,
    });
  };

  useEffect(() => {
    dispatch(setIsTraditionalSearchingFailure(false));
  }, []);

  return (
    <div
      className={`flex flex-1 flex-col gap-6 overflow-hidden ${isFilterSearching && "pointer-events-none opacity-70"}`}
    >
      {isTraditionalSearchingFailure && (
        <span className="block w-full rounded-lg bg-orange-300/40 p-3 px-2 text-center text-sm text-orange-800">
          Sorry, your search returned no results. Please adjust your filters and
          try again.
        </span>
      )}
      <header className="flex flex-row justify-center">
        <label className="text-center font-semibold text-gray-600">Perks</label>
      </header>
      <div className="flex w-full flex-1 flex-col gap-6 overflow-hidden">
        <div className="hide-scrollbar flex-1 overflow-y-auto">
          <SelectPerks
            prefix="filter"
            values={localPropertyPerksInputValues}
            setValues={setLocalPropertyPerksInputValues}
          />
        </div>
        <FilterApplyButton
          onClick={applyAndSearch}
          disabled={!isAnySelectorChanged}
        />
      </div>
    </div>
  );
}

export default FilterPerksInput;
