import { getRequest, patchRequest, postRequest } from "@src/utils/axiosClient";

export const fetchPropertyById = async (propertyId) => {
  const response = await getRequest({
    url: `${import.meta.env.VITE_API_URL}/property/${propertyId}`,
  });

  return response.data;
};

export async function getPropertyDataByInviteCode(invitationCode) {
  try {
    const response = await postRequest({
      url: "/proposals/invitation/",
      payload: { invitation_code: invitationCode },
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function addPropertyOwner(propertyId) {
  try {
    const response = await patchRequest({
      url: `/property/${propertyId}/ownership/`,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function likeProperty(propertyId) {
  try {
    const response = await postRequest({
      url: `/properties/${propertyId}/like`,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function unlikeProperty(propertyId) {
  try {
    const response = await postRequest({
      url: `/properties/${propertyId}/unlike`,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export const fetchLikedProperties = async (params) => {
  try {
    const response = await getRequest({
      url: "/api/v1/properties/liked",
      params,
    });

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

/**
 * This takes a single property id and returns
 * the liked status
 * @param {number} propertyId
 * @returns
 */
export const fetchPropertyLikedStatus = async (propertyId) => {
  try {
    const response = await getRequest({
      url: `/properties/${propertyId}/liked`,
    });

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

/**
 * This takes an array of ids under "property_ids" and
 * returns their liked status
 * @param {object} params
 * @returns
 * Example return: {
    "liked_status": {
        "17": true,
        "20": false,
    }
  }
 */
export const fetchPropertiesLikedStatus = async (params) => {
  try {
    const response = await getRequest({
      url: "/api/v1/properties/liked_status",
      params,
    });

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export async function updatePropertyViaApi(propertyData) {
  try {
    const response = await patchRequest({
      url: `/api/v1/owner_info/${propertyData.property_id}/`,
      payload: propertyData,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}
