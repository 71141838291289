import SentIcon from "@src/assets/svgs/SentIcon";

function EmailConfirmation({ email }) {
  return (
    <>
      <SentIcon />
      <h1 className="mt-[-12px] font-attention text-2xl text-neutral-800">
        Almost there!
      </h1>

      <div className="mt-[18px] flex flex-col text-center font-content text-neutral-700">
        <p>We’ve sent a validation link to</p>
        <p className="font-semibold text-neutral-800">{email}.</p>
      </div>
      <div className="flex max-w-[268px] flex-col">
        <p className="mt-[15px] flex flex-col text-center font-content text-neutral-700">
          Please open your email and click the link to continue.
        </p>
      </div>
    </>
  );
}

export default EmailConfirmation;
