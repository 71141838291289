import * as Slider from "@radix-ui/react-slider";

export default function SingleSlider({ onValueChange, min, max, step, value }) {
  return (
    <Slider.Root
      onValueChange={onValueChange}
      min={min}
      max={max}
      step={step}
      value={[value]}
      className="relative flex h-5 w-full touch-none select-none items-center"
      defaultValue={[value || min]}
    >
      <Slider.Track className="relative h-2 grow rounded-full bg-gray-200">
        <Slider.Range className="absolute h-full rounded-full bg-primary-500" />
      </Slider.Track>
      <Slider.Thumb className="block h-7 w-7 rounded-full border border-gray-300 bg-white" />
    </Slider.Root>
  );
}
