import React from "react";
import { useSelector } from "react-redux";

export default function FilterApplyButton({ onClick, disabled }) {
  const { isFilterSearching } = useSelector((state) => state.search);
  return (
    <>
      {isFilterSearching ? (
        <button
          disabled={true}
          className="mx-auto flex w-full justify-center gap-2 rounded-md bg-primary-500 p-3 px-6 pl-4 font-semibold text-white transition-all delay-75"
        >
          <div className="h-6 w-6 animate-spin rounded-full border border-2 border-black/10 border-t-white transition duration-100" />
        </button>
      ) : (
        <button
          onClick={onClick}
          disabled={disabled}
          className="mx-auto flex w-full justify-center gap-2 rounded-md bg-primary-500 p-3 px-6 pl-4 font-semibold text-white transition-all delay-75 disabled:opacity-60"
        >
          Apply
        </button>
      )}
    </>
  );
}
