import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchPropertiesLikedStatus } from "@src/api/property";
import { apiFetchRandomProperties, apiSearchProperties } from "@src/api/search";
import architecturalStyles from "@src/data/architectural_styles";
import { getAuthTokenFromStorage } from "@src/utils/misc";
import ReactGA from "react-ga4";

export const searchProperties = createAsyncThunk(
  "search/searchProperties",
  async (searchData, { rejectWithValue }) => {
    const isLoggedIn = !!getAuthTokenFromStorage();

    try {
      if (searchData?.style?.length) {
        const mappingSet = new Set([]);
        searchData?.style.forEach((id) => {
          const style = architecturalStyles.find((style) => style?.id === id);
          if (style?.id) {
            style?.mapping?.forEach((mappingId) => mappingSet.add(mappingId));
          }
        });
        searchData.style = Array.from(mappingSet);
      }

      const minimumDelay = new Promise((resolve) => setTimeout(resolve, 1000));

      const apiCall = apiSearchProperties(searchData);

      // Wait for both the spinner delay and the API call to complete
      const [response] = await Promise.all([apiCall, minimumDelay]);
      ReactGA.event("search", { search_term: JSON.stringify(searchData) });
      if (isLoggedIn) {
        const propertiesIds = response?.properties?.map((item) => item?.id);
        if (propertiesIds.length) {
          const likedStatuses = await fetchPropertiesLikedStatus({
            property_ids: propertiesIds,
          });
          const updatedProperties = response?.properties?.map((item) => {
            return {
              ...item,
              liked: likedStatuses?.liked_status[item?.id],
            };
          });

          return {
            ...response,
            properties: updatedProperties,
          };
        }

        return response;
      }

      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return rejectWithValue(message);
    }
  },
);

export const fetchRandomProperties = createAsyncThunk(
  "search/getRandomProperties",
  async (searchData, { rejectWithValue }) => {
    const isLoggedIn = !!getAuthTokenFromStorage();

    try {
      const response = await apiFetchRandomProperties();

      if (isLoggedIn) {
        const propertiesIds = response?.properties?.map((item) => item?.id);

        const likedStatuses = await fetchPropertiesLikedStatus({
          property_ids: propertiesIds,
        });
        const updatedProperties = response?.properties?.map((item) => {
          return {
            ...item,
            liked: likedStatuses?.liked_status[item?.id],
          };
        });
        return {
          ...response,
          properties: updatedProperties,
        };
      }

      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return rejectWithValue(message);
    }
  },
);

const service = {
  searchProperties,
  fetchRandomProperties,
};

export default service;
