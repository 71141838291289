import React, { useState, useRef, useEffect } from "react";
import architectural_styles from "../../data/architectural_styles.js";
import { MdAdd, MdCheck } from "react-icons/md";
import HomeOultine from "@src/assets/svgs/HomeOultine.jsx";

function SelectArchitecturalStyles({
  value,
  onChange,
  prefix,
  allowMultiple = false,
}) {
  const [selectedArchitectureStyles, setSelectedArchitectureStyles] =
    useState(value);

  const toggleArchitecturalStyle = (styleId) => {
    let newStyles = [];
    if (!allowMultiple) {
      if (selectedArchitectureStyles.includes(styleId)) {
        newStyles = [10000];
      } else {
        newStyles = [styleId];
      }
    } else {
      if (selectedArchitectureStyles.includes(styleId)) {
        newStyles = selectedArchitectureStyles.filter((id) => id !== styleId);
      } else {
        newStyles = [...selectedArchitectureStyles, styleId];
      }
    }

    setSelectedArchitectureStyles(newStyles);
    if (onChange) {
      const styleIds = newStyles.map((id) => {
        if (id === 10000) return 10000;
        const style = architectural_styles.find((style) => style.id === id);
        return style.id;
      });

      onChange(styleIds);
    }
  };

  const onStyleChanged = (e) => {
    const styleId = parseInt(e.target.dataset.styleId);
    toggleArchitecturalStyle(styleId);
  };

  const isSelectedStyle = (styleId) => {
    return selectedArchitectureStyles.includes(styleId);
  };

  return (
    <div className="flex flex-col items-center @container">
      {/*<div className="grid w-full auto-rows-auto grid-cols-[repeat(auto-fit,_minmax(7rem,_1fr))] gap-3 gap-y-5 sm:pt-4 md:grid-cols-[repeat(auto-fit,_minmax(10rem,_1fr))] md:gap-8 md:gap-y-10">*/}
      <div className="grid w-full auto-rows-auto grid-cols-[repeat(auto-fit,_minmax(7rem,_1fr))] gap-4 gap-y-5 @xs:grid-cols-[repeat(auto-fit,_minmax(7rem,_1fr))] @md:grid-cols-[repeat(auto-fit,_minmax(8rem,_1fr))]">
        {architectural_styles
          .filter((style) => {
            if (allowMultiple && style.id === 0) return false;
            return style;
          })
          .map((style) => (
            <div
              key={style.id}
              className="relative h-fit w-fit overflow-hidden rounded-2xl text-center transition-colors sm:hover:bg-primary-500/20"
            >
              <input
                data-style-id={style.id}
                checked={isSelectedStyle(style.id)}
                id={`${prefix}_${style.id}`}
                className="peer absolute hidden"
                type="checkbox"
                onChange={(e) => onStyleChanged(e)}
              />
              <label
                htmlFor={`${prefix}_${style.id}`}
                className="max-w-30 relative block flex w-full cursor-pointer flex-col items-center justify-center gap-2 p-6 pb-2 pt-3 font-content"
              >
                <img
                  src={style.image}
                  alt={style.label}
                  className="w-full mix-blend-darken"
                />
                <span className="text-xs leading-4 text-neutral-700">
                  {style.label}
                </span>
              </label>
              <div className="pointer-events-none absolute left-0 top-0 z-10 h-full w-full bg-primary-500 opacity-0 mix-blend-multiply shadow-custom-inset transition-all peer-checked:opacity-20"></div>
              <div className="pointer-events-none absolute right-2 top-2 h-fit w-fit rounded-full bg-primary-500/20 p-1 opacity-0 peer-checked:opacity-100">
                <MdCheck className="opacity-60" />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default SelectArchitecturalStyles;
