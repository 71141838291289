import React, { useEffect, useRef, useState } from "react";
import PropertyProfileImage from "@src/components/propertyProfile/propertyProfileImage/PropertyProfileImage.jsx";
import { useDispatch } from "react-redux";
import {
  updateProperty,
  updatePropertyMainImage,
} from "@src/store/features/property/service.js";
import { setPropertyPropValue } from "@src/store/features/property/slice.js";
import {
  cacheImage,
  invalidateImage,
} from "@src/store/features/imageCache/slice.js";
import { UPLOAD_PHOTO_TYPE } from "@src/utils/constants";
import {
  resetTours,
  setFirstPhotoPromptLastShown,
} from "@src/store/features/user/slice.js";
import { IoMdTrash } from "react-icons/io";
import { MdCamera, MdOutlinePhotoCamera, MdPhotoCamera } from "react-icons/md";

function PropertyProfileFirstPhotoInput({ property, onChange }) {
  const [isUploading, setIsUploading] = useState(false);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  // Handle the image file selection
  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsUploading(true);
      // dispatch(invalidateImage(property.id));

      await new Promise((resolve) => setTimeout(resolve, 2000));
      const response = await dispatch(
        updatePropertyMainImage({
          file: event.target.files[0],
          type: UPLOAD_PHOTO_TYPE.PROPERTY_COVER,
          propertyId: property.id,
        }),
      );

      // dispatch(
      //   cacheImage({
      //     propertyId: property.id,
      //     imageUrl: response.payload.get_url,
      //   }),
      // );
      if (response.payload.get_url) {
        setIsUploading(false);
        onChange(response.payload.get_url);
      }
    }
  };

  useEffect(() => {
    if (property.cover_photo_url) {
      setIsUploading(false);
    }
  }, [property.cover_photo_url]);

  const handleDeleteCoverPhoto = async () => {
    setIsUploading(true);
    //wait two seconds to simulate the deletion process
    await new Promise((resolve) => setTimeout(resolve, 2000));
    await dispatch(
      cacheImage({
        propertyId: property.id,
        imageUrl: null,
      }),
    );
    await dispatch(
      setPropertyPropValue({
        key: "cover_photo_url",
        value: null,
      }),
    );
    await dispatch(updateProperty({ propertyData: property, isDraft: true }));
    setIsUploading(false);
  };

  // Trigger the hidden file input when the button is clicked
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="relative h-full w-full overflow-hidden">
      <PropertyProfileImage property={property} />
      {!property.cover_photo_url && (
        <span className="absolute bottom-1 right-2 text-xs font-bold text-white">
          Google
        </span>
      )}
      <div className="absolute inset-0 flex h-full flex-col items-center justify-center bg-black/20 p-4 text-left">
        {!isUploading && property.cover_photo_url && (
          <button
            onClick={handleDeleteCoverPhoto}
            className="absolute right-2 top-2 aspect-square rounded-full bg-black/70 p-2 text-red-400/90 transition-all hover:bg-black"
          >
            <IoMdTrash className="h-5 w-5" />
          </button>
        )}

        {isUploading ? (
          <>
            <div className="absolute inset-0 flex animate-pulse flex-col items-center justify-center gap-2 bg-black/80 text-neutral-300">
              <div className="border-r-red h-8 w-8 animate-spin rounded-full border-4 border-neutral-600 border-r-primary-500"></div>
              Updating image...
            </div>
          </>
        ) : (
          <button
            className="flex items-center gap-1 rounded-lg border border-neutral-300 bg-black/40 px-5 py-3 pl-3.5 text-sm font-normal text-neutral-100 transition-all hover:bg-black/60"
            onClick={handleButtonClick}
          >
            <MdOutlinePhotoCamera className="mr-2 h-4 w-4" />
            <span>
              {property.cover_photo_url
                ? "Change Cover Photo"
                : "Replace Street View"}
            </span>
          </button>
        )}

        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          onChange={handleImageChange}
          style={{ display: "none" }}
        />
      </div>
    </div>
  );
}

export default PropertyProfileFirstPhotoInput;
