import { dateTimeFormatter } from "@src/utils/misc";

function MessageBubble({ message, isSender, userName, time }) {
  return (
    <div className={`flex justify-${isSender ? "end" : "start"} mb-2`}>
      <div
        className={`max-w-xs rounded-tl-2xl rounded-tr-2xl p-3 text-black ${
          isSender
            ? "w-[70%] rounded-bl-2xl bg-gold-250"
            : "w-[70%] rounded-br-2xl bg-sage-300"
        }`}
      >
        <div className="mb-1 flex justify-between text-xs text-gray-500">
          <span className="font-content text-base font-semibold text-grey-800">
            {userName}
          </span>
          <span className="font-content text-xs text-grey-400">
            {dateTimeFormatter(time)}
          </span>
        </div>
        <div className="font-content text-sm font-normal text-grey-700 whitespace-pre-wrap">
          {message}
        </div>
      </div>
    </div>
  );
}

export default MessageBubble;
