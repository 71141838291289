import { NavLink, useLocation } from "react-router-dom";

const NavItem = ({
  to,
  icon: Icon,
  children,
  additionalActivePaths = [],
  onClick,
}) => {
  const location = useLocation();

  const isActive = [to, ...additionalActivePaths].includes(location.pathname);

  return (
    <li
      className={`flex cursor-pointer flex-row items-center transition-colors ${
        isActive
          ? "pointer-events-none text-[#B2A585]"
          : "text-[#CCCCCC] md:hover:text-white"
      }`}
      onClick={onClick} // Capture the click event here
    >
      <NavLink
        to={to}
        className={`flex w-full items-center gap-3 px-6 py-3 transition-colors ${
          isActive ? "fill-[#B2A585]" : "fill-[#A0A0A0]/65"
        }`}
      >
        <Icon
          className={`transition-colors ${isActive ? "fill-[#B2A585]" : "fill-[#A0A0A0]/65"}`}
        />
        {children}
      </NavLink>
    </li>
  );
};

export default NavItem;
