import { useCallback, useEffect, useState } from "react";
import { MdSearch } from "react-icons/md";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

import { apiSearchProperties } from "@src/api/search";
import BaseModal from "@src/components/baseModal/BaseModal";
import { PlaceAutocomplete } from "@src/components/search/SearchPanel";
import CottageIcon from "@src/assets/svgs/CottageIcon";
import FindMyHomeIcon from "@src/assets/svgs/FindMyHomeIcon";
import { formatGoogleAPIPlace } from "@src/utils/misc";
import SunMoonAnimation from "@src/assets/24hr_spinner.gif";
import ClaimHomeMenuIcon from "@src/assets/svgs/ClaimHomeMenuIcon.jsx";
import ReactGA from "react-ga4";

const STEPS = [
  {
    title: "Claim your home",
    subTitle: "Search your address to find and own your property’s profile.",
  },
  {
    title: "Show off your home",
    subTitle: "Upload photos and tell the story of your home.",
    numberStyles: "-mr-[10px]",
  },
  {
    title: "Collect interest in your home",
    subTitle:
      "Proactively discover buyers who love your home so you better understand your options.",
    numberStyles: "-mr-1",
  },
];

export default function FindMyHome() {
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [error, setError] = useState("");

  const onChangeValue = useCallback((event) => {
    setValue(event.target.value);
    setError("");
  }, []);

  const onPlaceSelect = useCallback((place) => {
    if (!place?.formatted_address) return;
    setSelectedPlace(place);
    setValue(place?.formatted_address);
  }, []);

  const handleSearchClick = async () => {
    const searchData = formatGoogleAPIPlace({ place: selectedPlace });

    setIsSearching(true);

    // Create a promise for the spinner delay
    const spinnerDelay = new Promise((resolve) => setTimeout(resolve, 2000));

    try {
      // Start the API call
      const apiCall = apiSearchProperties(searchData);
      ReactGA.event("claim_home_search");
      // Wait for both the spinner delay and the API call to complete
      const [response] = await Promise.all([apiCall, spinnerDelay]);

      const property = response?.properties?.[0];

      if (property?.id) {
        navigate(`/profile/${property?.id}?claiming=true`);
      } else {
        setError("Sorry, we couldn't find your home.");
      }
    } catch (error) {
      setError("Sorry, we couldn't find your home.");
    }

    setIsSearching(false);
  };

  useEffect(() => {
    if (selectedPlace) handleSearchClick();
  }, [selectedPlace]);

  return (
    <section className="flex w-full flex-col justify-center pb-[74px] text-center">
      <div
        className={`mx-auto flex w-full max-w-4xl flex-col items-center px-4 ${error ? "pb-3" : "pb-6"} pt-[74px]`}
      >
        <div className="mx-auto mb-6 mt-3 flex w-full flex-col items-center rounded-xl bg-gold-400/10 p-6 pb-7 ring-1 ring-neutral-200 md:pb-10">
          <div className="mt-2 flex aspect-square h-20 w-20 items-center justify-center rounded-full bg-gold-400/20">
            <CottageIcon className="h-10 w-10 translate-y-1.5" />
          </div>
          <h2 className="mt-5 text-balance font-attention text-2xl leading-[140%] text-black/80 sm:text-3xl md:max-w-[28rem]">
            Learn if buyers are interested in your home
          </h2>
          <p className="mt-6 w-full font-content font-semibold tracking-tighter text-black/30 md:text-black/50">
            Search your address to begin
          </p>

          <div className="relative mx-auto mt-3 flex h-[44px] w-full max-w-sm items-center justify-center self-center rounded-full border border-gold-500/60 bg-white pl-4 pr-3 shadow-xl shadow-gold-700/5 transition-colors focus-within:border-gold-500 md:mt-4">
            <MdSearch
              className="absolute left-2.5 top-1/2 h-[1.4rem] w-[1.4rem] -translate-y-1/2 opacity-50"
              color="#808080"
            />
            <PlaceAutocomplete
              textValue={value}
              className="disabled:transparent h-full w-full bg-transparent pl-6 pr-2 font-content focus:border-none focus:outline-none"
              onPlaceSelect={onPlaceSelect}
              onChange={onChangeValue}
              placeholder="123 Unlisted Dr, Anytown, OH"
              specificAddress={true}
            />
          </div>
          {error ? (
            <motion.div
              key="popup-container"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.3 }}
            >
              <p className="mt-2 text-sm text-orange-600">{error}</p>
            </motion.div>
          ) : null}
        </div>

        <div className="flex w-full flex-col flex-wrap gap-6 sm:flex-row">
          <div className="flex-1 rounded-xl p-6 text-neutral-800 ring-1 ring-neutral-200">
            <div className="text-3xl">🏡</div>
            <h3 className="mt-2 font-bold">Test interest without listing</h3>
            <p className="text-neutral-500">
              Be notified when buyers like your home.
            </p>
          </div>
          <div className="flex-1 rounded-xl p-6 text-neutral-800 ring-1 ring-neutral-200">
            <div className="text-2xl">💬</div>
            <h3 className="mt-2 font-bold">Connect with buyers</h3>
            <p className="text-neutral-500">
              Consider highly-motivated offers with zero commitment.
            </p>
          </div>
        </div>
      </div>
      {isSearching ? (
        <BaseModal open={isSearching} contentWrapperStyles="px-[21px]">
          <div className="modal-shadow flex h-[269px] flex-col items-center justify-center rounded-2xl bg-grey-100">
            <div className="relative h-28 w-44 translate-y-2">
              <CottageIcon className="absolute bottom-6 left-1/2 -translate-x-1/2" />
              <img
                className="absolute bottom-0 left-1/2 max-w-[140%] -translate-x-1/2"
                src={SunMoonAnimation}
                alt="Sun and Moon animation"
              />
            </div>

            <p className="font-content">Finding your property...</p>
          </div>
        </BaseModal>
      ) : null}
    </section>
  );
}
