const property_perks = [
  { id: "balcony", text: "Balcony", emoji: "🌇" },
  { id: "breezeway", text: "Breezeway", emoji: "🌬️" },
  { id: "boat_access", text: "Boat Access", emoji: "🚤" },
  { id: "cabin", text: "Cabin", emoji: "🏡" },
  { id: "elevator", text: "Elevator", emoji: "🛗" },
  { id: "game_room", text: "Game Room", emoji: "🎮" },
  { id: "garage", text: "Garage", emoji: "🚗" },
  { id: "guest_house", text: "Guest House", emoji: "🏘️" },
  { id: "office", text: "Office", emoji: "💼" },
  { id: "pond", text: "Pond", emoji: "🐟" },
  { id: "sauna", text: "Sauna", emoji: "🧖" },
  { id: "tennis_court", text: "Tennis Court", emoji: "🎾" },
  { id: "wine_cellar", text: "Wine Cellar", emoji: "🍷" },
  { id: "pool", text: "Pool", emoji: "🏊‍♂️" },
  { id: "exercise_room", text: "Gym", emoji: "💪" },
  { id: "porch", text: "Porch", emoji: "🪴" },
  { id: "courtyard", text: "Courtyard", emoji: "🏘️" },
  { id: "sunroom", text: "Sunroom", emoji: "🪟" },
  { id: "patio_area", text: "Patio", emoji: "🌞" },
  { id: "practice_green", text: "Practice Green", emoji: "⛳" },
  { id: "outdoor_kitchen", text: "Outdoor Kitchen", emoji: "🍔" },
  { id: "finished_basement_area", text: "Finished Basement", emoji: "🛋️" },
  { id: "deck", text: "Deck", emoji: "🌳" },
  { id: "handicap_accessible", text: "Handicap Accessible", emoji: "♿" },
  { id: "cellar", text: "Cellar", emoji: "⬇️" },
  { id: "fireplace", text: "Fireplace", emoji: "🔥" },
  { id: "family_room", text: "Family Room", emoji: "🛋️" },
  { id: "great_room", text: "Great Room", emoji: "🛋️" },
  { id: "laundry_room", text: "Laundry Room", emoji: "🧺" },
  { id: "media_room", text: "Media Room", emoji: "📺" },
  { id: "mud_room", text: "Mud Room", emoji: "🥾" },
  { id: "safe_room", text: "Safe Room", emoji: "🔐" },
  { id: "sitting_room", text: "Sitting Room", emoji: "🛋️" },
  { id: "storm_shelter", text: "Storm Shelter", emoji: "⛈️" },
  { id: "study", text: "Study", emoji: "📚" },
  { id: "utility_room", text: "Utility Room", emoji: "🛠️" },
  { id: "escalator", text: "Escalator", emoji: "🛗" },
  { id: "wet_bar", text: "Wet Bar", emoji: "🍸" },
  { id: "pergola", text: "Pergola", emoji: "🌿" },
  { id: "boat_house", text: "Boat House", emoji: "🚤" },
  { id: "green_house", text: "Green House", emoji: "🪴" },
  { id: "pool_house", text: "Pool House", emoji: "🏊‍♂️" },
  { id: "poultry_house", text: "Poultry House", emoji: "🐔" },
  { id: "shed", text: "Shed", emoji: "🏚️" },
  { id: "silo", text: "Silo", emoji: "🌾" },
  { id: "stable", text: "Stable", emoji: "🐎" },
  { id: "universal_access", text: "Universal Access", emoji: "♿" },

  // {
  //   id: "outdoor_living",
  //   title: "Outdoor Living",
  //   perks: [
  //     { id: "balcony", text: "Balcony", emoji: "🌇" },
  //     { id: "breezeway", text: "Breezeway", emoji: "🌬️" },
  //     { id: "porch", text: "Porch", emoji: "🪴" },
  //     { id: "courtyard", text: "Courtyard", emoji: "🏘️" },
  //     { id: "patio_area", text: "Patio", emoji: "🌞" },
  //     { id: "deck", text: "Deck", emoji: "🌳" },
  //     { id: "pergola", text: "Pergola", emoji: "🌿" },
  //     { id: "outdoor_kitchen", text: "Outdoor Kitchen", emoji: "🍔" },
  //     { id: "practice_green", text: "Practice Green", emoji: "⛳" },
  //     { id: "pond", text: "Pond", emoji: "🐟" },
  //   ],
  // },
  // {
  //   id: "entertainment_leisure",
  //   title: "Entertainment & Leisure",
  //   perks: [
  //     { id: "game_room", text: "Game Room", emoji: "🎮" },
  //     { id: "media_room", text: "Media Room", emoji: "📺" },
  //     { id: "pool", text: "Pool", emoji: "🏊‍♂️" },
  //     { id: "sauna", text: "Sauna", emoji: "🧖" },
  //     { id: "tennis_court", text: "Tennis Court", emoji: "🎾" },
  //     { id: "wine_cellar", text: "Wine Cellar", emoji: "🍷" },
  //     { id: "wet_bar", text: "Wet Bar", emoji: "🍸" },
  //   ],
  // },
  // {
  //   id: "luxury_unique_features",
  //   title: "Luxury & Unique Features",
  //   perks: [
  //     { id: "boat_access", text: "Boat Access", emoji: "🚤" },
  //     { id: "boat_house", text: "Boat House", emoji: "🚤" },
  //     { id: "sunroom", text: "Sunroom", emoji: "🪟" },
  //     { id: "fireplace", text: "Fireplace", emoji: "🔥" },
  //     { id: "pool_house", text: "Pool House", emoji: "🏊‍♂️" },
  //     { id: "green_house", text: "Green House", emoji: "🪴" },
  //     { id: "stable", text: "Stable", emoji: "🐎" },
  //     { id: "silo", text: "Silo", emoji: "🌾" },
  //     { id: "poultry_house", text: "Poultry House", emoji: "🐔" },
  //   ],
  // },
  // {
  //   id: "functional_spaces",
  //   title: "Functional Spaces",
  //   perks: [
  //     { id: "garage", text: "Garage", emoji: "🚗" },
  //     { id: "laundry_room", text: "Laundry Room", emoji: "🧺" },
  //     { id: "utility_room", text: "Utility Room", emoji: "🛠️" },
  //     { id: "finished_basement_area", text: "Finished Basement", emoji: "🛋️" },
  //     { id: "mud_room", text: "Mud Room", emoji: "🥾" },
  //     { id: "safe_room", text: "Safe Room", emoji: "🔐" },
  //     { id: "storm_shelter", text: "Storm Shelter", emoji: "⛈️" },
  //   ],
  // },
  // {
  //   id: "work_study",
  //   title: "Work & Study",
  //   perks: [
  //     { id: "office", text: "Office", emoji: "💼" },
  //     { id: "study", text: "Study", emoji: "📚" },
  //     { id: "sitting_room", text: "Sitting Room", emoji: "🛋️" },
  //     { id: "great_room", text: "Great Room", emoji: "🛋️" },
  //     { id: "family_room", text: "Family Room", emoji: "🛋️" },
  //   ],
  // },
  // {
  //   id: "accessibility_movement",
  //   title: "Accessibility & Movement",
  //   perks: [
  //     { id: "elevator", text: "Elevator", emoji: "🛗" },
  //     { id: "escalator", text: "Escalator", emoji: "🛗" },
  //     { id: "handicap_accessible", text: "Handicap Accessible", emoji: "♿" },
  //     { id: "universal_access", text: "Universal Access", emoji: "♿" },
  //   ],
  // },
  // {
  //   id: "outbuildings_storage",
  //   title: "Outbuildings & Storage",
  //   perks: [
  //     { id: "shed", text: "Shed", emoji: "🏚️" },
  //     { id: "cellar", text: "Cellar", emoji: "⬇️" },
  //     { id: "barn", text: "Barn", emoji: "🐴" },
  //     { id: "poultry_house", text: "Poultry House", emoji: "🐔" },
  //     { id: "silo", text: "Silo", emoji: "🌾" },
  //   ],
  // },
];

export default property_perks;
