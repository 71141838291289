import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MdArrowBack } from "react-icons/md";
import { motion, useAnimationControls } from "framer-motion";
import { closeSearchOptionPanel } from "../../store/features/search/slice.js";

function SearchOptionPanel({ label, children }) {
  const dispatch = useDispatch();
  const optionsControls = useAnimationControls();
  const { isShowingSearchOptionPanel } = useSelector((state) => state.search);
  //const [isSearchOptionPanelShowing, setIsSearchOptionPanelShowing] = useState(false);
  const containerVariants = {
    close: {
      x: "100%",
      transition: {
        type: "easeOut",
        damping: 0,
        duration: 0.125,
      },
    },
    open: {
      x: "0%",
      transition: {
        type: "easeOut",
        damping: 0,
        duration: 0.125,
      },
    },
  };

  useEffect(() => {
    if (isShowingSearchOptionPanel) {
      optionsControls.start("open");
    } else {
      optionsControls.start("close");
    }
  }, [isShowingSearchOptionPanel]);

  return (
    <motion.div
      initial="close"
      variants={containerVariants}
      animate={optionsControls}
      className="fixed right-0 top-0 z-30 flex h-dvh w-dvw translate-x-full flex-col overflow-y-hidden bg-white sm:max-w-sm"
    >
      <header className="sticky left-0 top-0 z-10 flex flex-row items-center justify-between border-b border-b-gray-300 bg-white py-3 pl-3">
        <button
          className="shrink-0 rounded-full p-2 duration-300 md:hover:bg-gray-200"
          onClick={() => dispatch(closeSearchOptionPanel())}
        >
          <MdArrowBack className="h-6 w-6" />
        </button>
        <h1 className="font-attention text-2xl text-gray-600">{label}</h1>
        <div className="w-8"></div>
      </header>
      <div className="w-full flex-1 overflow-y-auto">{children}</div>
    </motion.div>
  );
}

export default SearchOptionPanel;
