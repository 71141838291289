import PropertyProfileContent from "@src/components/propertyProfile/propertyProfileContent/PropertyProfileContent";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getDisplayPropertyById } from "../../../store/features/property/service.js";
import { useDispatch, useSelector } from "react-redux";
import * as Dialog from "@radix-ui/react-dialog";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";

function PropertyProfileModal({ property }) {
  const [open, setOpen] = useState(false);
  const { currentDisplayProperty } = useSelector((state) => state.property);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  function handleOverlayClick(e) {
    const newSearchParams = new URLSearchParams(searchParams);
    const hasShowingParam = searchParams.get("showing");

    if (hasShowingParam) {
      newSearchParams.delete("showing");
    }

    setSearchParams(newSearchParams);
  }

  const dismissModal = () => {
    if (!open) return;
    setOpen(false);
    setTimeout(() => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete("showing");
      setSearchParams(newSearchParams);
      dispatch(getDisplayPropertyById(null));
    }, 300);
  };

  useEffect(() => {
    const propertyParam = searchParams.get("showing");
    if (propertyParam) {
      dispatch(getDisplayPropertyById(propertyParam));
    } else {
      dismissModal();
      // setIsShowingProfileCard(false);
    }
  }, [searchParams]);

  useEffect(() => {
    if (currentDisplayProperty) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [currentDisplayProperty]);

  return (
    <>
      {currentDisplayProperty && (
        <Dialog.Root open={open} onOpenChange={setOpen}>
          <Dialog.Portal>
            <Dialog.Overlay className="fixed inset-0 z-50 bg-black/60 data-[state=closed]:animate-[fade-out_300ms] data-[state=open]:animate-[fade-in_300ms]" />
            <Dialog.Content
              onOpenAutoFocus={(e) => e.preventDefault()}
              className="fixed inset-0 left-1/2 z-50 h-dvh w-full -translate-x-1/2 overflow-y-auto data-[state=closed]:animate-[slight-slide-down-fade-out_300ms] data-[state=open]:animate-[slight-slide-up-fade-in_300ms] md:p-5"
            >
              <VisuallyHidden>
                <Dialog.Title>Property Profile</Dialog.Title>
                <Dialog.Description>
                  This is the property profile modal
                </Dialog.Description>
              </VisuallyHidden>
              <div className="fixed inset-0" onClick={handleOverlayClick}></div>
              <div className="shadow-3xl relative z-10 mx-auto min-h-full w-full max-w-5xl shadow-xl shadow-black/40 md:overflow-hidden md:rounded-xl">
                <PropertyProfileContent
                  display="modal"
                  property={currentDisplayProperty}
                />
              </div>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>
      )}
    </>

    // <div className="relative h-full w-full overflow-y-auto md:p-5">
    //   <div
    //     className="fixed left-0 top-0 z-0 h-full w-full py-10"
    //     onClick={handleOverlayClick}
    //   ></div>
    //   <div className="shadow-3xl relative z-10 mx-auto min-h-full w-full max-w-5xl shadow-xl shadow-black/40 md:overflow-hidden md:rounded-xl">
    //     <PropertyProfileContent
    //       display="modal"
    //       property={currentDisplayProperty}
    //     />
    //   </div>
    // </div>
  );
}

export default PropertyProfileModal;
