import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function PageViewTracker() {
    //This is for manual page view tracking, but so far the automatic "Enable Enhanced Measurement in GA4" is working fine.


    // const location = useLocation();

    // useEffect(() => {
    //     window.dataLayer = window.dataLayer || [];
    //     window.dataLayer.push({
    //         event: 'pageview',
    //         page: location.pathname,
    //     });
    // }, [location]);

    return null;
}

export default PageViewTracker;
