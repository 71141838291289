import React from "react";
import * as Accordion from "@radix-ui/react-accordion";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

const AccordionCustom = ({ data }) => (
  <Accordion.Root type="single" collapsible>
    {data.map((item, index) => (
      <Accordion.Item
        key={index}
        value={item.title}
        className="border-b border-gray-300"
      >
        <Accordion.Header>
          <Accordion.Trigger className="group flex w-full items-center justify-between py-4">
            <span className="text-left font-attention text-lg text-black">
              {item.title}
            </span>
            <span className="flex items-center">
              <AiOutlinePlus className="text-2xl text-gray-500 group-data-[state=open]:hidden" />
              <AiOutlineMinus className="text-2xl text-gray-500 group-data-[state=closed]:hidden" />
            </span>
          </Accordion.Trigger>
        </Accordion.Header>
        <Accordion.Content className="pb-4 font-content text-sm font-normal text-gray-900">
          <p>{item.content}</p>
        </Accordion.Content>
      </Accordion.Item>
    ))}
  </Accordion.Root>
);

export default AccordionCustom;
