import { motion } from "framer-motion";
function PopupContainer({ children }) {
  return (
    <motion.div
      key="popup-container"
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.8 }}
      transition={{ duration: 0.3 }}
      className="w-[min(100% - 3rem, 34rem)] flex flex-col items-center justify-center rounded-[16px] bg-[#E6E6E6] pb-12 pl-[41px] pr-[40px] pt-3 shadow-lg"
    >
      {children}
    </motion.div>
  );
}

export default PopupContainer;
