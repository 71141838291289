import React, { useState, useMemo, useCallback, useEffect } from "react";
import MultiSelectRadioGroup from "@src/components/MultiSelectRadioGroup.jsx";
import FilterApplyButton from "@src/components/filters/FilterApplyButton.jsx";
import { useSelector, useDispatch } from "react-redux";
import { setIsTraditionalSearchingFailure } from "@src/store/features/search/slice.js";

function FilterRadioGroupInput({
  label, // Label for the filter (e.g., "Bedrooms", "Bathrooms")
  options, // The list of options (e.g., bedroom_options.values or bathroom_options.values)
  initialValues, // The initially selected values from the search state
  onApplyFilter, // Callback to apply the filter
  valueKey, // Key to store the values in the state (e.g., "bedrooms" or "bathrooms")
}) {
  const dispatch = useDispatch();
  const { isFilterSearching, isTraditionalSearchingFailure } = useSelector(
    (state) => state.search,
  );
  const [localInputValues, setLocalInputValues] = useState(initialValues);

  const isAnySelectorChanged = useMemo(() => {
    return localInputValues[0] !== initialValues[0];
  }, [localInputValues, initialValues]);

  const applyAndSearch = () => {
    const valuesToSave = { [valueKey]: localInputValues };
    const valuesToSearch = {
      [valueKey]: String(options[localInputValues[0]]),
    };
    onApplyFilter({ valuesToSave, valuesToSearch });
  };

  const handleOptionChange = useCallback((indexes) => {
    setLocalInputValues(indexes);
  }, []);

  useEffect(() => {
    dispatch(setIsTraditionalSearchingFailure(false));
  }, []);

  return (
    <div
      className={`flex w-full flex-col gap-12 ${isFilterSearching && "pointer-events-none opacity-70"}`}
    >
      <div className="flex flex-col gap-6">
        {isTraditionalSearchingFailure && (
          <span className="block w-full rounded-lg bg-orange-300/40 p-3 px-2 text-center text-sm text-orange-800">
            Sorry, your search returned no results. Please adjust your filters
            and try again.
          </span>
        )}

        <MultiSelectRadioGroup
          label={label}
          items={options}
          values={localInputValues}
          allowMultiple={false} // Single selection
          onChange={handleOptionChange}
        />
      </div>

      <FilterApplyButton
        onClick={applyAndSearch}
        disabled={!isAnySelectorChanged}
      />
    </div>
  );
}

export default FilterRadioGroupInput;
