function ClaimHomeMenuIcon({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      height="26px"
      viewBox="0 -960 960 960"
      width="26px"
      fill="#5f6368"
    >
      <path d="M240-140v-620h287.69l16 80H760v320H552.31l-16-80H280v300h-40Zm260-420Zm86 160h134v-240H510l-16-80H280v240h290l16 80Z" />
    </svg>
  );
}

export default ClaimHomeMenuIcon;
