import React from "react";
import { abbreviateNumber } from "@src/utils/misc.js";

function PropertyProfileEstimatedValueTag({ property }) {
  const estimatedValue =
    property.owner_expected_value ||
    property.estimated_value ||
    property.estimated_value_one ||
    0;
  if (!estimatedValue) return null;

  return (
    <span className="inline-block rounded-full bg-[#bf9a36]/40 px-3 py-0.5 pt-1 text-sm text-white/80">
      Est. ${abbreviateNumber(estimatedValue)}
    </span>
  );
}

export default PropertyProfileEstimatedValueTag;
