import { useState } from "react";
import EmailForm from "@src/components/emailForm/EmailForm";
import EmailConfirmation from "@src/components/emailConfirmation/EmailConfirmation";
import PopupContainer from "@src/components/popupContainer/PopupContainer.jsx";

function SignIn() {
  const [email, setEmail] = useState("");
  const [isFirstStep, setIsFirstStep] = useState(true);

  return (
    <section className="flex h-dvh w-full flex-col items-center pt-[12dvh] text-center">
      {isFirstStep ? (
        <EmailForm
          setIsFirstStep={setIsFirstStep}
          email={email}
          setEmail={setEmail}
        />
      ) : (
        <PopupContainer>
          <EmailConfirmation email={email} />
        </PopupContainer>
      )}
    </section>
  );
}
export default SignIn;
