import { useNavigate } from "react-router-dom";

export const EmptyState = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center mt-[25vh]">
      <h2 className="text-grey-700 font-attention text-2xl mb-4">
        No Liked Homes Yet
      </h2>
      <p className="text-grey-500 font-content text-center mb-6">
        It looks like you haven&apos;t liked any homes. Start exploring and find your perfect home!
      </p>
      <button onClick={() => navigate("/discover/list")} className="bg-primary-500 text-white py-2 px-4 rounded">
        Browse Homes
      </button>
    </div>
  );
};

export default EmptyState;
