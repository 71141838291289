import { addPropertyOwner } from "@src/api/property";
import BaseModal from "@src/components/baseModal/BaseModal";
import {
  setPropertyOwner,
  setShowConfetti,
} from "@src/store/features/property/slice";
import { setRandomAndSearchPropertyOwner } from "@src/store/features/search/slice";
import { useState } from "react";
import { MdFlag } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ReactGA from "react-ga4";
import { getLoggedInUserInfo } from "@src/api/user.js";
import { setUserInfo } from "@src/store/features/user/slice.js";

export default function ClaimHomeVerificationModal({
  open,
  onClickOverlay,
  title = "Claim this home",
  introText = "You are about to take ownership of",
  outroText = ". Please confirm that you want to proceed with the claim.",
  propertyId,
  property,
}) {
  const dispatch = useDispatch();
  const homes = useSelector((state) => state.user.homes);
  const loggedInUser = useSelector((state) => state.user.user);
  const [isLoading, setisLoading] = useState(false);

  const addOwnerOfProperty = async () => {
    setisLoading(true);
    try {
      const response = await addPropertyOwner(propertyId);

      if (response?.status === "ownership updated") {
        toast.success("You have successfully claimed this home!");

        dispatch(setPropertyOwner({ propertyId, ownerId: loggedInUser?.id }));

        const response = await getLoggedInUserInfo();
        dispatch(setUserInfo(response));

        dispatch(
          setRandomAndSearchPropertyOwner({
            propertyId,
            ownerId: loggedInUser?.id,
          }),
        );
        onClickOverlay({ claimed: true });
        ReactGA.event("home_profile_claimed", {
          property_id: propertyId,
        });

        setTimeout(() => {
          dispatch(setShowConfetti(true));
        }, 100);
      } else if (response?.error) {
        onClickOverlay();
        toast.error(response.error);
      }
    } catch (error) {
      onClickOverlay();
      toast.error("Sorry, we couldn't add this property to you claimed homes");
    }
    setisLoading(false);
  };

  return (
    <BaseModal open={open} onClickOverlay={onClickOverlay}>
      <div className="modal-shadow flex flex-col items-center rounded-2xl bg-grey-100 p-[21px] pt-12">
        {homes?.length === 0 ? (
          <>
            <div className="absolute left-1/2 top-0 flex h-[70px] w-[70px] -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full border border-white bg-neutral-100 ring-1 ring-neutral-200">
              <MdFlag className="h-9 w-9 text-primary-500" />
            </div>
            <h2 className="mb-2 mt-2 font-attention text-2xl text-neutral-700">
              {title}
            </h2>
            <span className="mt-1 text-balance text-center font-content text-sm text-grey-800">
              {introText}{" "}
              <span className="font-semibold capitalize text-black">{`${property.address_line_1 ? property.address_line_1.toLowerCase() : ", "}${property.address_line_2 ? property.address_line_2.toLowerCase() : ", "} ${property.city ? property.city.toLowerCase() + ", " : " "}${property.state ? property.state + " " : ""}${property?.zip ? property.zip : ""}`}</span>
              {outroText}
            </span>
            <button
              disabled={isLoading}
              onClick={addOwnerOfProperty}
              className="mx-auto mb-3 mt-6 flex w-full max-w-60 justify-center gap-2 rounded-full bg-primary-500 p-3 px-6 font-semibold text-white shadow-lg shadow-primary-500/10"
            >
              {isLoading ? (
                <div className="flex items-center justify-center">
                  <div className="h-6 w-6 animate-spin rounded-full border-2 border-white border-t-transparent" />
                </div>
              ) : (
                "Yes, I own this home"
              )}
            </button>

            <button
              disabled={isLoading}
              className="mb-4 mt-3 text-balance text-sm text-[#737459] underline"
              onClick={onClickOverlay}
            >
              My mistake, I do not own this home
            </button>
          </>
        ) : (
          <>
            <div className="absolute left-1/2 top-0 flex h-[70px] w-[70px] -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full border border-white bg-neutral-100 ring-1 ring-neutral-200">
              <MdFlag className="h-9 w-9 text-red-400" />
            </div>
            <h2 className="mb-2 mt-2 font-attention text-2xl text-neutral-700">
              Sorry about this
            </h2>
            <span className="mt-1 text-pretty px-2 text-center font-content text-sm text-grey-800">
              Our records indicate that you have already claimed the maximum
              number of homes with Unlisted.
            </span>
            <button
              onClick={onClickOverlay}
              className="mx-auto mb-3 mt-6 flex w-full max-w-60 justify-center gap-2 rounded-full bg-primary-500 p-3 px-6 font-semibold text-white shadow-lg shadow-primary-500/10"
            >
              Got it
            </button>
          </>
        )}
      </div>
    </BaseModal>
  );
}
