import not_found from "@src/assets/not_found.svg";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();

  const goHome = () => {
    navigate("/");
  };

  return (
    <div className="min-h-[calc(100vh-226px)] py-24 mx-8">
      <div className="flex flex-col items-center text-center md:flex-row md:text-left">
        <div className="mb-8 md:mb-0 md:w-1/2">
          <img src={not_found} alt="img" className="max-w-[70%] md:max-w-full" />
        </div>
        <div className="md:w-1/2 md:pt-16 md:pr-12">
          <p className="text-lg mb-12">Sorry, we couldn&#39;t find what you were looking for.</p>
          <button 
            onClick={goHome} 
            className="w-full max-w-[320px] bg-white border-2 border-black text-black py-3 px-4 font-bold"
          >
            Back to app
          </button>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
