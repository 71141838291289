export default function FavoriteMenuIcon({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 36 36"
      fill="none"
    >
      <path d="M18 29.4812L16.8634 28.4545C14.4154 26.218 12.3904 24.3036 10.7884 22.7113C9.18663 21.1188 7.92225 19.7145 6.99525 18.4982C6.06825 17.282 5.42062 16.1815 5.05237 15.1967C4.68412 14.2122 4.5 13.2218 4.5 12.2256C4.5 10.3178 5.14813 8.71596 6.44438 7.41996C7.74038 6.12371 9.34225 5.47559 11.25 5.47559C12.5693 5.47559 13.8068 5.81309 14.9625 6.48809C16.1183 7.16309 17.1308 8.14483 18 9.43333C18.8693 8.14483 19.8818 7.16309 21.0375 6.48809C22.1933 5.81309 23.4307 5.47559 24.75 5.47559C26.6578 5.47559 28.2596 6.12371 29.5556 7.41996C30.8519 8.71596 31.5 10.3178 31.5 12.2256C31.5 13.2218 31.3159 14.2122 30.9476 15.1967C30.5794 16.1815 29.9318 17.282 29.0048 18.4982C28.0778 19.7145 26.8181 21.1188 25.2259 22.7113C23.6336 24.3036 21.6039 26.218 19.1366 28.4545L18 29.4812ZM18 27.4506C20.4 25.2813 22.375 23.4232 23.925 21.8762C25.475 20.329 26.7 18.9866 27.6 17.8491C28.5 16.7116 29.125 15.704 29.475 14.8262C29.825 13.9482 30 13.0813 30 12.2256C30 10.7256 29.5 9.47559 28.5 8.47559C27.5 7.47559 26.25 6.97559 24.75 6.97559C23.5557 6.97559 22.4539 7.31646 21.4444 7.99821C20.4346 8.67996 19.5308 9.70634 18.7328 11.0773H17.2673C16.45 9.68709 15.5414 8.65584 14.5414 7.98359C13.5414 7.31159 12.4443 6.97559 11.25 6.97559C9.76925 6.97559 8.524 7.47559 7.51425 8.47559C6.50475 9.47559 6 10.7256 6 12.2256C6 13.0813 6.175 13.9482 6.525 14.8262C6.875 15.704 7.5 16.7116 8.4 17.8491C9.3 18.9866 10.525 20.3241 12.075 21.8616C13.625 23.3991 15.6 25.2621 18 27.4506Z" />
    </svg>
  );
}
