import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import property_perks from "../../data/property_perks.js";
import SelectableTag from "../SelectableTag.jsx";
import { togglePropertyPerk } from "../../store/features/search/slice.js";

function SelectPerks({ prefix, values, setValues }) {
  //const [selectedPropertyPerks, setSelectedPropertyPerks] = useState(values);

  const handleTagChange = (id) => {
    setValues((prev) => {
      return prev.includes(id)
        ? prev.filter((item) => item !== id)
        : [...prev, id];
    });
    // onChange(id);
  };

  const isPerkSelected = (id) => {
    return values.includes(id);
  };

  return (
    <div className="flex-1 overflow-y-auto">
      <div className="flex flex-row flex-wrap items-start justify-center gap-4 gap-x-3">
        {property_perks.map((perk, index) => {
          return (
            <SelectableTag
              id={perk.id}
              prefix={prefix}
              emoji={perk.emoji}
              text={perk.text}
              key={index}
              selectable={true}
              selected={isPerkSelected(perk.id)}
              onChange={() => {
                handleTagChange(perk.id);
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

export default SelectPerks;
