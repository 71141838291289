import React from "react";

function Hotspot({ className, label }) {
  return (
    <div
      className={`absolute flex h-6 w-6 items-center justify-center ${className}`}
    >
      <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-sky-400 opacity-75"></span>
      <span className="absolute inline-flex h-full w-full animate-ping rounded-full ring-1 ring-sky-400 animate-delay-100"></span>
      <span className="relative inline-flex h-3 w-3 rounded-full bg-sky-500"></span>
      {label && (
        <div className="absolute -right-7 top-[calc(100%+14px)] flex w-28 items-center rounded-lg bg-sky-500 p-2 py-2 shadow-lg shadow-sky-200/50">
          <span className="text-center text-sm leading-[135%] text-white opacity-90">
            {label}
          </span>
          <span className="absolute right-10 top-0 h-8 w-[1px] -translate-y-full bg-sky-500"></span>
        </div>
      )}
    </div>
  );
}

export default Hotspot;
