import React, { useEffect, useState } from "react";
import numeral from "numeral";

function FilterRangeDisplay({
  rangeValues,
  prepend,
  steps,
  max,
  separator = " to ",
}) {
  const valuesArray = steps;

  const [rangeLabel, setRangeLabel] = useState("");

  useEffect(() => {
    const prefix = prepend ? prepend : "";

    const translateValue = (value) => {
      return valuesArray[value];
    };

    const formatValue = (value) => {
      let formatter = prefix === "$" ? "0.[00]a" : "0,0";
      let formatted = numeral(value).format(formatter);
      formatted = formatted.replace("m", "M"); // Replace 'm' with 'M'
      return formatted;
    };

    const formattedRangeLabel = `${prefix}${formatValue(translateValue(rangeValues[0]))}${separator}${prefix}${formatValue(translateValue(rangeValues[1]))}`;

    setRangeLabel(formattedRangeLabel);
  }, [rangeValues, valuesArray, prepend]);

  return <>{rangeLabel}</>;
}

export default FilterRangeDisplay;
