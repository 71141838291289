// src/redux/slices/imageCacheSlice.js
import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "imageCache",
  initialState: {},
  reducers: {
    cacheImage: (state, action) => {
      const { propertyId, imageUrl } = action.payload;
      state[propertyId] = imageUrl;
    },
    invalidateImage: (state, action) => {
      const propertyId = action.payload.toString();
      return { ...state, [propertyId]: undefined };
    },
  },
});

export const { cacheImage, invalidateImage } = slice.actions;
export default slice.reducer;
