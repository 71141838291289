import { useState } from "react";
import { MdOutlineEdit } from "react-icons/md";
import { useSelector } from "react-redux";
import EditProfileModal from "@src/components/editProfileModal/EditProfileModal";
import { getInitials } from "@src/utils/misc";
import BackButtonLink from "@src/components/backButtonLink/BackButtonLink.jsx";

export default function UserProfile() {
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const user = useSelector((state) => state.user.user);

  return (
    <div>
      <BackButtonLink className="pt-24" />
      <div className="flex w-full flex-col items-center px-4 pb-6 pb-8 pt-32">
        <div className="relative flex h-40 w-40 items-center justify-center overflow-hidden rounded-full border-2 bg-sage-400 shadow-lg">
          {user?.profile_picture_url ? (
            <img
              src={user?.profile_picture_url}
              alt="profile img"
              className="z-10 h-full w-full rounded-full object-cover"
            />
          ) : null}
          <p className="absolute font-content text-6xl text-grey-700">
            {getInitials({
              firstName:
                user?.first_name || user?.last_name
                  ? user?.first_name
                  : user?.email,
              lastName: user?.last_name,
            })}
          </p>
        </div>
        <div className="mt-6 flex flex-col items-center text-center font-content">
          {user?.first_name || user?.last_name ? (
            <p className="text-balance break-all font-attention text-3xl">
              {user?.first_name ?? ""} {user?.last_name ?? ""}
            </p>
          ) : null}
          <p className="mt-1 text-grey-600">{user?.email}</p>
          <div className="flex justify-end">
            <button
              className="mt-8 flex items-center gap-1 text-sky-600"
              onClick={() => setIsEditModalVisible(true)}
            >
              <MdOutlineEdit className="h-4 w-4 fill-current" />
              <span>Edit profile</span>
            </button>
          </div>
        </div>
        {isEditModalVisible && user?.id ? (
          <EditProfileModal
            open={isEditModalVisible}
            onClickOverlay={() => setIsEditModalVisible(false)}
          />
        ) : null}
      </div>
    </div>
  );
}
