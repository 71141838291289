import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
  useMemo,
} from "react";
import { AnimatePresence, motion, useAnimation } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import {
  MdArrowBack,
  MdArrowForward,
  MdCheck,
  MdChevronLeft,
  MdChevronRight,
  MdClose,
  MdList,
  MdMenu,
  MdMenuOpen,
  MdOutlineCheckCircleOutline,
  MdOutlineCottage,
  MdOutlineEdit,
} from "react-icons/md";
import PropertyProfileImage from "@src/components/propertyProfile/propertyProfileImage/PropertyProfileImage.jsx";
import TagsValueDisplay from "@src/components/TagsValueDisplay.jsx";
import SelectableTag from "@src/components/SelectableTag.jsx";
import { toast } from "react-toastify";
import { setPropertyPropValue } from "@src/store/features/property/slice.js";
import PropertyProfileEditableItemPanel from "@src/components/propertyProfile/propertyProfileEditor/PropertyProfileEditableItemPanel.jsx";
import PropertyProfileEditorTextInput from "@src/components/propertyProfile/propertyProfileEditor/propertyProfileEditorFormItems/propertyProfileEditorTextInput/PropertyProfileEditorTextInput.jsx";
import { useSearchParams } from "react-router-dom";
import PropertyProfileEditorCurrencyInput from "@src/components/propertyProfile/propertyProfileEditor/propertyProfileEditorFormItems/propertyProfileEditorCurrencyInput/PropertyProfileEditorCurrencyInput.jsx";
import { formatNumber } from "@src/utils/misc.js";
import SearchArchitecturalStyles from "@src/components/search/SearchArchitecturalStyles.jsx";
import SelectArchitecturalStyles from "@src/components/selectArchitecturalStyles/SelectArchitecturalStyles.jsx";
import architectural_styles from "@src/data/architectural_styles.js";
import home_details from "@src/data/home_details.js";
import property_perks from "@src/data/property_perks.js";
import selling_choices from "@src/data/selling_choices.js";
import ownership_cycle from "@src/data/ownership_cycle.js";
import ArchitecturalStyleIcon from "@src/assets/svgs/ArchitecturalStyleIcon.jsx";
import PropertyProfileEditorIncrementer from "@src/components/propertyProfile/propertyProfileEditor/propertyProfileEditorFormItems/propertyProfileEditorIncrementer/PropertyProfileEditorIncrementer.jsx";
import SelectPerks from "@src/components/selectPerks/SelectPerks.jsx";
import PropertyProfileEditorTexAreaInput from "@src/components/propertyProfile/propertyProfileEditor/propertyProfileEditorFormItems/propertyProfileEditorTextAreaInput/propertyProfileEditorTextAreaInput.jsx";
import * as Dialog from "@radix-ui/react-dialog";
import {
  getDisplayPropertyById,
  getRoutedPropertyById,
  updateProperty,
} from "@src/store/features/property/service.js";
import * as RadioGroup from "@radix-ui/react-radio-group";
import PropertyProfileMainImageUpdater from "@src/components/propertyProfile/propertyProfileEditor/propertyProfileEditorFormItems/propertyProfileMainImageUpdater/PropertyProfileMainImageUpdater.jsx";
import UpdatePropertyProfilePerks from "@src/components/updatePropertyProfilePerks/UpdatePropertyProfilePerks.jsx";
import PropertyProfileTextSliderInput from "@src/components/propertyProfile/propertyProfileEditor/propertyProfileEditorFormItems/propertyProfileTextSliderInput/PropertyProfileTextSliderInput.jsx";
import RadioGroupInput from "@src/components/radioGroupInput/RadioGroupInput.jsx";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import PropertyProfileImageManager from "@src/components/propertyProfile/propertyProfileEditor/propertyProfileEditorFormItems/propertyProfileImageManager/PropertyProfileImageManager.jsx";
import debounce from "lodash.debounce";
import SavedChangesIcon from "@src/assets/svgs/SavedChangesIcon.jsx";
import PropertyProfileFirstPhotoInput from "@src/components/propertyProfile/propertyProfileEditor/propertyProfileEditorFormItems/propertyProfileFirstPhotoInput/PropertyProfileFirstPhotoInput.jsx";
import { IoMdTrash } from "react-icons/io";
import price_range from "@src/data/price_range.js";

function PropertyProfileEditorModal({ property }) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentEditableItemId } = useSelector((state) => state.property);
  const [editableItem, setEditableItem] = useState(null);
  const [isEditingItem, setIsEditingItem] = useState(false);
  const [open, setOpen] = useState(false);
  const [updatedProperty, setUpdatedProperty] = useState(false);
  const isShowingProfileEditor = searchParams.get("edit");
  const controls = useAnimation();
  const [animateToItem, setAnimateToItem] = useState(false);
  const [editedItems, setEditedItems] = useState(new Set());

  const editableItems = [
    {
      id: "address",
      toastLabel: "Address",
      displayComponent: createEditableItem({
        id: "address",
        label: "Address",
        values: (() => {
          const valueToReturn =
            `${property?.address_line_1?.toLowerCase() ? property?.address_line_1?.toLowerCase() + ", " : ""} ${property.city ? property.city.toLowerCase() + ", " : " "}${property.state ? property.state + " " : ""}${property?.zip ? property.zip : ""}` ||
            "Not Added";
          return [{ value: valueToReturn }];
        })(),
        canEdit: false,
      }),
    },
    {
      id: "headline",
      toastLabel: "Headline",
      displayComponent: createEditableItem({
        id: "headline",
        label: "Headline",
        capitalize: false,
        placeholder: "Give your home a name or headline",
        // canEdit: false,
        values: [{ value: property.owner_headline || property.headline || "" }],
        onClick: () => handleEditableItemClick("headline"),
      }),
      title: "Give your home a captivating headline or unique name.",
      inputComponent: (
        <div className="mx-auto mt-12 block w-[min(calc(100%-5rem),20rem)]">
          <PropertyProfileEditorTextInput
            id={"headline"}
            title={"Give your home a captivating headline or unique name."}
            placeholder={"My Humble Homestead"}
            value={property.owner_headline || property.headline || ""}
            onChange={(value) => {
              setUpdatedProperty(true);
              setEditedItems((prev) => new Set([...prev, "headline"]));
              dispatch(setPropertyPropValue({ key: "owner_headline", value }));
            }}
          />
        </div>
      ),

      //     createTextInputComponent({
      //   id: "headline",
      //   title: "Give your home a captivating headline or unique name",
      //   placeholder: "My Humble Homestead",
      //   value: property.owner_headline || property.headline || "",
      //   onChange: (value) => {
      //     setUpdatedProperty(true);
      //     dispatch(setPropertyPropValue({ key: "owner_headline", value }));
      //   },
      // }),
    },
    {
      id: "owner_expected_value",
      toastLabel: "Owner value",
      displayComponent: createEditableItem({
        id: "owner_expected_value",
        label: "Owner value",
        values: [
          {
            value: `$${formatNumber(property.owner_expected_value || property.estimated_value || 0)}`,
          },
        ],
        onClick: () => handleEditableItemClick("owner_expected_value"),
      }),
      title: "What offer would motivate you to sell today?",
      description:
        "Other real estate sites try to estimate your home's worth. Unlisted lets you decide.",

      inputComponent: (
        <div className="mx-auto mt-8 block w-[min(calc(100%-5rem),20rem)]">
          <PropertyProfileTextSliderInput
            prop={{
              value: property.owner_expected_value,
              defaultValue:
                property.estimated_value || property.estimated_value_one || 0,
              icon: null,
              labelSingular: "",
              labelPlural: "",
              isCurrency: "$",
              min: 10000,
              max: 10000000,
              maxSliderValue: price_range?.values?.length - 1,
              interval: 25,
              roundTo: 25,
              isInputWithSlider: true,
              placeholder: "Estimated value",
              values: price_range?.values,
              steps: price_range?.steps,
            }}
            onChange={async (value) => {
              // setEditedScreens((prev) => [...prev, "owner_expected_value"]);
              setUpdatedProperty(true);

              await new Promise((resolve) => setTimeout(resolve, 20));

              setEditedItems(
                (prev) => new Set([...prev, "owner_expected_value"]),
              );
              dispatch(
                setPropertyPropValue({ key: "owner_expected_value", value }),
              );
            }}
          />
        </div>
      ),

      //     createCurrencyInputComponent({
      //   id: "owner_expected_value",
      //   // title: "If you sold your home today, how much would you ask for?",
      //   placeholder: "250,000",
      //   value: property.owner_expected_value || property.estimated_value || 0,
      //   onChange: (value) => {
      //     setUpdatedProperty(true);
      //     dispatch(
      //       setPropertyPropValue({ key: "owner_expected_value", value }),
      //     );
      //   },
      // }),
    },
    // {
    //   id: "all_photos",
    //   toastLabel: "Photos",
    //
    //   displayComponent: (
    //     <button
    //       onClick={() => handleEditableItemClick("all_photos")}
    //       className="flex w-full flex-row items-center justify-between rounded-lg bg-white/80 p-3 text-left shadow-md shadow-transparent ring-1 ring-neutral-200 transition-all md:hover:bg-white md:hover:shadow-neutral-200"
    //     >
    //       <div className="flex flex-col gap-2">
    //         <label className="mb-1 text-sm font-semibold text-neutral-800">
    //           Photos
    //         </label>
    //         <div className="max-w-24 overflow-hidden rounded-md">
    //           <PropertyProfileImage property={property} />
    //         </div>
    //       </div>
    //
    //       <MdChevronRight className="h-7 w-7 shrink-0 fill-neutral-500" />
    //     </button>
    //   ),
    //   inputLayout: "flex flex-col min-h-full",
    //   inputComponent: <PropertyProfileImageManager property={property} />,
    // },

    {
      id: "cover_photo",
      toastLabel: "Cover Photo",

      displayComponent: (
        <button
          onClick={() => handleEditableItemClick("cover_photo")}
          className="flex w-full flex-row items-center justify-between rounded-lg bg-white/80 p-3 text-left shadow-md shadow-transparent ring-1 ring-neutral-200 transition-all md:hover:bg-white md:hover:shadow-neutral-200"
        >
          <div className="flex flex-col gap-2">
            <label className="mb-1 text-sm font-semibold text-neutral-800">
              Cover Photo
            </label>
            <div className="aspect-video max-w-28 overflow-hidden rounded-md">
              <PropertyProfileImage property={property} />
            </div>
          </div>

          <MdChevronRight className="h-7 w-7 shrink-0 fill-neutral-500" />
        </button>
      ),
      inputLayout: "flex flex-col min-h-full",
      inputComponent: (
        <div className="mx-auto mt-8 block w-[min(calc(100%-5rem),30rem)]">
          <h2 className="z-20 mx-auto max-w-[26ch] text-pretty bg-[#fafafa] pt-7 text-center font-attention text-2xl text-neutral-900 md:text-3xl">
            Add a cover photo that captures your home&apos;s unique charm.
          </h2>

          <div className="mt-12 h-64 overflow-hidden rounded-xl">
            <PropertyProfileFirstPhotoInput
              property={property}
              onChange={(photoUrl) => {
                setUpdatedProperty(true);
                setEditedItems((prev) => new Set([...prev, "cover_photo"]));
                dispatch(
                  setPropertyPropValue({
                    key: "cover_photo_url",
                    value: photoUrl,
                  }),
                );
              }}
            />
          </div>
        </div>
      ),
    },

    {
      id: "style",
      toastLabel: "Architectural Style",
      displayComponent: createEditableItem({
        id: "style",
        label: "Architectural Style",
        capitalize: true,
        placeholder: "Not added",
        values: [
          {
            icon: ArchitecturalStyleIcon,
            value: (() => {
              if (
                ((property.owner_given_style !== 10000 &&
                  property.owner_given_style !== null) ||
                  property.style !== null) &&
                architectural_styles.find(
                  (style) =>
                    style.id === property.owner_given_style ||
                    style.id === property.style,
                )
              ) {
                if (
                  property.owner_given_style !== 10000 &&
                  property.owner_given_style !== null &&
                  property.owner_given_style !== undefined
                ) {
                  const styleNames = [property.owner_given_style].map((id) => {
                    const style = architectural_styles.find(
                      (style) => style.id === id,
                    );
                    return style.label;
                  });
                  return styleNames.join(", ");
                } else if (
                  property.owner_given_style !== 10000 &&
                  property.style !== null &&
                  property.style !== undefined
                ) {
                  const styleNames = [property.style].map((id) => {
                    const style = architectural_styles.find(
                      (style) => style.id === id,
                    );
                    return style.label;
                  });

                  return styleNames.join(", ");
                }
              }
              return "Not Set";
            })(),
          },
        ],
        onClick: () => handleEditableItemClick("style"),
      }),
      title: "What is your home's architectural style?",
      inputComponent: (
        <div className="flex w-full flex-1 flex-col items-center justify-center overflow-hidden">
          <div className="w-full flex-1 overflow-y-auto px-6 py-4">
            <div className="mx-auto mt-8 max-w-xl">
              <SelectArchitecturalStyles
                id="style"
                title="Select Architectural Styles"
                prefix="set"
                allowMultiple={false}
                value={
                  (property.owner_given_style !== null &&
                    property.owner_given_style !== undefined && [
                      property.owner_given_style,
                    ]) ||
                  (property.style && [property.style]) ||
                  []
                }
                onChange={(styles) => {
                  setUpdatedProperty(true);
                  setEditedItems((prev) => new Set([...prev, "style"]));
                  dispatch(
                    setPropertyPropValue({
                      key: "owner_given_style",
                      value: styles[0],
                    }),
                  );
                }}
              />
            </div>
          </div>
        </div>
      ),
    },

    {
      id: "home_details",
      toastLabel: "Home Details",
      displayComponent: createEditableItem({
        id: "home_details",
        label: "Home Details",
        //generate values with a self calling function that
        //filters out the home details existing on the property that are not null...
        values: (() => {
          const foundDetails = home_details
            .filter((prop) => prop.id !== "building_area")
            //then map over the filtered details and return an object with the icon and value
            .map((prop) => {
              //we know of the two is not null, so we can return the one that is not null, giving preference to the owner_id
              const value = property[prop.owner_id] || property[prop.id] || 0;
              //return the object with the icon and value
              return {
                icon: prop.icon,
                value: `${formatNumber(value)} ${value > 1 ? prop.labelPlural : prop.labelSingular}`,
              };
              // }
            });
          return foundDetails;
        })(),
        onClick: () => handleEditableItemClick("home_details"),
      }),
      title: "Home Details",
      description:
        "Take control. Update your property data to ensure accuracy.",
      inputComponent: (() => {
        //filter out the home details that are not null
        const foundDetails = home_details
          .filter((prop) => prop.id !== "building_area")
          //then map over the filtered details and return an object with the icon and value
          .map((prop) => {
            return {
              ...prop,
              value: property[prop.owner_id] || property[prop.id] || 0,
            };
          });
        return (
          <div className="mx-auto mt-12 flex h-full w-full max-w-[min(calc(100%-6rem),28rem)] flex-col gap-12">
            <div className="flex flex-col gap-8">
              {foundDetails.map((prop, index) => (
                <PropertyProfileEditorIncrementer
                  prop={prop}
                  key={index}
                  onChange={(value) => {
                    setUpdatedProperty(true);
                    setEditedItems(
                      (prev) => new Set([...prev, "home_details"]),
                    );
                    dispatch(
                      setPropertyPropValue({ key: prop.owner_id, value }),
                    );
                  }}
                />
              ))}
            </div>
          </div>
        );
      })(),
    },

    {
      id: "square_footage",
      toastLabel: "Square Footage",
      displayComponent: createEditableItem({
        id: "square_footage",
        label: "Square Feet",
        values: (() => {
          const foundDetails = home_details
            .filter((prop) => prop.id === "building_area")
            //then map over the filtered details and return an object with the icon and value
            .map((prop) => {
              //we know of the two is not null, so we can return the one that is not null, giving preference to the owner_id
              const value = property[prop.owner_id] || property[prop.id] || 0;
              //return the object with the icon and value
              return {
                icon: prop.icon,
                value: `${formatNumber(value)} ${value > 1 ? prop.labelPlural : prop.labelSingular}`,
              };
              // }
            });
          return foundDetails;
        })(),
        onClick: () => handleEditableItemClick("square_footage"),
      }),
      title: "Please enter your home's square footage.",
      inputComponent: (() => {
        //filter out the home details that are not null
        const foundDetails = home_details
          .filter((prop) => prop.id === "building_area")
          //then map over the filtered details and return an object with the icon and value
          .map((prop) => {
            return {
              ...prop,
              value: property[prop.owner_id] || property[prop.id] || 0,
            };
          });
        return (
          <div className="mx-auto mt-8 block w-[min(calc(100%-5rem),20rem)]">
            {foundDetails.map((prop, index) => (
              <PropertyProfileTextSliderInput
                prop={prop}
                key={index}
                onChange={(value) => {
                  setUpdatedProperty(true);
                  setEditedItems(
                    (prev) => new Set([...prev, "square_footage"]),
                  );
                  dispatch(setPropertyPropValue({ key: prop.owner_id, value }));
                }}
              />
            ))}
          </div>
        );
      })(),
    },

    {
      id: "owner_blurb",
      toastLabel: "About",
      displayComponent: createEditableItem({
        id: "owner_blurb",
        label: "About",
        capitalize: false,
        placeholder: "No description added",
        truncate: false,
        textStyle: "paragraph",
        values: [
          {
            value: property.owner_blurb || "",
          },
        ],
        onClick: () => handleEditableItemClick("owner_blurb"),
      }),
      title: "Share what makes your home special.",
      description:
        "Tell us about your home! Don’t forget to mention any special or unique qualities.",
      inputComponent: (
        <PropertyProfileEditorTexAreaInput
          id={"owner_blurb"}
          // title={title}
          // content={content}
          placeholder={"No description added"}
          value={property.owner_blurb || ""}
          onChange={(value) => {
            setUpdatedProperty(true);
            setEditedItems((prev) => new Set([...prev, "owner_blurb"]));
            dispatch(setPropertyPropValue({ key: "owner_blurb", value }));
          }}
        />
      ),

      //     createTextAreaInputComponent({
      //   id: "owner_blurb",
      //   title: "Share what makes your home special",
      //   content:
      //     "Tell us about your home! Don’t forget to mention any special or unique qualities.",
      //   placeholder: "No description added",
      //   value: property.owner_blurb || "",
      //   onChange: (value) => {
      //     setUpdatedProperty(true);
      //     dispatch(setPropertyPropValue({ key: "owner_blurb", value }));
      //   },
      // }),
    },
    {
      id: "owner_ownership_cycle",
      toastLabel: "Ownership Phase",
      displayComponent: createEditableItem({
        id: "owner_ownership_cycle",
        label: "Ownership Phase",
        capitalize: false,
        placeholder: "Which phase of homeownership are you in?",
        values: [
          {
            value: property.owner_ownership_cycle
              ? `${
                  ownership_cycle.find(
                    ({ id }) => id === property.owner_ownership_cycle,
                  ).title
                }: ${
                  ownership_cycle.find(
                    ({ id }) => id === property.owner_ownership_cycle,
                  ).description
                }`
              : "",
          },
        ],
        onClick: () => handleEditableItemClick("owner_ownership_cycle"),
      }),
      title: "Which phase of homeownership are you in?",
      inputComponent: (
        <SellingChoiceRadioGroup
          options={ownership_cycle}
          value={property.owner_ownership_cycle}
          onChange={(newValue) => {
            setUpdatedProperty(true);
            setEditedItems(
              (prev) => new Set([...prev, "owner_ownership_cycle"]),
            );
            dispatch(
              setPropertyPropValue({
                key: "owner_ownership_cycle",
                value: newValue,
              }),
            );
          }}
        />
      ),
    },
    {
      id: "owner_selling_choice",
      toastLabel: "Your Timeline",
      displayComponent: createEditableItem({
        id: "owner_selling_choice",
        label: "Your Timeline",
        capitalize: false,
        placeholder: "When might you sell your home?",
        values: [
          {
            value: property.owner_selling_choice
              ? selling_choices.find(
                  ({ id }) => id === property.owner_selling_choice,
                )?.title
              : "",
          },
        ],
        onClick: () => handleEditableItemClick("owner_selling_choice"),
      }),
      title: "When if ever, do you plan to sell your home?",
      inputComponent: (
        <SellingChoiceRadioGroup
          options={selling_choices}
          value={property.owner_selling_choice}
          onChange={(newValue) => {
            setUpdatedProperty(true);
            setEditedItems(
              (prev) => new Set([...prev, "owner_selling_choice"]),
            );
            dispatch(
              setPropertyPropValue({
                key: "owner_selling_choice",
                value: newValue,
              }),
            );
          }}
        />
      ),
    },
    {
      id: "perks",
      toastLabel: "Perks",
      displayComponent: (
        <EditableTagsItem
          label="Perks"
          canEdit={true}
          placeholder="None selected"
          values={(() => {
            let ownerPerks = property?.owner_perks || [];

            return property_perks?.filter(
              (item) =>
                ownerPerks?.includes(item.id) ||
                (property?.owner_perks === null && property?.[item.id]),
            );
          })()}
          onClick={() => handleEditableItemClick("perks")}
        />
      ),
      title: "What bonus features make your home special?",
      inputComponent: (() => {
        return (
          <>
            <div className="w-full flex-1 overflow-y-auto px-6 pb-12 pt-4">
              <div className="mx-auto mt-6 max-w-2xl">
                <UpdatePropertyProfilePerks
                  property={property}
                  onChange={() => {
                    setEditedItems((prev) => new Set([...prev, "perks"]));
                    setUpdatedProperty(true);
                  }}
                />
              </div>
            </div>
          </>
        );
      })(),
    },
  ];

  const editScreens = editableItems.filter((item) => item.inputComponent);

  const [currentScreenIndex, setCurrentScreenIndex] = useState(0);
  const [shouldAnimate, setShouldAnimate] = useState(true);
  const containerRef = useRef(null);

  const handleEditableItemClick = (id) => {
    const index = editScreens.findIndex((item) => item.id === id);
    setCurrentScreenIndex(index);
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("item", id);
    setSearchParams(newSearchParams);
  };

  useEffect(() => {
    const itemId = searchParams.get("item");
    // const animate = searchParams.get("animate");
    const isDisplayingProperty = searchParams.get("showing");
    const editableItem = editableItems.find(({ id }) => id === itemId);

    if (editableItem) {
      setEditableItem(editableItem);
      setIsEditingItem(true);
      const index = editScreens.findIndex((item) => item.id === itemId);
      if (animateToItem) {
        controls.start({ x: `${(index / editScreens.length) * -100}%` });
      } else {
        controls.set({ x: `${(index / editScreens.length) * -100}%` });
      }
      setCurrentScreenIndex(index);
    } else {
      setEditableItem(null);
      setIsEditingItem(false);
      //dispatch(updateProperty(property));
    }

    if (isShowingProfileEditor) {
      const itemId = searchParams.get("item");
      const index = editScreens.findIndex((item) => item.id === itemId);
      setCurrentScreenIndex(index);
      setOpen(true);
    }
  }, [searchParams]);

  // useEffect(() => {
  //   const fetchAndUpdateProperty = async () => {
  //     const itemId = searchParams.get("item");
  //     const isDisplayingProperty = searchParams.get("showing");
  //     const isEditing = searchParams.get("edit");
  //     const editableItem = editableItems.find(({ id }) => id === itemId);
  //
  //     if (editableItem) {
  //       setEditableItem(editableItem);
  //       setIsEditingItem(true);
  //     } else {
  //       setEditableItem(null);
  //       setIsEditingItem(false);
  //
  //       if (!isEditing) {
  //         await dispatch(
  //           updateProperty({ propertyData: property, isDraft: false }),
  //         );
  //       }
  //     }
  //   };
  //
  //   // Call the async function
  //   fetchAndUpdateProperty();
  // }, [searchParams]);

  // useEffect(() => {
  //   if (isShowingProfileEditor) {
  //     const itemId = searchParams.get("item");
  //     const index = editScreens.findIndex((item) => item.id === itemId);
  //     setCurrentScreenIndex(index);
  //     setOpen(true);
  //   }
  // }, [searchParams]);

  const handleOnEditPanelExit = (itemId, draft = true, showToast = false) => {
    const fetchAndUpdateProperty = async (itemId, draft) => {
      // const itemId = searchParams.get("item");
      const isDisplayingProperty = searchParams.get("showing");
      const isEditing = searchParams.get("edit");
      const editableItem = editableItems.find(({ id }) => id === itemId);

      //let itemLabel = "Home Profile";

      //if (editableItem && editableItem.toastLabel)
      // itemLabel = editableItem.toastLabel;

      // Dispatch the async actions
      try {
        await dispatch(
          updateProperty({ propertyData: property, isDraft: draft }),
        );
        if (isEditing && updatedProperty) {
          setUpdatedProperty(false);
        }
        if (showToast && editedItems.size > 0)
          toast.success(`Updated profile successfully.`);

        if (isDisplayingProperty) {
          await dispatch(getDisplayPropertyById(property.id));
        } else {
          await dispatch(getRoutedPropertyById(property.id));
        }
      } catch (error) {
        console.error("Failed to update and fetch property:", error);
      }
    };

    // Call the async function
    fetchAndUpdateProperty(itemId, draft);
  };

  const handleBackToMenu = () => {
    const currentEditableItemId = editScreens[currentScreenIndex].id;
    handleOnEditPanelExit(currentEditableItemId);
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete("item");
    // newSearchParams.delete("animate");
    setAnimateToItem(false);
    setSearchParams(newSearchParams);
  };

  const goToStep = async (step) => {
    if (step < 0) return;

    const currentEditableItemId = editScreens[currentScreenIndex].id;

    console.log("currentEditableItemId", currentEditableItemId);

    handleOnEditPanelExit(currentEditableItemId);

    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("item", editScreens[step].id);
    // newSearchParams.set("animate", "true");
    setAnimateToItem(true);
    setSearchParams(newSearchParams);
  };

  const handleEscapeKeyDown = (e) => {
    e.preventDefault();
  };

  const closeEditor = () => {
    setOpen(false);
    const newSearchParams = new URLSearchParams(searchParams);

    handleOnEditPanelExit(currentEditableItemId, false, true);

    setTimeout(() => {
      newSearchParams.delete("edit");
      newSearchParams.delete("item");
      newSearchParams.delete("photo_tab");
      setAnimateToItem(false);
      setSearchParams(newSearchParams);
    }, 300);
  };

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (updatedProperty) {
  //       const currentEditableItemId = editScreens[currentScreenIndex].id;
  //       handleOnEditPanelExit(currentEditableItemId, true);
  //     }
  //   }, 1000);
  //   return () => clearTimeout(timer);
  // }, [editedItems]);

  useEffect(() => {
    setEditedItems(new Set([]));
  }, [open]);

  return (
    <>
      {isShowingProfileEditor ? (
        <Dialog.Root open={open} onOpenChange={setOpen}>
          <Dialog.Portal>
            <Dialog.Overlay className="fixed inset-0 z-50 bg-black/40 data-[state=closed]:animate-[fade-out_300ms] data-[state=open]:animate-[fade-in_300ms]" />
            <Dialog.Content
              onOpenAutoFocus={(e) => e.preventDefault()}
              onEscapeKeyDown={(event) => {
                event.preventDefault();
                closeEditor();
              }}
              className="pointer-events-none fixed left-1/2 top-0 z-50 flex h-dvh w-dvw -translate-x-1/2 flex-col data-[state=closed]:animate-[slight-slide-down-fade-out_300ms] data-[state=open]:animate-[slight-slide-up-fade-in_300ms]"
            >
              <VisuallyHidden>
                <Dialog.Title>Please edit your profile</Dialog.Title>
                <Dialog.Description>
                  Click the button to launch the profile editor
                </Dialog.Description>
              </VisuallyHidden>
              <>
                <div onClick={closeEditor} className="fixed inset-0 z-0"></div>
                <div
                  ref={containerRef}
                  className="absolute left-1/2 flex h-full w-dvw -translate-x-1/2 flex-col items-center justify-center overflow-hidden text-balance bg-[#FAFAFA] md:bottom-auto md:top-1/2 md:h-[calc(100%-5rem)] md:w-[min(calc(100vw-2rem),42rem)] md:-translate-y-1/2 md:rounded-3xl"
                >
                  <div className="flex h-full w-full flex-col justify-end">
                    <header className="relative z-30 flex select-none items-center border-b border-b-neutral-300 p-2 py-5 pb-4 font-content text-sm font-semibold text-neutral-500">
                      <button
                        tabIndex={editableItem ? 0 : -1}
                        className={`flex items-center px-3 ${editableItem ? "pointer-events-auto opacity-100" : "pointer-events-none opacity-0"}`}
                        onClick={handleBackToMenu}
                      >
                        <MdMenuOpen className="h-6 w-6" />
                      </button>

                      <div className="flex flex-1 items-center justify-center gap-4 text-center tracking-tighter sm:tracking-normal">
                        <div className="relative flex items-center whitespace-nowrap px-2">
                          {editedItems.has(
                            editScreens[currentScreenIndex]?.id,
                          ) && (
                            <SavedChangesIcon className="absolute right-full flex h-5 w-5 fill-green-600" />
                          )}
                          {editableItem
                            ? editScreens[currentScreenIndex].toastLabel
                            : "Edit Profile"}
                        </div>
                      </div>

                      <button
                        onClick={closeEditor}
                        className={`flex items-center px-3`}
                      >
                        <MdClose className="h-6 w-6" />
                      </button>
                    </header>
                    <div className="relative flex w-full flex-1 justify-start overflow-hidden">
                      <motion.div
                        initial={{
                          left: 0,
                          width: `${((currentScreenIndex + 1) / editScreens.length) * 100}%`,
                        }}
                        animate={{
                          left: 0,
                          width: `${((currentScreenIndex + 1) / editScreens.length) * 100}%`,
                        }}
                        className="absolute left-0 top-0 z-10 h-0.5 w-full origin-left bg-primary-500"
                      ></motion.div>
                      <div className="flex h-full w-full overflow-hidden">
                        <motion.div
                          initial={{
                            marginLeft: editableItem ? "-100%" : "0%",
                          }}
                          animate={{
                            marginLeft: editableItem ? "-100%" : "0%",
                          }}
                          transition={{ ease: "circInOut" }}
                          className="z-40 mx-auto h-full w-full shrink-0 overflow-y-auto bg-[#fafafa] p-3 py-4 md:w-[min(calc(100vw-2rem),42rem)] md:p-3 md:px-4 md:py-4"
                        >
                          <fieldset
                            className="flex flex-col gap-3 md:gap-3.5"
                            disabled={editableItem}
                          >
                            {editableItems.map((item, index) => (
                              <div key={index}>{item.displayComponent}</div>
                            ))}
                          </fieldset>
                        </motion.div>
                        <motion.div
                          initial={{
                            x: `${(currentScreenIndex / editScreens.length) * -100}%`,
                          }}
                          animate={controls}
                          transition={{ ease: "circInOut" }}
                          className="grid h-full w-fit grid-flow-col"
                        >
                          {editScreens.map((screen, index) => (
                            <Fragment key={screen.id}>
                              <div
                                data-screen-id={screen.id}
                                className="hide-scrollbar relative flex w-dvw flex-1 flex-col justify-center overflow-hidden md:w-[min(calc(100vw-2rem),42rem)]"
                              >
                                <div
                                  className={`${screen.inputLayout ? screen.inputLayout : "max-h-full"} overflow-y-auto py-1`}
                                >
                                  <AnimatePresence>
                                    {index === currentScreenIndex && (
                                      <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        className="min-h-full"
                                      >
                                        {screen.title && (
                                          <p className="z-20 mx-auto block max-w-[26ch] text-pretty bg-[#fafafa] px-3 pt-7 text-center font-attention text-2xl leading-relaxed text-neutral-900 md:text-3xl md:text-neutral-700">
                                            {screen.title}
                                          </p>
                                        )}
                                        {screen.description && (
                                          <p className="mx-auto mt-6 max-w-[40ch] text-pretty px-3 text-center text-neutral-600">
                                            {screen.description}
                                          </p>
                                        )}
                                        {screen.inputComponent}
                                      </motion.div>
                                    )}
                                  </AnimatePresence>
                                </div>
                              </div>
                            </Fragment>
                          ))}
                        </motion.div>
                      </div>
                    </div>

                    <footer
                      className={`relative flex ${editableItem ? "h-16 transition-all delay-0 duration-150" : "h-0 transition-all delay-150 duration-150"} w-full items-stretch justify-between overflow-hidden border-t border-t-neutral-300`}
                    >
                      {/*{editableItem ? (*/}
                      <AnimatePresence>
                        {editableItem && (
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className="flex h-full w-full items-stretch"
                          >
                            <button
                              key="prev"
                              onClick={() =>
                                goToStep(
                                  currentScreenIndex - 1 < 0
                                    ? editScreens.length - 1
                                    : currentScreenIndex - 1,
                                )
                              }
                              className={`transition-all ${editableItem ? "opacity-100 delay-0 duration-150" : "opacity-0 delay-150 duration-150"} group relative flex flex-1 items-center justify-start gap-2 overflow-hidden px-6 py-3 pl-3 text-sm text-neutral-500 transition-all md:pl-8 md:hover:bg-neutral-200/70 md:hover:text-neutral-800`}
                            >
                              <MdArrowBack className="relative h-4 w-4 shrink-0 transition-transform md:group-hover:-translate-x-1/4" />
                              <span className="relative">
                                {currentScreenIndex - 1 < 0
                                  ? editScreens[editScreens.length - 1]
                                      .toastLabel
                                  : editScreens[currentScreenIndex - 1]
                                      .toastLabel}
                              </span>
                            </button>
                            <button
                              key="next"
                              onClick={() =>
                                goToStep(
                                  currentScreenIndex + 1 < editScreens.length
                                    ? currentScreenIndex + 1
                                    : 0,
                                )
                              }
                              className="group relative flex flex-1 items-center justify-end gap-2 overflow-hidden px-6 py-3 pr-3 text-sm text-neutral-500 transition-all md:pr-8 md:hover:bg-neutral-200/70 md:hover:text-neutral-800"
                            >
                              <span className="relative">
                                {currentScreenIndex + 1 < editScreens.length
                                  ? editScreens[currentScreenIndex + 1]
                                      .toastLabel
                                  : editScreens[0].toastLabel}
                              </span>

                              <MdArrowForward className="relative h-4 w-4 shrink-0 transition-transform md:group-hover:translate-x-1/4" />
                            </button>
                          </motion.div>
                        )}
                      </AnimatePresence>

                      {/*) : null}*/}
                    </footer>
                  </div>
                </div>
              </>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>
      ) : null}
    </>
  );
}

const EditableItem = ({
  capitalize,
  icon: Icon,
  label,
  value,
  textStyle,
  truncate,
  placeholder,
  values,
  canEdit,
  onClick,
}) => {
  return (
    <button
      disabled={!canEdit}
      className={`flex w-full flex-row items-center justify-between rounded-lg bg-white/80 p-3 text-left shadow-md shadow-transparent ring-1 ring-neutral-200 transition-all md:hover:bg-white md:hover:shadow-neutral-200 ${!canEdit ? "pointer-events-none" : ""}`}
      onClick={onClick}
    >
      <div className="flex flex-col gap-2">
        {label && (
          <label className="mb-1 text-sm font-semibold text-neutral-800">
            {label}
          </label>
        )}
        {values && (
          <div className="flex flex-col gap-3">
            {values.map(({ icon: Icon, value }, index) => (
              <div key={index} className="flex items-center gap-2">
                {Icon && <Icon className="h-5 w-5 fill-neutral-500" />}
                <span
                  className={`max-w-[calc(100vw-5rem)] ${truncate ? "truncate" : ""} ${textStyle == "paragraph" ? "whitespace-pre-line font-normal leading-6" : "font-semibold"} ${capitalize ? "capitalize" : ""} ${
                    !value ? "text-neutral-300" : "text-neutral-800"
                  }`}
                >
                  {value ? value : placeholder}
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
      {canEdit && (
        <MdChevronRight className="h-7 w-7 shrink-0 fill-neutral-500" />
      )}
    </button>
  );
};

const EditableTagsItem = ({
  label,
  values = [],
  onClick,
  canEdit = true,
  placeholder,
}) => {
  return (
    <button
      onClick={() => {
        if (canEdit) onClick();
      }}
      className="flex w-full flex-row items-center justify-between rounded-lg bg-white/80 p-3 text-left shadow-md shadow-transparent ring-1 ring-neutral-200 transition-all md:hover:bg-white md:hover:shadow-neutral-200"
    >
      <div className="flex flex-col gap-2">
        {label && (
          <label className="mb-2 text-sm font-semibold text-neutral-800">
            {label}
          </label>
        )}
        {values.length === 0 && (
          <p className="text-left font-semibold text-neutral-300">
            {placeholder}
          </p>
        )}
        {values.length > 0 && (
          <div className="flex flex-row flex-wrap gap-2">
            {values.map((tag, index) => {
              return (
                <SelectableTag emoji={tag.emoji} text={tag.text} key={index} />
              );
            })}
          </div>
        )}
      </div>
      {canEdit && (
        <MdChevronRight className="h-7 w-7 shrink-0 fill-neutral-500" />
      )}
    </button>
  );
};

const SellingChoiceRadioGroup = ({ value, onChange, options }) => {
  // const [selectedValue, setSelectedValue] = useState(value);
  return (
    <>
      <div className="mt-12 w-full px-3 md:px-8">
        <RadioGroupInput value={value} onChange={onChange} options={options} />
      </div>
    </>
  );
};

const createEditableItem = ({
  id,
  label,
  placeholder,
  values,
  capitalize = true,
  truncate = true,
  textStyle = "label",
  canEdit = true,
  onClick,
}) => (
  <EditableItem
    capitalize={capitalize}
    label={label}
    values={values}
    truncate={truncate}
    textStyle={textStyle}
    canEdit={canEdit}
    placeholder={placeholder}
    onClick={canEdit ? onClick : null}
  />
);

const createTextInputComponent = ({
  id,
  title,
  placeholder,
  value,
  onChange,
}) => (
  <PropertyProfileEditorTextInput
    id={id}
    title={title}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
  />
);

const createTextAreaInputComponent = ({
  id,
  title,
  content,
  placeholder,
  value,

  onChange,
}) => (
  <PropertyProfileEditorTexAreaInput
    id={id}
    title={title}
    content={content}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
  />
);

const createCurrencyInputComponent = ({
  id,
  title,
  placeholder,
  value,
  onChange,
}) => (
  <PropertyProfileEditorCurrencyInput
    id={id}
    title={title}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
  />
);

export default PropertyProfileEditorModal;
