import React from "react";

function SelectableTag({
  id,
  prefix,
  emoji,
  text,
  selectable = false,
  selected,
  onChange,
}) {
  const onTagChange = () => {
    if (selectable) {
      onChange(id);
    }
  };
  return (
    <div>
      <input
        type="checkbox"
        checked={selected}
        id={prefix ? `${prefix}_${id}` : id}
        className={`${selectable && "peer"} hidden`}
        onChange={(e) => {
          onTagChange();
        }}
      />
      <label
        htmlFor={prefix ? `${prefix}_${id}` : id}
        className={`flex h-fit flex-row items-center gap-2 rounded-full bg-gray-200 p-2 px-4 text-sm text-gray-600 ${selectable && "cursor-pointer peer-checked:bg-[#5D655F] peer-checked:text-white sm:hover:bg-[#5D655F]/20"} transition-colors`}
      >
        {emoji && <span className="">{emoji}</span>}
        <span className="">{text}</span>
      </label>
    </div>
  );
}

export default SelectableTag;
