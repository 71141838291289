import React from "react";
import { useNavigate } from "react-router-dom";
import { dateTimeFormatter } from "@src/utils/misc";
import PropertyProfileImage from "@src/components/propertyProfile/propertyProfileImage/PropertyProfileImage.jsx";
import { MdChevronRight } from "react-icons/md";

function ListItem({
  name,
  lastMsg,
  senderName,
  lastMsgTime,
  property,
  unreadCount,
  isPropertyMode,
  proposal_id,
}) {
  const navigate = useNavigate();
  return (
    <div
      className="mb-4 min-h-16 border-b py-3"
      onClick={() => navigate(`/conversation/${proposal_id}`)}
    >
      <div className="flex flex-row items-start gap-4">
        {isPropertyMode ? (
          <div className="mt-1 aspect-square w-16 shrink-0 overflow-hidden rounded-lg">
            <PropertyProfileImage property={property} />
          </div>
        ) : (
          <div
            className={`mt-1 flex h-[56px] w-[56px] items-center justify-center rounded-full ${
              Math.random() < 0.5 ? "bg-gold-250" : "bg-sage-300"
            }`}
          >
            <span className="text-xl font-bold text-white">
              {name.charAt(0).toUpperCase()}
            </span>
          </div>
        )}
        <div className="flex h-full flex-1 flex-col">
          <span className="font-content text-base text-sm font-semibold capitalize text-grey-800">
            {name.toLowerCase()}
          </span>
          <span className="mt-0.5 line-clamp-2 w-full overflow-hidden overflow-ellipsis font-content text-sm font-normal leading-4 text-grey-600">
            {senderName + ": " + lastMsg}
          </span>
          <span className="mt-1.5 font-content text-xs text-grey-400">
            {lastMsgTime ? dateTimeFormatter(lastMsgTime) : ""}
          </span>
        </div>

        {senderName != "You" && unreadCount > 0 && (
          <div className="flex items-center justify-center self-center">
            <div className="align center flex h-6 min-w-6 items-center justify-center self-center rounded-2xl bg-teal-700 px-2 text-white">
              <div className="font-semibold">{unreadCount}</div>
            </div>
          </div>
        )}

        <MdChevronRight className="h-7 w-7 self-center text-2xl text-grey-600" />
      </div>
    </div>
  );
}

export default ListItem;
