function MyHomeIcon({className}) {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
            <path
                d="M760-369.23v-280L540-803.08 320-649.23v107.69h-40v-126.92l260-185.39 260 185.39v299.23h-40ZM540-803.08Zm23.85 180.77h32.3v-32.31h-32.3v32.31Zm-80 0h32.3v-32.31h-32.3v32.31Zm80 80h32.3v-32.31h-32.3v32.31Zm-80 0h32.3v-32.31h-32.3v32.31Zm-227.7 328.46 302.62 88.31 239.54-74q-1.16-18.23-10.89-26.27-9.74-8.04-22.04-8.04H575.6q-25.37 0-45.98-2-20.62-2-42.24-9.53l-87.61-28.7 13.54-39.54 81 29.31q19.31 7.31 43.84 8.77 24.54 1.46 67.23 1.69 0-18.69-6.88-30.23t-19.73-16.07l-230.15-84.47q-2.31-.76-4.24-1.15-1.92-.38-4.23-.38h-84v192.3ZM96.15-120v-326.15h243.1q5.6 0 11.52 1.26 5.92 1.27 11 2.97l231.15 84.69q21.46 8.15 36.96 29.31 15.5 21.15 15.5 54.07h120q36.16 0 55.39 22.62Q840-228.62 840-193.85v24.62L560.77-83.08l-304.62-88.77V-120h-160Zm40-40h80v-246.15h-80V-160Z"/>
        </svg>
    )
}

export default MyHomeIcon
