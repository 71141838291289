import { useCallback, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";

import SendEmailIcon from "@src/assets/svgs/SendEmailIcon";
import LockIcon from "@src/assets/svgs/LockIcon";
import { sendUserOTPEmail } from "@src/api/user.js";

import { EMAIL_REGEX } from "@src/utils/constants.js";

function EmailForm({ setIsFirstStep, email, setEmail }) {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  // Extract the redirectTo query parameter
  const queryParams = new URLSearchParams(location.search);
  const redirectTo = queryParams.get("redirectTo") || "/";

  const onChangeValue = useCallback((event) => {
    setEmail(event.target.value);
  }, []);

  const handleSubmitEmail = useCallback(
    async (e) => {
      e.preventDefault();
      if (isLoading) return;

      const isEmailValid = EMAIL_REGEX.test(email);
      if (!isEmailValid) {
        setError(email ? "Please enter a valid email!" : "Email is required!");
        return;
      }
      setError("");
      setIsLoading(true);

      try {
        await sendUserOTPEmail({
          payload: {
            email,
            redirect_to: redirectTo,
          },
        });
        setIsFirstStep(false);
      } catch (error) {
        console.log("error", error);
      }
      setIsLoading(false);
    },
    [email, isLoading, redirectTo, setIsFirstStep],
  );

  return (
    <div className="flex w-full max-w-96 flex-col py-6">
      <div className="flex flex-col">
        <h1 className="font-attention text-2xl text-grey-800">Sign In or</h1>
        <h1 className="font-attention text-2xl text-grey-800">
          Create an Account
        </h1>
      </div>

      <div className="mt-5 flex flex-col text-black">
        <p>
          <span className="font-bold">Please provide your email</span> and
        </p>
        <p>we’ll send you a secure link.</p>
      </div>
      <form className="mx-auto w-fit" onSubmit={handleSubmitEmail}>
        <div className="mt-7 flex h-[44px] items-center justify-center self-center rounded-full border border-gold-500 pl-4 pr-1">
          <input
            type="email"
            value={email}
            onChange={onChangeValue}
            placeholder="Email"
            disabled={isLoading}
            className={`bg-white font-content text-base opacity-80 focus:border-none focus:outline-none disabled:bg-white ${isLoading && "text-neutral-500"}`}
          />

          <button
            className="ml-2 flex h-9 w-9 items-center justify-center rounded-full bg-gold-400 opacity-55"
            onClick={handleSubmitEmail}
            disabled={isLoading}
          >
            {!isLoading ? (
              <SendEmailIcon className="ml-1 mt-[1px] h-6 w-6" />
            ) : (
              <div className="h-5 w-5 animate-spin rounded-full border border-2 border-gold-700 border-b-transparent border-r-transparent border-t-transparent transition duration-100"></div>
            )}
          </button>
        </div>
      </form>
      {error ? (
        <motion.div
          key="popup-container"
          initial={{ opacity: 0, scale: 1, y: -10 }}
          animate={{ opacity: 1, scale: 1, y: 0 }}
        >
          <p className="mt-2 text-sm text-red-500">{error}</p>
        </motion.div>
      ) : null}

      <div className="mt-2 flex items-center justify-center gap-1 opacity-75">
        <LockIcon />
        <p className="text-xs text-gray-800">
          Your email is kept private and secure.
        </p>
      </div>
    </div>
  );
}

export default EmailForm;
