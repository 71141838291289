import React from "react";
import { useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { MdChevronRight } from "react-icons/md";

function MoreListItem({ itemName, navigationUrl }) {
  const navigate = useNavigate();
  return (
    <div
      className="h-[60%] border-b last:border-b-transparent"
      onClick={() => navigate(`${navigationUrl}`)}
    >
      <div className="flex h-[60%] flex-row justify-between py-5">
        <div className="w-[90%] font-content text-base font-semibold tracking-tighter text-neutral-700">
          {itemName}
        </div>
        <button className="cursor-pointer">
          <MdChevronRight className="shrink-0 self-center text-2xl text-neutral-500" />
        </button>
      </div>
    </div>
  );
}

export default MoreListItem;
