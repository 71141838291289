import { useLocation, useNavigate } from "react-router-dom";
import ListItem from "@src/components/chatView/chatListItem/ChatListItem";
import { useEffect, useState } from "react";
import { getProposalSent, getProposalReceived } from "@src/api/chat";
import { USER_ID } from "@src/utils/constants";
import EmailIcon from "@src/assets/svgs/EmailIcon.jsx";

function ChatList() {
  const PAGE_SIZE = 10;
  const location = useLocation();
  const navigate = useNavigate();

  const userID = JSON.parse(localStorage.getItem(USER_ID));

  const [chatList, setChatList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  const searchParams = new URLSearchParams(location.search);
  const activeTab = searchParams.get("tab") || "homes";

  const handleTabChange = (tab) => {
    navigate(`?tab=${tab}`);
    setChatList([]);
    setCurrentPage(1);
    setHasMore(true);
  };

  const getChatProposals = async () => {
    try {
      const response =
        activeTab == "homes"
          ? await getProposalSent(PAGE_SIZE, currentPage)
          : await getProposalReceived(PAGE_SIZE, currentPage);

      if (response.proposals.length) {
        const chats = response.proposals.map((proposal) => ({
          property: proposal.property,
          name:
            activeTab === "homes"
              ? proposal.property.address_line_1
              : "Interested Party",
          lastMsg: proposal.last_message ?? proposal.buyer_message,
          senderName:
            activeTab === "homes"
              ? userID === proposal.last_message_sender_id
                ? "You"
                : "Owner"
              : userID === proposal.last_message_sender_id
                ? "You"
                : "Interested Party",
          lastMsgTime: proposal.last_message_time,
          unreadCount: proposal.unread_messages_count ?? proposal.unread_count,
          isPropertyMode: activeTab === "homes",
          proposal_id: proposal.proposal_id,
          property_name:
            proposal.property?.address_line_1 ?? proposal.address_line_1,
          isOwner: activeTab !== "homes",
        }));

        setChatList((prevState) => [...prevState, ...chats]);
        setTotalCount(response.count);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (chatList.length && chatList.length == totalCount) {
      setHasMore(false);
    }
  }, [chatList, totalCount]);

  useEffect(() => {
    if (hasMore) {
      getChatProposals();
    }
  }, [activeTab, currentPage]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.body.scrollHeight;

      const threshold = 8;

      if (scrollTop + windowHeight >= documentHeight - threshold && hasMore) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [hasMore, chatList?.length]);

  return (
    <div className="px-6 pt-20">
      <div className="flex flex-row items-center justify-between pb-6">
        <h1 className="font-attention text-2xl text-grey-800">Chats</h1>
        <div className="flex flex-row justify-center">
          <button
            onClick={() => handleTabChange("buyers")}
            className={`px-4 py-2 text-base font-semibold transition-all ${
              activeTab === "buyers"
                ? "border-b-2 border-sage-700 text-sage-700"
                : "border-b-2 border-b-transparent text-neutral-500"
            }`}
          >
            Buyers
          </button>
          <button
            onClick={() => handleTabChange("homes")}
            className={`px-4 py-2 text-base font-semibold transition-all ${
              activeTab === "homes"
                ? "border-b-2 border-sage-700 text-sage-700"
                : "border-b-2 border-b-transparent text-neutral-500"
            }`}
          >
            Homes
          </button>
        </div>
      </div>
      <div>
        {chatList.length === 0 && (
          <div className="flex h-[50vh] flex-col items-center justify-center">
            <div className="rounded-full bg-primary-500/20 p-8">
              <EmailIcon className="h-16 w-16 fill-primary-500/60" />
            </div>

            <div className="flex flex-col items-center text-center">
              <h3 className="mt-6 max-w-[40ch] text-balance font-attention text-xl text-neutral-500">
                No chats yet.{" "}
                {activeTab === "homes"
                  ? "Search for homes to connect with owners."
                  : "Update your home profile to attract interest."}
              </h3>
            </div>
          </div>
        )}
        {chatList.map((item, index) => (
          <ListItem
            key={index}
            name={item.name}
            lastMsg={item.lastMsg}
            senderName={item.senderName}
            lastMsgTime={item.lastMsgTime}
            property={item.property}
            unreadCount={item.unreadCount}
            isPropertyMode={item.isPropertyMode}
            proposal_id={item.proposal_id}
            property_name={item.property_name}
            isOwner={item.isOwner}
          />
        ))}
      </div>
    </div>
  );
}

export default ChatList;
