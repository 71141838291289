import "./overlayLoaderStyles.css";

export function OverlayLoader() {
  return (
    <div className="overlay-holder">
      <div className="snippet">
        <div className="stage">
          <div className="dot-flashing">&nbsp;</div>
        </div>
      </div>
    </div>
  );
}

export default OverlayLoader;
