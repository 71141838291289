import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setShowConfetti } from "@src/store/features/property/slice.js";
import Confetti from "react-confetti";
import * as Dialog from "@radix-ui/react-dialog";

function ConfettiBurst() {
  const dispatch = useDispatch();
  const { showConfetti, currentRoutedProperty } = useSelector(
    (state) => state.property,
  );

  useEffect(() => {
    console.log("showConfetti", showConfetti);
    if (showConfetti) {
      setTimeout(() => {
        dispatch(setShowConfetti(false));
      }, 10000); // Hide confetti after 10 seconds
    }
  }, [showConfetti, dispatch]);

  return (
    <>
      {showConfetti && (
        <div className="z-[60]">
          <Confetti
            frameRate={60}
            recycle={false}
            numberOfPieces={600}
            colors={["#24943e", "#e0c16c"]}
            gravity={0.2}
            tweenDuration={200}
            initialVelocityX={10}
            initialVelocityY={50}
            confettiSource={{
              x: window.innerWidth / 4,
              y: window.innerHeight,
              w: window.innerWidth / 2,
              h: 0,
            }}
          />
        </div>
      )}
    </>
  );
}

export default ConfettiBurst;
