import { postRequest } from "@src/utils/axiosClient";

export const createPaymentIntent = async (proposalId) => {
  try {
    const response = await postRequest({
      url: "payment/intent/create",
      payload: {
        proposal_id: proposalId,
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
