export function ConfirmCloseEditProfile({
  onClickOverlay,
  handleStayOnEditModal,
}) {
  return (
    <div className="flex flex-col items-center p-6">
      <h2 className="mb-4 font-attention text-lg">Confirm Close</h2>
      <p className="mb-6 text-grey-700">
        You have unsaved changes. Are you sure you want to close?
      </p>
      <div className="flex space-x-4">
        <button
          onClick={onClickOverlay}
          className="rounded bg-primary-500 px-4 py-2 text-white transition duration-300 hover:bg-grey-800"
        >
          Yes, Close
        </button>
        <button
          onClick={handleStayOnEditModal}
          className="rounded border border-grey-300 px-4 py-2 text-grey-700 transition duration-300 hover:bg-grey-100"
        >
          No, Stay
        </button>
      </div>
    </div>
  );
}
