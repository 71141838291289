import { Helmet } from "react-helmet-async";
import { useEffect, useRef } from "react";
import { useState } from "react";
import aboutHouse from "../assets/about_house.jpg";
import beautifulDoor from "../assets/beautiful_door.jpg";
import moreControlIcon from "../assets/more_control_icon.png";
import moreOptionsIcon from "../assets/more_options_icon.png";
import { postEmail } from "@src/api/user.js";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { NavLink, useSearchParams } from "react-router-dom";
import { MdArrowLeft, MdChevronLeft } from "react-icons/md";
import BackButtonLink from "@src/components/backButtonLink/BackButtonLink.jsx";

function About() {
  const [showConnectWithAgent, setShowConnectWithAgent] = useState(false);
  const userEmail = useSelector((state) => state.user.user.email);
  const emailFieldRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [backButtonLink, setBackButtonLink] = useState(null);

  const submitEmail = async () => {
    const response = await postEmail({ email: emailFieldRef.current.value });
    if (response.status === 200) {
      toast.success("We got your email and will be in touch shortly!");
    } else {
      toast.error("We had some trouble getting your email - try again?");
    }
    setShowConnectWithAgent(false);
  };

  useEffect(() => {
    if (showConnectWithAgent && userEmail) {
      emailFieldRef.current.value = userEmail;
    }
  }, [showConnectWithAgent, userEmail]);

  useEffect(() => {
    //see if the params have a backTo parameter
    const backTo = searchParams.get("backTo");

    if (backTo) {
      setBackButtonLink(backTo);
    }
  }, [searchParams]);

  return (
    <>
      <Helmet>
        <title>Unlisted</title>
        <meta name="description" content="About Page" />
      </Helmet>

      <div>
        <BackButtonLink className="pt-24" />
        <section className="relative mx-auto flex w-full max-w-4xl justify-center pt-12">
          <div className="flex w-full max-w-96 flex-col gap-4 py-6 text-center">
            <h1 className="font-attention text-3xl leading-[135%] text-neutral-700">
              Expand your search to include off-market homes
            </h1>
            <p className="font-content text-neutral-600">
              Unlisted connects you with off-market homeowners open to private
              sales under right conditions.
            </p>
          </div>
        </section>
        <section>
          <img
            className="mx-auto mt-4 aspect-video w-full max-w-4xl object-cover md:mb-12 md:rounded-xl"
            src={aboutHouse}
            alt="Beatiful house with nice landscaping."
          />
          <div className="flex flex-col items-center bg-[#f5f5f5] px-9 py-6">
            <div className="max-w-4xl md:my-12">
              <svg
                width="69"
                height="17"
                viewBox="0 0 69 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mx-auto"
              >
                <g clipPath="url(#clip0_2598_11296)">
                  <path
                    d="M0.404785 8.5H21.6732"
                    stroke="#9D9D9D"
                    strokeWidth="0.805918"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M21.6521 3.99593L17.1509 8.49719L21.6521 12.9985L26.1534 8.49719L21.6521 3.99593Z"
                    stroke="#9D9D9D"
                    strokeWidth="0.805918"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M28.4864 1.68307L21.6694 8.5L28.4864 15.3169L35.3033 8.5L28.4864 1.68307Z"
                    stroke="#9D9D9D"
                    strokeWidth="0.805918"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M34.2647 0.38544L26.1729 8.47729L34.2647 16.5692L42.3566 8.47729L34.2647 0.38544Z"
                    stroke="#9D9D9D"
                    strokeWidth="0.805918"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <g clipPath="url(#clip1_2598_11296)">
                  <path
                    d="M68.353 8.5L47.0846 8.5"
                    stroke="#9D9D9D"
                    strokeWidth="0.805918"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M47.1057 13.0041L51.6069 8.50281L47.1057 4.00155L42.6044 8.50281L47.1057 13.0041Z"
                    stroke="#9D9D9D"
                    strokeWidth="0.805918"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M40.2714 15.3169L47.0884 8.5L40.2714 1.68307L33.4545 8.5L40.2714 15.3169Z"
                    stroke="#9D9D9D"
                    strokeWidth="0.805918"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M34.4931 16.6146L42.585 8.52271L34.4931 0.430849L26.4013 8.5227L34.4931 16.6146Z"
                    stroke="#9D9D9D"
                    strokeWidth="0.805918"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2598_11296">
                    <rect width="42.7576" height="17" fill="white" />
                  </clipPath>
                  <clipPath id="clip1_2598_11296">
                    <rect
                      width="42.7576"
                      height="17"
                      fill="white"
                      transform="translate(68.7578 17) rotate(-180)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <h2 className="mt-4 text-center font-attention text-2xl text-black">
                Only 5% of homes are listed for sale. Unlisted gives you access
                to the other 95%.
              </h2>
              <p className="mt-5 block text-center font-content text-neutral-600">
                You could buy a listed home - but research shows most people are
                unsatisfied with the listed options in their area. And it&apos;s
                no wonder; properties listed for sale represent only 5% of all
                homes.
              </p>
              <p className="my-5 block text-center font-content text-neutral-600">
                What if your dream home isn&apos;t for sale? What if there are
                no listed options that meet your criteria? Unlisted expands your
                search to all homes, both listed and unlisted, so you can find
                the best home for you.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="flex flex-col items-center px-9 py-6 md:py-12">
            <h2 className="mt-4 text-center font-attention text-2xl text-black">
              Three reasons buyers explore off-market homes
            </h2>
            <div className="mt-16 flex w-[min(calc(100%-4rem),60rem)] flex-col items-center gap-12 md:my-12 md:flex-row md:gap-32">
              <div className="flex flex-col items-center">
                <img
                  className="max-w-20"
                  src={moreControlIcon}
                  alt="More control icon"
                />
                <h3 className="mt-2 font-attention text-xl">More control</h3>
                <p className="mt-1 block max-w-72 text-center font-content text-sm text-neutral-700">
                  Relying on the MLS means waiting for homeowners to list. With
                  Unlisted, you have the power to start the conversation
                  yourself.
                </p>
              </div>
              <div className="flex flex-col items-center">
                <img
                  className="max-w-20"
                  src={moreOptionsIcon}
                  alt="More control icon"
                />
                <h3 className="mt-2 font-attention text-xl">More options</h3>
                <p className="mt-1 block max-w-72 text-center font-content text-sm text-neutral-700">
                  Why limit your search to just those listed for sale? Search
                  all 121,000,000 homes in the U.S., not just the 5% that are
                  listed at any given time.
                </p>
              </div>
              <div className="flex flex-col items-center">
                <img
                  className="max-w-20"
                  src={moreControlIcon}
                  alt="More control icon"
                />
                <h3 className="mt-2 font-attention text-xl">More insights</h3>
                <p className="mt-1 block max-w-72 text-center font-content text-sm text-neutral-700">
                  What if you buy a home today, and your dream home lists
                  tomorrow? Unlisted helps you learn homeowner plans so you can
                  make an informed decision.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="flex flex-col items-center bg-[#333333] px-9 py-4 py-6 pb-12">
          <svg
            width="69"
            height="17"
            viewBox="0 0 69 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_2598_11296)">
              <path
                d="M0.404785 8.5H21.6732"
                stroke="#9D9D9D"
                strokeWidth="0.805918"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.6521 3.99593L17.1509 8.49719L21.6521 12.9985L26.1534 8.49719L21.6521 3.99593Z"
                stroke="#9D9D9D"
                strokeWidth="0.805918"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M28.4864 1.68307L21.6694 8.5L28.4864 15.3169L35.3033 8.5L28.4864 1.68307Z"
                stroke="#9D9D9D"
                strokeWidth="0.805918"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M34.2647 0.38544L26.1729 8.47729L34.2647 16.5692L42.3566 8.47729L34.2647 0.38544Z"
                stroke="#9D9D9D"
                strokeWidth="0.805918"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <g clipPath="url(#clip1_2598_11296)">
              <path
                d="M68.353 8.5L47.0846 8.5"
                stroke="#9D9D9D"
                strokeWidth="0.805918"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M47.1057 13.0041L51.6069 8.50281L47.1057 4.00155L42.6044 8.50281L47.1057 13.0041Z"
                stroke="#9D9D9D"
                strokeWidth="0.805918"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M40.2714 15.3169L47.0884 8.5L40.2714 1.68307L33.4545 8.5L40.2714 15.3169Z"
                stroke="#9D9D9D"
                strokeWidth="0.805918"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M34.4931 16.6146L42.585 8.52271L34.4931 0.430849L26.4013 8.5227L34.4931 16.6146Z"
                stroke="#9D9D9D"
                strokeWidth="0.805918"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_2598_11296">
                <rect width="42.7576" height="17" fill="white" />
              </clipPath>
              <clipPath id="clip1_2598_11296">
                <rect
                  width="42.7576"
                  height="17"
                  fill="white"
                  transform="translate(68.7578 17) rotate(-180)"
                />
              </clipPath>
            </defs>
          </svg>
          <h2 className="mt-4 max-w-xl text-balance text-center font-attention text-2xl text-white">
            Work with an agent to start your off-market home search
          </h2>
          <p className="mt-5 block max-w-lg text-balance text-center font-content text-neutral-200">
            Get matched with an agent in your area who specializes in off-market
            properties and private home sales.
          </p>
          <div className="mt-4">
            {showConnectWithAgent ? (
              <div className="flex flex-col items-center">
                <input
                  ref={emailFieldRef}
                  className="mt-4 w-full min-w-[230px] rounded-md border border-gray-300 bg-white p-3 text-base focus:outline-none"
                  type="text"
                  name="email"
                  placeholder="Enter your email"
                />

                <button
                  className="mt-8 w-[70%] rounded-md bg-[#BDCCC1] px-7 py-3.5 font-attention text-xl"
                  onClick={submitEmail}
                >
                  Submit
                </button>
              </div>
            ) : (
              <button
                className="mt-8 rounded-md bg-[#BDCCC1] px-7 py-3.5 font-attention text-xl"
                onClick={() => {
                  setShowConnectWithAgent(true);
                }}
              >
                Connect with an agent
              </button>
            )}
          </div>
        </section>
      </div>
    </>
  );
}

export default About;
