import { useEffect } from "react";
import { useApiIsLoaded, Map, useMap } from "@vis.gl/react-google-maps";
import { abbreviateNumber } from "@src/utils/misc.js";
import React from "react";

function PropertyProfileMap({ property }) {
  const isLoaded = useApiIsLoaded();
  const map = useMap("property-profile-map"); // Hook to get the specific map instance for this component

  useEffect(() => {
    if (!isLoaded || !map || !property?.latitude || !property?.longitude) {
      return;
    }

    const loadMarker = async () => {
      try {
        const { AdvancedMarkerElement } =
          await window.google.maps.importLibrary("marker");

        const priceTag = document.createElement("div");
        const estimatedValue =
          property.owner_expected_value ||
          property.estimated_value ||
          property.estimated_value_one ||
          0;
        priceTag.className = "price-tag";
        priceTag.textContent = abbreviateNumber(estimatedValue);

        new AdvancedMarkerElement({
          map: map, // Explicitly bind marker to the correct map instance
          position: {
            lat: parseFloat(property.latitude),
            lng: parseFloat(property.longitude),
          },
          content: priceTag,
        });
      } catch (error) {
        console.error("Error loading marker:", error);
      }
    };

    loadMarker();
  }, [isLoaded, map, property]);

  if (!isLoaded) {
    return <div>Loading Google Maps library...</div>;
  }

  return (
    <>
      {property && property.latitude && property.longitude ? (
        <>
          <h2 className="mb-4 font-attention text-xl text-neutral-800">
            Location
          </h2>
          <div className="h-64 overflow-hidden rounded-xl">
            <Map
              id={"property-profile-map"}
              mapId={"bf51a910020fa25a"}
              defaultZoom={14}
              defaultCenter={{
                lat: parseFloat(property.latitude),
                lng: parseFloat(property.longitude),
              }}
              gestureHandling={"cooperative"}
              options={{ disableDefaultUI: true }}
              renderingType="VECTOR"
            />
          </div>
        </>
      ) : null}
    </>
  );
}

export default PropertyProfileMap;
