import { getRequest, postRequest } from "@src/utils/axiosClient";

export const getMessagesData = async (proposal_id) => {
  try {
    const response = await getRequest({
      url: `/messages/${proposal_id}/messages/`,
    });

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const sendMessage = async ({ proposal_id, payload = {} }) => {
  try {
    const response = await postRequest({
      url: `/messages/${proposal_id}/messages/`,
      payload: payload,
    });

    return { data: response.data, status: response.status };
  } catch (error) {
    throw { data: error.response.data, status: error.status };
  }
};

export const getProposalSent = async (pageSize, page = 1) => {
  try {
    const response = await getRequest({
      url: `/api/v1/proposals/sent/?page=${page}&page_size=${pageSize}`,
    });

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getProposalReceived = async (pageSize, page = 1) => {
  try {
    const response = await getRequest({
      url: `/api/v1/proposals/received/?page=${page}&page_size=${pageSize}`,
    });

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getProposalData = async (proposal_id) => {
  try {
    const response = await getRequest({
      url: `/proposal/${proposal_id}/`,
    });

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
