import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import BaseModal from "@src/components/baseModal/BaseModal";
import EditProfileForm from "./components/editProfileForm/EditProfileForm";
import { ConfirmCloseEditProfile } from "./components/confirmCloseEditProfile/ConfirmCloseEditProfile";

export default function EditProfileModal({ open, onClickOverlay }) {
  const user = useSelector((state) => state.user.user);
  const isUpdatingUserDetails = useSelector(
    (state) => state.user.isUpdatingUserDetails,
  );

  const [firstName, setFirstName] = useState(user?.first_name ?? "");
  const [lastName, setLastName] = useState(user?.last_name ?? "");
  const [profileImage, setProfileImage] = useState(user?.profile_picture_url);
  const [showConfirmClose, setShowConfirmClose] = useState(false);

  const handleCloseModal = useCallback(() => {
    if (
      firstName !== user?.first_name ||
      lastName !== user?.last_name ||
      profileImage !== user?.profile_picture_url
    ) {
      setShowConfirmClose(true);
      return;
    }

    onClickOverlay();
  }, [
    isUpdatingUserDetails,
    firstName,
    lastName,
    profileImage,
    user?.first_name,
    user?.last_name,
    user?.profile_picture_url,
  ]);

  const handleStayOnEditModal = useCallback(
    () => setShowConfirmClose(false),
    [],
  );

  return (
    <BaseModal
      open={open}
      onClickOverlay={handleCloseModal}
      contentWrapperStyles="bg-white"
    >
      {showConfirmClose ? (
        <ConfirmCloseEditProfile
          onClickOverlay={onClickOverlay}
          handleStayOnEditModal={handleStayOnEditModal}
        />
      ) : (
        <EditProfileForm
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          profileImage={profileImage}
          setProfileImage={setProfileImage}
          onClickOverlay={onClickOverlay}
        />
      )}
    </BaseModal>
  );
}
