import React from "react";

function BedroomsIcon({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 -960 960 960"
      width="24px"
      fill="currentColor"
    >
      <path d="M120-240v-200q0-17.77 10.23-37.85Q140.46-497.92 160-508v-92q0-33.85 23.08-56.92Q206.15-680 240-680h180q20.69 0 35.31 8.5Q469.92-663 480-648q10.08-15 24.69-23.5Q519.31-680 540-680h180q33.85 0 56.92 23.08Q800-633.85 800-600v92q19.54 10.08 29.77 30.15Q840-457.77 840-440v200h-40v-80H160v80h-40Zm380-280h260v-80q0-17-11.5-28.5T720-640H540q-17 0-28.5 11.5T500-600v80Zm-300 0h260v-80q0-17-11.5-28.5T420-640H240q-17 0-28.5 11.5T200-600v80Zm-40 160h640v-80q0-17-11.5-28.5T760-480H200q-17 0-28.5 11.5T160-440v80Zm640 0H160h640Z" />
    </svg>
  );
}

export default BedroomsIcon;
