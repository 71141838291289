function SendEmailIcon({ className }) {
  return (
    <svg
      className={className}
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 17.5V12.3463L9.84625 11L4 9.65375V4.5L19.423 11L4 17.5Z"
        fill="#333333"
      />
    </svg>
  );
}

export default SendEmailIcon;
