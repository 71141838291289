import { PLACE_ICONS } from "@src/utils/constants";
import { MdPlace } from "react-icons/md";
import { useNavigate } from "react-router-dom";

export default function SearchInputItem({
  section,
  isLastSection,
  setIsFocused,
}) {
  const Icon = PLACE_ICONS[section?.name] || MdPlace;
  const navigate = useNavigate();

  const handlePlaceClick = (place) => () => {
    // navigate(place?.url);
    setIsFocused(false);
  };

  return (
    <div
      key={section.name}
      className={`flex w-full flex-col items-start ${!isLastSection && "border-b border-neutral-200"} pb-2 pt-4 font-content`}
    >
      <span className="pl-4 text-sm font-medium uppercase text-grey-500">
        {section.name}
      </span>
      {section?.rows?.map((place) => (
        <button
          key={place?.id}
          className="flex w-full cursor-pointer flex-col items-start px-4 py-2 text-start transition-all hover:bg-gray-100 hover:text-black active:bg-gray-200 active:text-black"
          onClick={handlePlaceClick(place)}
        >
          <div className="flex w-full items-center gap-2">
            <Icon className="h-4 w-4 fill-sage-600" />
            <span className="w-full truncate text-grey-900">{place?.name}</span>
          </div>
          <span className="w-full truncate pl-6 text-xs text-grey-400">
            {place?.subName}
          </span>
        </button>
      ))}
    </div>
  );
}
