export const Loader = ({ hasData, isLoadingLikedHomes }) => {
  if (!isLoadingLikedHomes) return null;

  if (hasData)
    return (
      <div className="mt-4 flex items-center justify-center">
        <div className="h-6 w-6 animate-spin rounded-full border-2 border-grey-500 border-t-transparent"></div>
      </div>
    );

  return [1, 2, 3, 4, 5].map((item) => (
    <div
      key={item}
      className="mb-4 h-[239px] w-full animate-pulse rounded-lg bg-grey-200 shadow-md"
    />
  ));
};
