import React from "react";
import { MdFlag } from "react-icons/md";

function PropertyProfileClaimedBadge({ property }) {
  return (
    <>
      {property.owner_id
        ? null
        : // <div className="inline-block flex w-fit items-center gap-1 rounded-full bg-[#5CA95B]/90 p-1 pl-2.5 pr-3 font-content text-xs text-white/90">
          //   <MdFlag className="-scale-x-100" />
          //   <span>Claimed</span>
          // </div>
          null}
    </>
  );
}

export default PropertyProfileClaimedBadge;
