function CottageIcon({className}) {
	return (
		<svg className={className} xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
			<g clipPath="url(#clip0_362_92)">
				<path d="M10.4167 34.6667V15.6162L6.38645 18.7214L5.12811 17.0704L10.4167 13.0001V7.50319H12.5V11.3855L25 1.85423L44.8719 17.0704L43.6135 18.7094L39.5833 15.6162V34.6667H10.4167ZM12.5 32.5834H22.1156V22.6475H27.8844V32.5834H37.5V14.0417L25 4.51048L12.5 14.0417V32.5834ZM10.4167 5.01934C10.4167 3.76413 10.8174 2.68913 11.6187 1.79433C12.4198 0.899543 13.5682 0.452149 15.0641 0.452149C15.9748 0.452149 16.6299 0.212392 17.0292 -0.267122C17.4285 -0.746635 17.6281 -1.335 17.6281 -2.03223H19.7114C19.7114 -0.776671 19.3108 0.298502 18.5094 1.19329C17.7083 2.08809 16.5599 2.53548 15.0641 2.53548C14.1533 2.53548 13.4983 2.77507 13.0989 3.25423C12.6996 3.73375 12.5 4.32211 12.5 5.01934H10.4167Z" fill="#808080"/>
			</g>
			<defs>
				<clipPath id="clip0_362_92">
					<rect width="50" height="50" fill="white"/>
				</clipPath>
			</defs>
		</svg>
	)
}

export default CottageIcon
