import * as Dialog from "@radix-ui/react-dialog";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import "./styles.css";

export default function BaseModal({
  open,
  onOpenChange,
  contentWrapperStyles = "",
  overlayStyles = "",
  onClickOverlay = () => {},
  children,
}) {
  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <VisuallyHidden.Root>
          <Dialog.Title />
        </VisuallyHidden.Root>
        <Dialog.Overlay
          onClick={(e) => {
            e.stopPropagation();
            onClickOverlay();
          }}
          className={`DialogOverlay z-50 ${overlayStyles}`}
        />
        <Dialog.Content
          onClick={(e) => {
            e.stopPropagation();
          }}
          className={`DialogContent z-50 max-h-[80vh] max-w-sm rounded-lg ${contentWrapperStyles}`}
          aria-describedby={undefined}
          onOpenAutoFocus={(event) => event.preventDefault()}
        >
          {children}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
