import React from "react";
import BackButtonLink from "@src/components/backButtonLink/BackButtonLink.jsx";

function ReportAbuse() {
  return (
    <>
      <BackButtonLink className="pt-24" />
      <div className="pb- mx-auto w-full px-6 pt-20 md:max-w-4xl">
        <h1 className="py-2 font-attention text-3xl text-gray-900">
          Report Abuse
        </h1>
        <p className="py-2 font-content text-sm font-normal text-gray-900">
          Unlisted is a neighborly way for people to connect about their
          property plans; we do not tolerate abuse, harassment, or inappropriate
          behavior of any kind. Please help Unlisted uphold our neighborly
          values and make us aware of any inappropriate conduct at{" "}
          <a
            href="mailto:hello@unlistedhomes.com"
            className="text-sage-600 underline"
          >
            hello@unlistedhomes.com
          </a>
          .
        </p>
      </div>
    </>
  );
}

export default ReportAbuse;
