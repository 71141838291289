import { getRequest, postRequest } from "@src/utils/axiosClient";

export const apiSearchProperties = async (searchData) => {
  let response;
  if (searchData.address) {
    response = await postRequest({
      url: `${import.meta.env.VITE_API_URL}/api/v1/properties/search`,
      payload: searchData,
    });
    return { properties: [response.data] };
  } else if (searchData.question) {
    response = await getRequest({
      url: `${import.meta.env.VITE_API_URL}/api/v1/properties/searchv2`,
      params: {
        question: searchData.question,
      },
    });
    return response.data;
  } else if (searchData.exact_lat && searchData.exact_long) {
    response = await postRequest({
      url: `${import.meta.env.VITE_API_URL}/api/v1/properties/searchv1`,
      payload: {
        page: 0,
        exact_lat: searchData.exact_lat,
        exact_long: searchData.exact_long,
        radius: searchData.exact_lat && searchData.exact_long ? "5" : undefined,
      },
    });
    return response.data;
  } else {
    response = await postRequest({
      url: `${import.meta.env.VITE_API_URL}/api/v1/properties/searchv1`,
      payload: searchData,
    });
    return response.data;
  }
};

export const apiFetchRandomProperties = async () => {
  let response = await getRequest({
    url: `${import.meta.env.VITE_API_URL}/api/v1/properties/random`,
    params: {
      page: 0,
    },
  });

  return response.data;
};
