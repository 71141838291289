import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const DiscoverTab = ({ to, icon: Icon, children }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <NavLink
      to={to}
      className={`flex flex-1 flex-row items-center justify-center gap-2 p-4 transition-colors ${
        isActive ? "pointer-events-none text-[#847652]" : "text-[#777777]"
      }`}
    >
      <Icon
        className={`transition-colors ${
          isActive ? "fill-[#B2A585]" : "fill-[#b8b8b8]"
        }`}
      />
      {children}
    </NavLink>
  );
};

export default DiscoverTab;
