import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setArchitecturalStyles,
  toggleArchitecturalStyle,
} from "../../store/features/search/slice.js";
import SelectArchitecturalStyles from "@src/components/selectArchitecturalStyles/SelectArchitecturalStyles.jsx";

function SearchArchitecturalStyles({ prefix, values, setValues }) {
  const dispatch = useDispatch();

  return (
    <div className="px-4 py-6">
      <SelectArchitecturalStyles
        allowMultiple={true}
        prefix={prefix}
        value={values}
        onChange={(styles) => {
          setValues(styles);
        }}
      />
    </div>
  );
}

export default SearchArchitecturalStyles;
