function LockIcon({ className }) {
  return (
    <svg
      className={className}
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.74986 10.0835C2.49777 10.0835 2.28205 9.99382 2.10269 9.81446C1.92333 9.63509 1.8335 9.41922 1.83319 9.16683V4.5835C1.83319 4.33141 1.92302 4.11569 2.10269 3.93633C2.28236 3.75697 2.49808 3.66714 2.74986 3.66683H3.20819V2.75016C3.20819 2.11614 3.4317 1.57576 3.87873 1.12904C4.32576 0.682316 4.86613 0.458802 5.49986 0.458496C6.13358 0.458191 6.67411 0.681705 7.12144 1.12904C7.56877 1.57637 7.79213 2.11675 7.79152 2.75016V3.66683H8.24986C8.50194 3.66683 8.71781 3.75666 8.89748 3.93633C9.07715 4.116 9.16683 4.33172 9.16652 4.5835V9.16683C9.16652 9.41891 9.07684 9.63479 8.89748 9.81446C8.71812 9.99412 8.50224 10.0838 8.24986 10.0835H2.74986ZM5.49986 7.79183C5.75194 7.79183 5.96781 7.70215 6.14748 7.52279C6.32715 7.34343 6.41683 7.12755 6.41652 6.87516C6.41622 6.62277 6.32654 6.40705 6.14748 6.228C5.96843 6.04894 5.75255 5.95911 5.49986 5.9585C5.24716 5.95789 5.03144 6.04772 4.85269 6.228C4.67394 6.40828 4.58411 6.624 4.58319 6.87516C4.58227 7.12633 4.67211 7.34221 4.85269 7.52279C5.03327 7.70337 5.249 7.79305 5.49986 7.79183ZM4.12486 3.66683H6.87486V2.75016C6.87486 2.36822 6.74118 2.04357 6.47381 1.7762C6.20645 1.50884 5.8818 1.37516 5.49986 1.37516C5.11791 1.37516 4.79326 1.50884 4.5259 1.7762C4.25854 2.04357 4.12486 2.36822 4.12486 2.75016V3.66683Z"
        fill="#808080"
      />
    </svg>
  );
}

export default LockIcon;
