import React from "react";

function SquareFeetIcon({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 -960 960 960"
      width="24px"
      fill="currentColor"
    >
      <path d="M257.23-181.54q-31.61 0-53.65-22.04t-22.04-53.65v-401.85q0-22.07 18.92-30.5 18.92-8.42 33.54 6.19l59.23 59.24-30.92 30.92 18.77 18.77L312-605.39l85.54 85.54-30.92 30.93 18.76 18.77 30.93-30.93 88.61 88.62L474-381.54l18.77 18.77 30.92-30.92 85.54 85.54-30.92 30.92 18.77 18.77L628-289.38l56.92 56.92q13.85 13.84 5.43 32.38-8.43 18.54-29.73 18.54H257.23Zm7.39-58.46H572L240-572v307.38q0 10.77 6.92 17.7 6.93 6.92 17.7 6.92Z" />
    </svg>
  );
}

export default SquareFeetIcon;
