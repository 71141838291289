let isUpdateScenario = false; // Global variable to track if it's an update scenario

export const init = () => {
  console.log("Initializing Service Worker...");

  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("/sw.js", { scope: "/" })
      .then((registration) => {
        console.log("Service Worker registered successfully:", registration);

        // --- 1. Periodic update checking ---
        const enablePeriodicUpdates = true;
        if (enablePeriodicUpdates) {
          setInterval(
            async () => {
              try {
                console.log(
                  "Service Worker - setInterval registration.update()",
                );
                await registration.update();
              } catch (error) {
                console.error("Service Worker update failed:", error);
              }
            },
            60 * 60 * 1000,
          ); // every hour
        }

        // --- 2. Focus-based update checking ---
        const enableFocusUpdates = true;
        let lastFocusUpdateTime = 0;
        const focusUpdateThrottle = 60000;
        if (enableFocusUpdates) {
          window.addEventListener("focus", async () => {
            const now = Date.now();
            if (now - lastFocusUpdateTime > focusUpdateThrottle) {
              lastFocusUpdateTime = now;
              console.log(
                "Service Worker - window focus registration.update()",
              );
              try {
                await registration.update();
              } catch (error) {
                console.error("Service Worker update failed:", error);
              }
            }
          });
        }

        // --- 3. Lifecycle: Detecting when a new SW is found and installed ---
        const enableUpdateNotification = true;
        let updateModalTimeout;

        if (enableUpdateNotification) {
          registration.addEventListener("updatefound", () => {
            const newWorker = registration.installing;
            // Check if this is an update (existing SW present)
            const isUpdate = !!navigator.serviceWorker.controller;

            if (isUpdate) {
              isUpdateScenario = true; // Mark that we are indeed dealing with an update
              console.log(
                "A new Service Worker update has been found: ",
                newWorker,
              );
              showUpdateModal();

              // Start fallback
              updateModalTimeout = setTimeout(() => {
                console.log(
                  "Fallback: No SW activation in 10 seconds. Hiding modal.",
                );
                hideUpdateModal();
              }, 10000);
            } else {
              console.log(
                "First time SW installation. No modal and no reload needed.",
              );
            }

            newWorker.addEventListener("statechange", () => {
              if (newWorker.state === "installed") {
                // Clear the fallback if it was set
                if (updateModalTimeout) clearTimeout(updateModalTimeout);

                if (isUpdate) {
                  console.log(
                    "New Service Worker installed and waiting (update scenario).",
                  );
                  handleNewServiceWorker(registration);
                } else {
                  // Initial install: do nothing else
                }
              }
            });
          });
        }

        // --- 4. Automatically refresh the page when a new SW activates ---
        const enableAutoRefresh = true;
        if (enableAutoRefresh) {
          navigator.serviceWorker.addEventListener("controllerchange", () => {
            console.log("New Service Worker is now controlling the page.");
            if (updateModalTimeout) clearTimeout(updateModalTimeout);

            // Only reload if this was an update scenario
            if (isUpdateScenario) {
              limitAutoReload();
            } else {
              // Not an update scenario, so no reload.
              // Just ensure modal is hidden in case it was shown (though it shouldn't have been).
              hideUpdateModal();
            }
          });
        }
      })
      .catch((error) => {
        console.error("Service Worker registration failed:", error);
      });
  }
};

// --- Limit the auto-refresh to once per minute ---
function limitAutoReload() {
  const lastServiceWorkerTriggeredReloadTime = localStorage.getItem(
    "lastServiceWorkerTriggeredReloadTime",
  );
  const now = Date.now();
  const oneMinute = 60 * 1000;

  if (
    !lastServiceWorkerTriggeredReloadTime ||
    now - lastServiceWorkerTriggeredReloadTime > oneMinute
  ) {
    console.log("Page reloading due to new Service Worker activation...");
    localStorage.setItem("lastServiceWorkerTriggeredReloadTime", now);
    window.location.reload();
  } else {
    console.log("Skipping reload; too soon since last reload.");
    hideUpdateModal();
    console.log(
      "lastServiceWorkerTriggeredReloadTime",
      lastServiceWorkerTriggeredReloadTime,
    );
    console.log("now", now);
  }
}

// --- 5. Handle new Service Worker: notify user or refresh ---
function handleNewServiceWorker(registration) {
  if (registration.waiting) {
    registration.waiting.postMessage({ type: "SKIP_WAITING" });
  }
}

function showUpdateModal() {
  const modalDiv = document.createElement("div");
  modalDiv.id = "update-modal";
  modalDiv.style.position = "fixed";
  modalDiv.style.top = "0";
  modalDiv.style.left = "0";
  modalDiv.style.width = "100vw";
  modalDiv.style.height = "100vh";
  modalDiv.style.backgroundColor = "rgba(0, 0, 0, 0.8)";
  modalDiv.style.color = "white";
  modalDiv.style.display = "flex";
  modalDiv.style.flexDirection = "column";
  modalDiv.style.justifyContent = "center";
  modalDiv.style.alignItems = "center";
  modalDiv.style.zIndex = "9999";

  const modalContent = `
    <div style="text-align: center;">
      <h1 style="margin-bottom: 20px;">Updating the App<br>Please stand by...</h1>
    </div>
  `;
  modalDiv.innerHTML = modalContent;

  document.body.appendChild(modalDiv);
}

function hideUpdateModal() {
  const modalDiv = document.getElementById("update-modal");
  if (modalDiv) {
    modalDiv.remove();
    console.log("Update modal hidden.");
  }
}

export default { init };
