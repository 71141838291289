import debounce from "lodash.debounce";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { TfiLocationArrow } from "react-icons/tfi";
import SearchInputItem from "./components/SearchInputItem";
import { toast } from "react-toastify";
import { Geolocation } from "@capacitor/geolocation";

export const PropertySearchInput = forwardRef(
  (
    {
      placeholder,
      handleSearchQueryChange,
      handleCurrentLocation,
      places,
      isLoading,
    },
    ref,
  ) => {
    const inputRef = useRef(null);
    const containerRef = useRef(null);

    const [searchString, setSearchString] = useState("");
    const [isFocused, setIsFocused] = useState(true);
    const [isInputDisabled, setIsInputDisabled] = useState(false);

    const handleSearchQueryChangeDebounced = debounce((newValue) => {
      if (handleSearchQueryChange) {
        handleSearchQueryChange(newValue);
      }
    }, 300);

    const onSearchQueryChange = useCallback((e) => {
      setSearchString(e.target.value);
      handleSearchQueryChangeDebounced(e.target.value);
    }, []);

    const placesData = useMemo(() => {
      if (!searchString) return [places[0]];

      return places;
    }, [searchString, places]);

    const onClickCurrentLocation = useCallback(async () => {
      try {
        const coordinates = await Geolocation?.getCurrentPosition?.();
        if (handleCurrentLocation) {
          const response = await handleCurrentLocation({
            latitude: coordinates?.coords?.latitude,
            longitude: coordinates?.coords?.longitude,
          });
        }
        // Remove later
        toast.success(
          `lat: ${coordinates?.coords?.latitude} long: ${coordinates?.coords?.longitude}`,
        );
      } catch (error) {
        const errorString = error?.toString();
        let errorLabel =
          "To find results around you, please allow location access.";

        if (errorString?.includes("disabled")) {
          errorLabel = "Please turn on your device's location";
        }

        toast.error(errorLabel);
      }
    }, []);

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }

      const handleClickOutside = (event) => {
        if (
          containerRef?.current &&
          !containerRef?.current?.contains(event.target)
        ) {
          setIsFocused(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    useImperativeHandle(ref, () => ({
      focusInput() {
        inputRef.current?.focus();
        setIsFocused(true);
      },
      blurInput() {
        inputRef.current?.blur();
        setIsFocused(false);
      },
      disableInput() {
        setIsInputDisabled(true);
      },
      enableInput() {
        setIsInputDisabled(true);
      },
    }));

    return (
      <>
        <div
          ref={containerRef}
          className="w-full rounded-md ring-0 ring-transparent transition-shadow focus-within:ring-2 focus-within:ring-[#8E9991]/40 focus-within:ring-offset-2"
        >
          <div className="relative w-full rounded-md">
            {isLoading && (
              <div className="absolute right-0 top-1/2 -translate-x-1/2 -translate-y-1/2">
                <div className="h-6 w-6 animate-spin rounded-full border border-2 border-black/10 border-t-sage-700 transition duration-100" />
              </div>
            )}
            <input
              ref={inputRef}
              value={searchString}
              onChange={onSearchQueryChange}
              onFocus={() => setIsFocused(true)}
              className="inline-block w-full rounded-md border border-[#8E9991] p-3 pr-12 font-content outline-none outline-0 transition-all focus:border-[#8E9991]/50 disabled:opacity-40"
              placeholder={placeholder ?? "Address, city, state, or zip"}
              disabled={isInputDisabled}
            />
            {searchString && (
              <button
                data-close
                onClick={() => {
                  if (inputRef.current) {
                    setSearchString("");
                    setTimeout(() => {
                      inputRef?.current?.focus();
                    }, 150);
                  }
                }}
                className={`${isLoading && "pointer-events-none opacity-30"} absolute right-0 top-0 flex h-12 w-12 items-center justify-center`}
              >
                <IoMdCloseCircle className="h-6 w-6 fill-neutral-300" />
              </button>
            )}
            {isFocused ? (
              <div className="hide-scrollbar absolute top-full z-10 max-h-[30rem] w-full overflow-y-auto rounded-md border-2 bg-white">
                {!searchString ? (
                  <div
                    className="flex w-full cursor-pointer items-center gap-2 p-4 transition-all hover:bg-gray-100 hover:text-black active:bg-gray-200 active:text-black"
                    onClick={onClickCurrentLocation}
                  >
                    <TfiLocationArrow className="h-4 w-4 scale-x-[-1] transform fill-black" />
                    <span className="text-md text-grey-600">
                      Current Location
                    </span>
                  </div>
                ) : null}
                {placesData?.length
                  ? placesData?.map((section, index) => (
                      <SearchInputItem
                        key={section.name}
                        section={section}
                        isLastSection={placesData?.length - 1 === index}
                        setIsFocused={setIsFocused}
                      />
                    ))
                  : null}
              </div>
            ) : null}
          </div>
        </div>
        {searchString && !placesData?.length && !isLoading && (
          <span className="block w-full px-1 pt-4 text-sm text-red-500">
            Sorry, your search returned no results. Please try again.
          </span>
        )}
      </>
    );
  },
);

PropertySearchInput.displayName = "PropertySearchInput";
