import { createSlice } from "@reduxjs/toolkit";
import { getProposalDetails } from "./service";

const initialState = {
  isLoadingProposalDetails: false,
  proposalDetails: {},
};

export const proposalSlice = createSlice({
  name: "proposal",
  initialState,
  reducers: {
    setProposalDetails: (state, { payload }) => {
      state.proposalDetails[payload.proposalId] = payload.data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProposalDetails.pending, (state) => {
        state.isLoadingProposalDetails = true;
      })
      .addCase(
        getProposalDetails.fulfilled,
        (state, { payload, meta: { arg } }) => {
          state.proposalDetails[arg] = payload;
          state.isLoadingProposalDetails = false;
        },
      )
      .addCase(getProposalDetails.rejected, (state) => {
        state.isLoadingProposalDetails = false;
      });
  },
});

export const { setProposalDetails } = proposalSlice.actions;
export default proposalSlice.reducer;
