import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isFulfilled } from "@reduxjs/toolkit";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SignInModal from "@src/components/signInModal/SignInModal";
import ClaimHomeVerificationModal from "@src/components/propertyProfile/propertyProfileContent/components/claimHomeVerificationModal/ClaimHomeVerificationModal";
import { getRoutedPropertyById } from "@src/store/features/property/service";
import { getProposalDetails } from "@src/store/features/proposal/service";

export default function SaveProperty({ children, modalType }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const redirectTo = location.pathname?.split("?")?.[0]?.replace(/\/$/, "");
  const { proposal_id } = useParams();

  const searchParams = new URLSearchParams(location.search);
  const propertyId = searchParams.get("property_id") || false;

  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const property = useSelector((state) => state.property.currentRoutedProperty);

  const [showSigninModal, setShowSigninModal] = useState(false);
  const [isClaimHomeModalVisible, setIsClaimHomeModalVisible] = useState(false);

  const onClickClaimHomeModalOverlay = useCallback(
    ({ claimed = false } = {}) => {
      setIsClaimHomeModalVisible(false);
      const isChatScreen = redirectTo.includes("/conversation");
      if (isChatScreen && claimed) {
        /**
         * We're calling the proposal details api again because
         * this updates the proposal's owner_id to the user's id which
         * Starts the message api polling in Chat.jsx
         */
        dispatch(getProposalDetails(proposal_id));
      }
      const redirectLink = isChatScreen && !claimed ? "/" : redirectTo;
      navigate(redirectLink, { replace: true });
    },
    [redirectTo],
  );

  useEffect(() => {
    const handleShowClaimHomeModal = async () => {
      const response = await dispatch(getRoutedPropertyById(propertyId));
      if (isFulfilled(response)) {
        setIsClaimHomeModalVisible(true);
      } else {
        navigate("/");
      }
    };

    if (propertyId) {
      if (isLoggedIn) handleShowClaimHomeModal();
      else setShowSigninModal(true);
    }
  }, []);

  return (
    <>
      {children}
      {showSigninModal ? (
        <SignInModal
          isOpen={showSigninModal}
          redirectTo={redirectTo}
          type={modalType}
        />
      ) : null}
      {isClaimHomeModalVisible && property?.id ? (
        <ClaimHomeVerificationModal
          open={isClaimHomeModalVisible}
          title="Confirm Ownership"
          introText="Before you chat, please confirm that you own"
          outroText="."
          onClickOverlay={onClickClaimHomeModalOverlay}
          propertyId={property?.id}
          property={property}
        />
      ) : null}
    </>
  );
}
