import React from "react";
import { MdChevronRight } from "react-icons/md";

function TextValueDisplay({ label, value, onClick }) {
  return (
    <button
      className="flex cursor-pointer flex-row items-center justify-between gap-4"
      onClick={() => onClick()}
    >
      <div className="flex flex-col items-start gap-1">
        <header className="flex flex-row justify-between">
          <label className="font-semibold text-gray-600">{label}</label>
        </header>
        <p className="text-left text-gray-500">{value}</p>
      </div>
      <MdChevronRight className="shrink-0 self-center text-2xl text-neutral-500" />
    </button>
  );
}

export default TextValueDisplay;
