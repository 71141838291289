import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

function PropertyProfileEditorTextInput({
  id,
  title,
  value = "",
  onChange,
  placeholder = "",
}) {
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState(value);

  // Sync the prop value with internal state whenever the value changes
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    // if (inputRef.current) {
    //   inputRef.current.focus();
    // }
    setInputValue(value);
  }, []);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    const charLimit = 30;
    if (newValue.length <= charLimit) {
      setInputValue(e.target.value);
      if (onChange) {
        onChange(e.target.value);
      }
    } else {
      toast.error(`Headline cannot exceed ${charLimit} characters.`);
    }
  };

  return (
    <>
      <input
        ref={inputRef}
        onChange={handleInputChange}
        value={inputValue}
        placeholder={placeholder}
        className="w-full rounded-md p-3 font-content text-xl text-neutral-900 outline-none ring-1 ring-neutral-300 transition-all focus:ring-primary-500"
        id={id}
      />
    </>
  );
}

export default PropertyProfileEditorTextInput;
