import { Navigate, useLocation } from "react-router-dom";
import { getAuthTokenFromStorage } from "@src/utils/misc";

const LoggedOutRedirect = ({ children }) => {
  const isLoggedIn = !!getAuthTokenFromStorage();
  const location = useLocation();

  if (!isLoggedIn) {
    const currentUrl = encodeURIComponent(location.pathname + location.search);
    return <Navigate to={`/signin?redirectTo=${currentUrl}`} />;
  }

  return children;
};

export default LoggedOutRedirect;
