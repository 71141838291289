import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import { getUsersLikedHomes } from "@src/store/features/user/service";
import { Loader } from "./components/Loader";
import EmptyState from "./components/EmptyState";
import ListItem from "@src/pages/discover/ListItem";
import { resetNumberOfHomesUnliked } from "@src/store/features/user/slice";

export default function LikedHomes() {
  const dispatch = useDispatch();
  const {
    likedHomes,
    hasMoreLikedHomes,
    isLoadingLikedHomes,
    numberOfHomesUnliked,
  } = useSelector((state) => state.user);

  const loadMore = useCallback(
    ({ offset = undefined, limit = 5 }) => {
      if (isLoadingLikedHomes) return;
      dispatch(
        getUsersLikedHomes({
          offset: offset ?? likedHomes?.length - numberOfHomesUnliked,
          limit,
        }),
      );
    },
    [isLoadingLikedHomes, likedHomes?.length, numberOfHomesUnliked],
  );

  useEffect(() => {
    dispatch(resetNumberOfHomesUnliked());
    loadMore({ offset: 0, limit: window.innerWidth > 992 ? 10 : 5 });
  }, []);

  return (
    <div className="flex flex-col items-center">
      <div className="w-full max-w-6xl px-4 pb-6 pb-8 pt-[74px]">
        {isLoadingLikedHomes || likedHomes?.length ? (
          <p className="mb-[15px] ml-1 font-attention text-[21px]">
            Homes I like
          </p>
        ) : null}
        {!isLoadingLikedHomes && !likedHomes?.length ? <EmptyState /> : null}
        <InfiniteScroll
          loadMore={loadMore}
          hasMore={hasMoreLikedHomes}
          loader={
            <Loader
              key={0}
              hasData={!!likedHomes?.length}
              isLoadingLikedHomes={isLoadingLikedHomes}
            />
          }
          className="search-list flex flex-col gap-4"
          initialLoad={false}
        >
          {likedHomes.map((property) => (
            <ListItem key={property?.id} property={property} />
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
}
