import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import ZillowLogo from "@src/assets/svgs/ZillowLogo.jsx";
import { MdOutlineOpenInNew } from "react-icons/md";
import RealtorDotComLogo from "@src/assets/svgs/RealtorDotComLogo.jsx";
import RedFinLogo from "@src/assets/svgs/RedFinLogo.jsx";
import GreatSchoolsLogo from "@src/assets/svgs/GreatSchoolsLogo.jsx";
import axios from "axios";

function PropertyProfileSiteLinks({ property }) {
  const [realtorLink, setRealtorLink] = useState(null);
  const [zillowLink, setZillowLink] = useState(null);
  const [redfinLink, setRedfinLink] = useState(null);

  const [greatschoolsLink, setGreatschoolsLink] = useState(null);
  const getZillowLink = async () => {
    const addressLineOne = property.address_line_1.split(" ");
    const addressZip = property.zip;
    var zillowBuiltLink = "";
    if (property.unit_value !== "" && property.unit_value !== null) {
      zillowBuiltLink = `https://zillowstatic.com/autocomplete/v3/suggestions?q=${addressLineOne[0]}%20${addressLineOne[1]}%20${addressLineOne[2]}%20UNIT%20${property.unit_value}%20${addressZip}`;
    } else {
      zillowBuiltLink = `https://zillowstatic.com/autocomplete/v3/suggestions?q=${addressLineOne[0]}%20${addressLineOne[1]}%20${addressLineOne[2]}%20${addressZip}`;
    }
    const response = await axios.get(zillowBuiltLink);
    if (response.status === 200 && response.data.results[0]) {
      let zillowId = response.data.results[0].metaData.zpid;
      // setBackupLat(response.data.results[0].metaData.lat)
      // setBackupLng(response.data.results[0].metaData.lng)
      // setIsForSale(response.data.results[0].metaData.addressType === 'forsale_address')
      setZillowLink(`https://zillow.com/homes/${zillowId}_zpid`);
    }
  };

  const getRealtorLink = async () => {
    const addressLineOne = property.address_line_1.split(" ");
    var realtorBuiltLink = "";
    if (property.unit_value !== "" && property.unit_value !== null) {
      realtorBuiltLink = `https://parser-external.geo.moveaws.com/suggest?input=${addressLineOne[0]}%20${addressLineOne[1]}%20${addressLineOne[2]}%20%UNIT%20${property.unit_value}%20${property.zip}&client_id=rdc-home&limit=1`;
    } else {
      realtorBuiltLink = `https://parser-external.geo.moveaws.com/suggest?input=${addressLineOne[0]}%20${addressLineOne[1]}%20${addressLineOne[2]}%20${property.zip}&client_id=rdc-home&limit=1`;
    }
    const response = await axios.get(realtorBuiltLink);
    if (response.status === 200 && response.data.autocomplete[0]) {
      let realtorId = response.data.autocomplete[0].mpr_id;
      setRealtorLink(
        `https://realtor.com/realestateandhomes-detail/M${realtorId}`,
      );
    }
  };

  //THIS IS NOT WORKING
  const getRedfinLink = () => {
    const address = `${property.address_line_1}, ${property.city}, ${property.state} ${property.zip}`;
    const encodedAddress = encodeURIComponent(address);
    const redfinSearchUrl = `https://www.redfin.com/search?location=${encodedAddress}`;
    setRedfinLink(redfinSearchUrl);
  };

  const getGreatschoolsLink = async () => {
    setGreatschoolsLink(
      `https://www.greatschools.org/search/search.page?lat=${property.latitude}&locationType=coordinate&lon=${property.longitude}&st=public_charter&st=public&st=charter&state=${property.state}`,
    );
  };

  useEffect(() => {
    if (property.address_line_1) {
      getZillowLink();
      getRealtorLink();
      // getRedfinLink();
      getGreatschoolsLink();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mx-auto w-full max-w-2xl rounded-lg bg-white p-5 pb-5 pt-3 ring-1 ring-primary-500/40 @container">
      <h2 className="mb-3 text-center font-attention text-neutral-800">
        View on
      </h2>
      <div className="flex flex-col justify-around @md:flex-row">
        {zillowLink && (
          <a
            href={zillowLink}
            target="_blank"
            className="group relative flex flex-1 items-center justify-center gap-1 rounded-lg p-2 py-3 transition-colors sm:hover:bg-primary-500/10"
          >
            <ZillowLogo className="h-5" />
            <MdOutlineOpenInNew className="absolute right-2 top-1/2 h-5 w-5 -translate-y-1/2 fill-neutral-400 opacity-0 transition-all group-hover:opacity-100" />
          </a>
        )}
        {realtorLink && (
          <a
            href={realtorLink}
            target="_blank"
            className="group relative flex flex-1 items-center justify-center rounded-lg p-2 py-3 transition-colors sm:hover:bg-primary-500/10"
          >
            <RealtorDotComLogo className="h-5" />
            <MdOutlineOpenInNew className="absolute right-2 top-1/2 h-5 w-5 -translate-y-1/2 fill-neutral-400 opacity-0 transition-all group-hover:opacity-100" />
          </a>
        )}
        {greatschoolsLink && (
          <a
            href={greatschoolsLink}
            target="_blank"
            className="group relative flex flex-1 items-center justify-center rounded-lg p-2 py-3 transition-colors sm:hover:bg-primary-500/10"
          >
            <GreatSchoolsLogo className="h-10" />
            <MdOutlineOpenInNew className="absolute right-2 top-1/2 h-5 w-5 -translate-y-1/2 fill-neutral-400 opacity-0 transition-all group-hover:opacity-100" />
          </a>
        )}
        {/*{redfinLink && (*/}
        {/*  <a*/}
        {/*    href={redfinLink}*/}
        {/*    target="_blank"*/}
        {/*    rel="noopener noreferrer"*/}
        {/*    className="group relative flex flex-1 justify-center rounded-lg p-2 py-3 transition-colors sm:hover:bg-primary-500/10"*/}
        {/*  >*/}
        {/*    <RedFinLogo className="h-5" />*/}
        {/*    <MdOutlineOpenInNew className="absolute right-2 top-1/2 h-5 w-5 -translate-y-1/2 fill-neutral-400 opacity-0 transition-all group-hover:opacity-100" />*/}
        {/*  </a>*/}
        {/*)}*/}
      </div>
    </div>
  );
}

export default PropertyProfileSiteLinks;
