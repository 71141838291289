import React, { useEffect, useMemo, useState } from "react";
import { MdFlag, MdMarkunreadMailbox, MdOutlineEdit } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import SignInModal from "@src/components/signInModal/SignInModal.jsx";
import ClaimHomeVerificationModal from "@src/components/propertyProfile/propertyProfileContent/components/claimHomeVerificationModal/ClaimHomeVerificationModal.jsx";
import { SIGNIN_MODAL_TYPE } from "@src/utils/constants.js";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setEditProfilePromptLastShown } from "@src/store/features/user/slice.js";

function PropertyProfileCallsToAction({ property }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const loggedInUser = useSelector((state) => state.user.user);
  const homes = useSelector((state) => state.user.homes);
  const [signinModalType, setSigninModalType] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");
  const [isClaimHomeModalVisible, setIsClaimHomeModalVisible] = useState(false);
  const [isClaiming, setIsClaiming] = useState(false);

  const onClickSignModalOverlay = () => {
    setSigninModalType("");
    setRedirectUrl("");
  };

  const onClickClaimHomeModalOverlay = () => {
    setIsClaimHomeModalVisible(false);
    setRedirectUrl("");
  };

  const handleClaimHome = () => {
    if (!isLoggedIn) {
      setRedirectUrl(`/profile/${property.id}?claimHome=true`);
      setSigninModalType(SIGNIN_MODAL_TYPE.PROPERTY);
      return;
    }
    setIsClaimHomeModalVisible(true);
  };

  const contactOwnerLabel = useMemo(() => {
    if (
      property?.owner_id === property?.latestPaidProposal?.owner_id ||
      (!property?.owner_id && property?.latestPaidProposal?.id)
    )
      return "Chat with owner";

    return "Contact owner";
  }, [property?.owner_id, property?.latestPaidProposal?.owner_id]);

  const handleChat = () => {
    setRedirectUrl(`/conversations/create/${property?.id}`);
    if (!isLoggedIn) {
      setSigninModalType(SIGNIN_MODAL_TYPE.CHAT);
      return;
    }

    if (
      property?.owner_id === property?.latestPaidProposal?.owner_id ||
      (!property?.owner_id && property?.latestPaidProposal?.id)
    ) {
      navigate(`/conversation/${property?.latestPaidProposal?.id}`);
    } else if (property?.owner_id !== loggedInUser.id) {
      navigate(`/conversations/create/${property?.id}`);
    } else toast.error("You own this property!");
  };

  function handleEdit() {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("edit", "true");
    setSearchParams(newSearchParams);
  }

  useEffect(() => {
    const isCurrentlyClaiming = searchParams.get("claiming");
    if (isCurrentlyClaiming) {
      setIsClaiming(true);
    }
  }, [searchParams]);

  return (
    <div className="w-full @container">
      <div className="flex w-full flex-col items-center justify-center overflow-hidden @xl:flex-row">
        {loggedInUser?.id === property?.owner_id ? (
          <div className="flex w-full flex-1 flex-col items-center justify-center p-5">
            <h3 className="mb-3 hidden text-center font-attention text-neutral-800 md:block">
              This is your home.
            </h3>
            <button
              onClick={handleEdit}
              className="flex w-full max-w-fit items-center justify-center gap-2.5 whitespace-nowrap rounded-lg bg-black/80 px-12 py-3.5 font-attention text-white"
            >
              {/*<MdOutlineChat className="shrink-0 translate-y-0.5" />{" "}*/}
              <MdOutlineEdit className="h-5 w-5 shrink-0 fill-white" /> Edit
              Profile
            </button>
          </div>
        ) : (
          <>
            {!isClaiming && (
              <div className="flex w-full flex-1 flex-col items-center justify-center p-5">
                <h3 className="mb-3 text-center font-attention text-neutral-800">
                  Interested in this home?
                </h3>
                <button
                  onClick={handleChat}
                  className="flex w-full max-w-fit items-center justify-center gap-2.5 whitespace-nowrap rounded-lg bg-primary-500 px-12 py-3.5 font-attention text-white md:max-w-full"
                >
                  {/*<MdOutlineChat className="shrink-0 translate-y-0.5" />{" "}*/}
                  <MdMarkunreadMailbox className="shrink-0" />{" "}
                  {contactOwnerLabel}
                </button>
              </div>
            )}
          </>
        )}

        {((!isLoggedIn && !property?.owner_id) ||
          (isLoggedIn && !property?.owner_id && homes.length < 1)) &&
          isClaiming && (
            <div className="flex w-full flex-1 flex-col items-center p-5">
              {/*<button className="flex w-full items-center justify-center gap-2.5 whitespace-nowrap rounded-lg bg-[#d3c295] px-12 py-3.5 font-attention text-[#4b4535]">*/}
              {/*  <MdOutlineFlag className="shrink-0" /> Claim Home*/}
              {/*</button>*/}
              <button
                onClick={handleClaimHome}
                className="flex w-full max-w-fit items-center justify-center gap-2.5 whitespace-nowrap rounded-lg bg-primary-500 px-12 py-3.5 font-attention text-white md:max-w-full"
              >
                <MdFlag className="shrink-0" /> Claim home
              </button>
            </div>
          )}
      </div>
      {signinModalType ? (
        <SignInModal
          isOpen={!!signinModalType}
          handleClose={onClickSignModalOverlay}
          redirectTo={redirectUrl}
          type={signinModalType}
        />
      ) : null}
      {isClaimHomeModalVisible ? (
        <ClaimHomeVerificationModal
          open={isClaimHomeModalVisible}
          onClickOverlay={onClickClaimHomeModalOverlay}
          propertyId={property?.id}
          property={property}
        />
      ) : null}
    </div>
  );
}

export default PropertyProfileCallsToAction;
