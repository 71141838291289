export default function EmailIcon({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="#5f6368"
    >
      <path d="M9.75 22.8203H20.25V21.3203H9.75V22.8203ZM9.75 18.3203H26.25V16.8203H9.75V18.3203ZM9.75 13.8203H26.25V12.3203H9.75V13.8203ZM4.5 32.6858V9.49356C4.5 8.80306 4.73125 8.22656 5.19375 7.76406C5.65625 7.30156 6.23275 7.07031 6.92325 7.07031H29.0768C29.7673 7.07031 30.3438 7.30156 30.8063 7.76406C31.2688 8.22656 31.5 8.80306 31.5 9.49356V25.6471C31.5 26.3376 31.2688 26.9141 30.8063 27.3766C30.3438 27.8391 29.7673 28.0703 29.0768 28.0703H9.1155L4.5 32.6858ZM8.475 26.5703H29.0768C29.3078 26.5703 29.5194 26.4742 29.7116 26.2819C29.9039 26.0897 30 25.8781 30 25.6471V9.49356C30 9.26256 29.9039 9.05094 29.7116 8.85869C29.5194 8.66644 29.3078 8.57031 29.0768 8.57031H6.92325C6.69225 8.57031 6.48063 8.66644 6.28838 8.85869C6.09613 9.05094 6 9.26256 6 9.49356V29.0367L8.475 26.5703Z" />
    </svg>
  );
}
