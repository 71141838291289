import React from "react";

function BathroomsIcon({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 -960 960 960"
      width="24px"
      fill="currentColor"
    >
      <path d="M284.62-557.69q-25.31 0-42.66-17.4-17.34-17.4-17.34-41.83 0-25.07 17.34-42.92 17.35-17.85 42.66-17.85 25.3 0 42.65 17.74 17.35 17.74 17.35 42.65 0 24.92-17.35 42.26-17.35 17.35-42.65 17.35Zm-60 437.69q-17 0-28.5-12.27t-11.5-29.27q-27.62 0-46.12-19.43Q120-200.4 120-227.69v-178.46h104.62v-30.77q0-31.08 21.76-53.62 21.77-22.54 52.85-22.54 18.46 0 34.69 8.39 16.23 8.38 28.7 22.38l40.61 47.39q8 8.22 15.5 15.41 7.5 7.19 16.5 13.36H720v-338.31q0-22.46-15-39T668.31-800q-10.35 0-19.83 4.42-9.48 4.43-16.79 11.73l-50 51.16q5 17.39 2 34.27-3 16.88-12 31.65l-82.31-84.31q14-9.24 30-11.8 16-2.57 32 3.59l50-51.33q13.39-13.82 30.54-21.6 17.15-7.78 36.39-7.78 38.61 0 65.15 28Q760-784 760-744.46v338.31h80v178.46q0 27.29-18.5 46.72t-46.12 19.43q0 17-11.5 29.27T735.38-120H224.62Zm-40-81.54h590.76q10.77 0 17.7-7.69 6.92-7.69 6.92-18.46v-138.46H160v138.46q0 10.77 6.92 18.46 6.93 7.69 17.7 7.69Zm0 0H160h640-615.38Z" />
    </svg>
  );
}

export default BathroomsIcon;
