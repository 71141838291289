import React, { useState, useMemo, useCallback, useEffect } from "react";
import RangeSelector from "@src/components/RangeSelector.jsx";
import FilterApplyButton from "@src/components/filters/FilterApplyButton.jsx";
import { useSelector, useDispatch } from "react-redux";
import { setIsTraditionalSearchingFailure } from "@src/store/features/search/slice.js";

function RangeFilter({
  label, // Label for the range selector (e.g., "Price Range", "Square Feet")
  rangeValues, // The list of possible values (e.g., price_range.values or square_feet.values)
  min,
  max,
  initialRangeValues, // The initial range values from search state
  format, // Formatting (e.g., prepend "$", append "Sqft", etc.)
  onApplyFilter, // Callback to apply the filter
}) {
  const dispatch = useDispatch();
  const { isFilterSearching, isTraditionalSearchingFailure } = useSelector(
    (state) => state.search,
  );
  const [localRangeInputValues, setLocalRangeInputValues] =
    useState(initialRangeValues);

  const isAnySelectorChanged = useMemo(() => {
    return (
      localRangeInputValues[0] !== initialRangeValues[0] ||
      localRangeInputValues[1] !== initialRangeValues[1]
    );
  }, [localRangeInputValues, initialRangeValues]);

  const applyAndSearch = () => {
    const valuesToSave = { setting: localRangeInputValues };
    const valuesToSearch = {
      min: String(rangeValues[localRangeInputValues[0]]),
      max: String(rangeValues[localRangeInputValues[1]]),
    };
    onApplyFilter({ valuesToSave, valuesToSearch });
  };

  const handleLocalRangeChange = useCallback((indexes) => {
    setLocalRangeInputValues(indexes);
  }, []);

  useEffect(() => {
    dispatch(setIsTraditionalSearchingFailure(false));
  }, []);

  return (
    <div
      className={`flex w-full flex-col gap-12 ${isFilterSearching && "pointer-events-none opacity-70"}`}
    >
      <div className="flex flex-col gap-6">
        {isTraditionalSearchingFailure && (
          <span className="block w-full rounded-lg bg-orange-300/40 p-3 px-2 text-center text-sm text-orange-800">
            Sorry, your search returned no results. Please adjust your filters
            and try again.
          </span>
        )}

        <RangeSelector
          label={label}
          sliderValues={localRangeInputValues}
          format={format}
          min={min}
          max={max}
          steps={rangeValues}
          onChange={handleLocalRangeChange}
        />
      </div>

      <FilterApplyButton
        onClick={applyAndSearch}
        disabled={!isAnySelectorChanged}
      />
    </div>
  );
}

export default RangeFilter;
