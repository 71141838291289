import React, { useState, useEffect, useCallback } from "react";
import debounce from "lodash.debounce";
import { formatNumber, unformatNumber } from "@src/utils/misc";
import SingleSlider from "@src/components/singleSlider/SingleSlider";

function PropertyProfileEditorIncrementer({ prop, onChange }) {
  const {
    value,
    labelSingular,
    labelPlural,
    icon: Icon,
    min,
    max = 100,
    interval = 1,
    roundTo,
    isInputWithSlider = false,
    placeholder = "",
  } = prop;
  const [inputValue, setInputValue] = useState(value);

  // Sync the local state with the prop value
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const onChangeDebounced = debounce((newValue) => {
    if (onChange) {
      onChange(newValue);
    }
  }, 1000);

  const handleIncrement = () => {
    let newValue = inputValue + interval;
    if (roundTo) {
      newValue = Math.round(newValue / roundTo) * roundTo;
    }
    setInputValue(newValue);
    //onChangeDebounced(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  const handleDecrement = () => {
    let newValue = inputValue > 0 ? inputValue - interval : 0; // Prevent negative values
    if (roundTo) {
      newValue = Math.round(newValue / roundTo) * roundTo; // Round to the nearest roundTo value
    }
    newValue = newValue < min ? min : newValue;
    setInputValue(newValue); // Prevent values below the minimum
    // onChangeDebounced(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  const handleInputChange = useCallback((e) => {
    const unformattedValue = unformatNumber(e.target.value);
    let newValue = unformattedValue;
    if (newValue < 0) newValue = 0;
    else if (newValue > max) newValue = max;

    setInputValue(newValue);
    //onChangeDebounced(newValue);
  }, []);

  const handleSliderChange = useCallback((value) => {
    setInputValue(value[0]);
    //onChangeDebounced(value[0]);
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <div
        className={`flex w-full items-center ${!isInputWithSlider && "justify-between"} gap-2`}
      >
        <div className="flex items-center gap-5">
          {Icon && <Icon className="h-8 w-8 fill-neutral-400" />}
          {!isInputWithSlider ? (
            <span className="text-lg font-semibold capitalize text-neutral-700">
              {inputValue} {inputValue <= 1 ? labelSingular : labelPlural}
            </span>
          ) : null}
        </div>
        {!isInputWithSlider ? (
          <div className="flex select-none">
            <button
              onClick={handleDecrement}
              className="h-12 w-12 rounded-bl-lg rounded-tl-lg border border-neutral-300 border-r-transparent bg-neutral-100 font-content text-2xl font-light text-neutral-500"
            >
              -
            </button>
            <button
              onClick={handleIncrement}
              className="h-12 w-12 rounded-br-lg rounded-tr-lg border border-neutral-300 bg-neutral-100 font-content text-2xl font-light text-neutral-600"
            >
              +
            </button>
          </div>
        ) : (
          <div className="flex items-center gap-2">
            <div className="mt-1 rounded-md ring-0 ring-transparent transition-shadow focus-within:ring-2 focus-within:ring-[#8E9991]/40 focus-within:ring-offset-2">
              <input
                value={formatNumber(inputValue)}
                onChange={handleInputChange}
                className="peer inline-block max-w-32 resize-none rounded-md border border-[#8E9991] bg-transparent p-3 font-content outline-0 transition-all focus:border-[#8E9991]/50"
                placeholder={placeholder}
              />
            </div>
            <span className="text-lg font-semibold capitalize text-neutral-700">
              {inputValue <= 1 ? labelSingular : labelPlural}
            </span>
          </div>
        )}
      </div>
      {/*{isInputWithSlider ? (*/}
      {/*  <SingleSlider*/}
      {/*    onValueChange={handleSliderChange}*/}
      {/*    min={min}*/}
      {/*    max={max}*/}
      {/*    step={interval}*/}
      {/*    value={inputValue}*/}
      {/*  />*/}
      {/*) : null}*/}
    </div>
  );
}

export default PropertyProfileEditorIncrementer;
