import React, { useState, useEffect, useCallback } from "react";
import debounce from "lodash.debounce";
import { formatNumber, unformatNumber } from "@src/utils/misc";
import SingleSlider from "@src/components/singleSlider/SingleSlider";

function PropertyProfileTextSliderInput({ prop, onChange }) {
  const {
    value,
    defaultValue,
    labelSingular,
    labelPlural,
    icon: Icon,
    isCurrency,
    min,
    max = 100,
    maxSliderValue,
    interval = 1,
    roundTo,
    isInputWithSlider = false,
    placeholder = "",
    values,
    steps,
  } = prop;
  const [inputValue, setInputValue] = useState(value);
  const [userModified, setUserModified] = useState(false);
  const populatedSliderValue = (value) => {
    if (value >= 0 && values?.length && steps?.length) {
      /**
       * This loop finds the correct step to use as a modulus when finding the closest number to "value" in the "values" array
       * Ex: If value is 483718 and step is set to 25000, "value - (value % step)" will be 475000 which should be in the values array
       * If we do not do this and use 483718 directly, the slider will not recognize this value from its values array and will place the
       * slider at the very start by default which will be incorrect
       */
      let step = steps[0].step;
      for (let i = steps?.length - 1; i >= 0; i--) {
        if (value >= steps[i]?.gt) {
          step = steps[i].step;
          break;
        }
      }

      return values?.findIndex((item) => item === value - (value % step));
    }
    return 0;
  };
  const [sliderValue, setSliderValue] = useState(populatedSliderValue(value));

  // Sync the local state with the prop value
  useEffect(() => {
    let valueToSet = !userModified && !value ? defaultValue : value;
    setInputValue(valueToSet);
    setSliderValue(populatedSliderValue(valueToSet));
  }, [value]);

  const onChangeDebounced = debounce((newValue) => {
    setUserModified(true);
    if (onChange) {
      onChange(newValue);
    }
  }, 300);

  const handleIncrement = () => {
    let newValue = inputValue + interval;
    if (roundTo) {
      newValue = Math.round(newValue / roundTo) * roundTo;
    }
    setInputValue(newValue);
    onChangeDebounced(newValue);
  };

  const handleDecrement = () => {
    let newValue = inputValue > 0 ? inputValue - interval : 0; // Prevent negative values
    if (roundTo) {
      newValue = Math.round(newValue / roundTo) * roundTo; // Round to the nearest roundTo value
    }
    newValue = newValue < min ? min : newValue;
    setInputValue(newValue); // Prevent values below the minimum
    onChangeDebounced(newValue);
  };

  const handleInputChange = useCallback((e) => {
    const unformattedValue = unformatNumber(e.target.value);
    let newValue = unformattedValue;
    if (newValue < 0 || isNaN(newValue)) newValue = 0;
    else if (newValue > max) newValue = max;

    if (newValue >= 0 && isInputWithSlider) {
      setSliderValue(populatedSliderValue(newValue));
    }
    setInputValue(newValue);
    onChangeDebounced(newValue);
  }, []);

  const handleSliderChange = useCallback((value) => {
    setSliderValue(value);
    const actualValue = values?.[value?.[0]];

    setInputValue(actualValue);
    onChangeDebounced(actualValue);
  }, []);

  return (
    <div className="mx-auto flex max-w-xs flex-col gap-4">
      <div className={`flex w-full items-center justify-center gap-2`}>
        {!isInputWithSlider ? (
          <div className="flex select-none">
            <button
              onClick={handleDecrement}
              className="h-12 w-12 rounded-bl-lg rounded-tl-lg border border-neutral-300 border-r-transparent bg-neutral-100 font-content text-2xl font-light text-neutral-500"
            >
              -
            </button>
            <button
              onClick={handleIncrement}
              className="h-12 w-12 rounded-br-lg rounded-tr-lg border border-neutral-300 bg-neutral-100 font-content text-2xl font-light text-neutral-600"
            >
              +
            </button>
          </div>
        ) : (
          <div className="mb-2 flex w-fit items-center gap-2">
            {isCurrency && <span className="text-lg text-neutral-500">$</span>}
            <div className="mt-1 flex flex-1 items-center rounded-md ring-0 ring-transparent transition-shadow focus-within:ring-2 focus-within:ring-[#8E9991]/40 focus-within:ring-offset-2">
              <div className="flex w-fit items-center rounded-md border border-[#8E9991] pr-3">
                <input
                  className="peer inline-block max-w-[8rem] resize-none bg-transparent p-3 text-right font-content outline-none outline-0 transition-all focus:border-[#8E9991]/50"
                  inputMode="numeric"
                  onChange={handleInputChange}
                  placeholder={placeholder}
                  value={formatNumber(inputValue)}
                />
                <span className="text-lg font-semibold text-neutral-700">
                  {inputValue <= 1 ? labelSingular : labelPlural}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
      {isInputWithSlider ? (
        <SingleSlider
          onValueChange={handleSliderChange}
          min={0}
          max={maxSliderValue}
          step={1}
          value={sliderValue}
        />
      ) : null}
    </div>
  );
}

export default PropertyProfileTextSliderInput;
