import { useLocation } from "react-router-dom";
import { useLayoutEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDiscoverTabsHeight } from "@src/store/features/search/slice";
import { MdMap, MdViewList } from "react-icons/md";
import DiscoverTab from "./DiscoverTab.jsx";

function DiscoverTabs() {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const { properties } = useSelector((state) => state.search);
  const sliderPosition =
    location.pathname === "/discover/list" ? "left-0" : "left-1/2";

  useLayoutEffect(() => {
    if (ref?.current?.offsetHeight) {
      dispatch(setDiscoverTabsHeight(ref?.current?.offsetHeight));
    }
  }, []);

  return (
    <div
      ref={ref}
      className={`fixed bottom-0 flex w-full flex-row border-t border-gray-300 bg-white font-attention text-lg ${properties.length === 0 && "pointer-events-none opacity-0"}`}
    >
      <div
        className={`absolute left-0 top-0 h-1 w-1/2 rounded-bl-lg rounded-br-lg bg-[#B2A585] ${sliderPosition} transition-all`}
      ></div>
      <DiscoverTab to="/discover/list" icon={MdViewList}>
        List
      </DiscoverTab>
      <DiscoverTab to="/discover/map" icon={MdMap}>
        Map
      </DiscoverTab>
    </div>
  );
}

export default DiscoverTabs;
