import property_perks from "@src/data/property_perks.js";
import React, { useEffect, useState } from "react";
import SelectableTag from "@src/components/SelectableTag.jsx";

function PropertyProfilePerksDisplay({ property }) {
  const [perks, setPerks] = useState();

  useEffect(() => {
    setPerks(
      property_perks?.filter(
        (item) =>
          property.owner_perks?.includes(item.id) ||
          (property?.owner_perks === null && property?.[item.id]),
      ),
    );
  }, [property.owner_perks]);

  if (!property.owner_perks || !Object.keys(property.owner_perks).length)
    return null;

  return (
    <>
      <h2 className="mb-4 font-attention text-xl text-neutral-800">Perks</h2>
      <div className="flex flex-wrap gap-2">
        {perks?.map((perk, index) => (
          <SelectableTag emoji={perk.emoji} text={perk.text} key={index} />
        ))}
      </div>
    </>
  );
}

export default PropertyProfilePerksDisplay;
