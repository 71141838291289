import { useCallback, useMemo, useState, useRef } from "react";
import { motion } from "framer-motion";

import LockIcon from "@src/assets/svgs/LockIcon";
import { sendUserOTPEmail } from "@src/api/user.js";
import { EMAIL_REGEX, SIGNIN_MODAL_TYPE } from "@src/utils/constants.js";
import ArrowBackIcon from "@src/assets/svgs/ArrowBackIcon";
import FavoriteIcon from "@src/assets/svgs/FavoriteIcon";
import LockIconOutlined from "@src/assets/svgs/LockIconOutlined";
import SendEmailIcon from "@src/assets/svgs/SendEmailIcon";
import { MdFlag } from "react-icons/md";

export default function EmailFormModal({
  setIsFirstStep,
  email,
  setEmail,
  redirectTo = "/",
  type,
}) {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showExplanation, setShowExplanation] = useState(false);
  const inputRef = useRef(null);

  const toggleExplanation = useCallback(() => {
    setShowExplanation(!showExplanation);
  }, [showExplanation]);

  const onChangeValue = useCallback((event) => {
    setEmail(event.target.value);
  }, []);

  const handleSubmitEmail = useCallback(
    async (e) => {
      e.preventDefault();
      if (isLoading) return;

      const isEmailvalid = EMAIL_REGEX.test(email);
      if (!isEmailvalid) {
        if (email) setError("Please enter a valid email!");
        else setError("Email is required!");

        return;
      }
      setError("");
      setIsLoading(true);

      try {
        await sendUserOTPEmail({
          payload: {
            email,
            redirect_to: redirectTo,
          },
        });
        setIsFirstStep(false);
      } catch (error) {
        console.log("error", error);
      }
      setIsLoading(false);
    },
    [email, isLoading],
  );

  const title = useMemo(() => {
    switch (type) {
      case SIGNIN_MODAL_TYPE.CHAT:
        return "Before you chat...";
      case SIGNIN_MODAL_TYPE.PROPERTY:
        return "Claim this home";
      case SIGNIN_MODAL_TYPE.FAVORITE:
        return "Sign up to save your most-loved homes";
      default:
        return "Sign In/Up";
    }
  }, [type]);

  const subTitleFirstHalf = useMemo(() => {
    switch (type) {
      case SIGNIN_MODAL_TYPE.PROPERTY:
        return "Enter your email";
      case SIGNIN_MODAL_TYPE.CHAT:
        return "Please provide your email";
      case SIGNIN_MODAL_TYPE.FAVORITE:
        return "";
      default:
        return "Please provide your email";
    }
  }, [type]);

  const subTitleSecondHalf = useMemo(() => {
    switch (type) {
      case SIGNIN_MODAL_TYPE.CHAT:
        return "so we can notify you when the buyer responds.";
      case SIGNIN_MODAL_TYPE.PROPERTY:
        return "to unlock your home and own your data.";
      case SIGNIN_MODAL_TYPE.FAVORITE:
        return "";
      default:
        return "and we’ll send you a secure link.";
    }
  }, [type]);

  const icon = useMemo(() => {
    switch (type) {
      case SIGNIN_MODAL_TYPE.CHAT:
      case SIGNIN_MODAL_TYPE.PROPERTY:
        return <MdFlag className="h-9 w-9 text-primary-500" />;
      case SIGNIN_MODAL_TYPE.FAVORITE:
        return <FavoriteIcon className="mt-[3px]" />;
      default:
        return <LockIconOutlined />;
    }
  }, [type]);

  return (
    <div className="modal-shadow flex flex-col items-center rounded-2xl bg-white p-[21px] pt-12">
      <div className="absolute left-1/2 top-0 flex h-[70px] w-[70px] -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full border border-white bg-neutral-100 ring-1 ring-neutral-300">
        {icon}
      </div>
      <div className="flex flex-col gap-2 px-2 text-center font-content text-sm text-neutral-700">
        <h2 className="mb-3 mt-2 max-w-[40ch] text-balance text-center font-attention text-2xl text-neutral-600">
          {title}
        </h2>
        <div>
          <span className="font-semibold">{subTitleFirstHalf}</span>{" "}
          {subTitleSecondHalf}
        </div>
        <div className="flex flex-col font-content text-sm text-neutral-700">
          <form className="mx-auto w-full" onSubmit={handleSubmitEmail}>
            <div className="mt-2 flex h-[44px] w-full items-center justify-between self-center rounded-full border border-gold-500 bg-[#FFFFFF7D] pl-4 pr-1">
              <input
                ref={inputRef}
                type="email"
                value={email}
                onChange={onChangeValue}
                placeholder="Email"
                disabled={isLoading}
                className={`disabled:transparent w-full bg-transparent font-content text-base focus:border-none focus:outline-none ${isLoading && "text-neutral-500"}`}
              />

              <button
                className="ml-2 flex h-9 w-9 shrink-0 items-center justify-center rounded-full bg-gold-400 opacity-55"
                onClick={handleSubmitEmail}
                disabled={isLoading}
              >
                {!isLoading ? (
                  <SendEmailIcon className="ml-1 mt-[1px] h-6 w-6" />
                ) : (
                  <div className="h-5 w-5 animate-spin rounded-full border border-2 border-gold-700 border-b-transparent border-r-transparent border-t-transparent transition duration-100"></div>
                )}
              </button>
            </div>
          </form>
          {error ? (
            <motion.div
              key="popup-container"
              initial={{ opacity: 0, scale: 1, y: -10 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
            >
              <p className="mt-2 text-sm text-red-500">{error}</p>
            </motion.div>
          ) : null}
          <div className="mt-2 flex items-start justify-center gap-1 opacity-75">
            <LockIcon className="mt-0.5 h-[13px] w-[13px]" />
            <p className="text-xs text-gray-800">
              Your email is kept private and secure.
            </p>
          </div>
          {/* COMMENTING OUT OR NOW TO MERGE INTO MAIN */}
          {/*<div*/}
          {/*  className={`${showExplanation ? "mt-[7px]" : "mt-[31px]"} flex w-full flex-col text-sm text-gold-700 opacity-75`}*/}
          {/*>*/}
          {/*  {showExplanation ? (*/}
          {/*    <>*/}
          {/*      <div onClick={toggleExplanation}>*/}
          {/*        <ArrowBackIcon />*/}
          {/*      </div>*/}
          {/*      <p className="text-center">Explanation goes here</p>*/}
          {/*    </>*/}
          {/*  ) : (*/}
          {/*    <p onClick={toggleExplanation} className="text-center underline">*/}
          {/*      Why do you need my email?*/}
          {/*    </p>*/}
          {/*  )}*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
}
