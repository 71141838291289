import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getRoutedPropertyById } from "../store/features/property/service.js";
import { isFulfilled } from "@reduxjs/toolkit";
import NotFound from "./notFound/NotFound.jsx";
import { AnimatePresence, motion } from "framer-motion";
import SearchSpinner from "@src/components/searchSpinner/SearchSpinner.jsx";
import PropertyProfileContent from "@src/components/propertyProfile/propertyProfileContent/PropertyProfileContent.jsx";
import EditProfilePrompt from "@src/components/editProfilePrompt/EditProfilePrompt.jsx";
import AddFirstPhotoPrompt from "@src/components/addFirstPhotoPrompt/AddFirstPhotoPrompt.jsx";
import PropertyProfileEditScreensModal from "@src/components/propertyProfile/propertyProfileEditor/PropertyProfileEditScreensModal.jsx";

function PropertyProfile() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [notFound, setNotFound] = useState(false);
  const { currentRoutedProperty } = useSelector((state) => state.property);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const loggedInUser = useSelector((state) => state.user.user);
  const [signinModalType, setSigninModalType] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const getPropertyDetails = async () => {
    const response = await dispatch(getRoutedPropertyById(id));
    if (!isFulfilled(response)) setNotFound(true);
  };

  useEffect(() => {
    setNotFound(false);
    getPropertyDetails();
  }, [id]);

  useEffect(() => {
    if (!searchParams.get("edit")) {
      setIsEditing(false);
    }
  }, [searchParams]);

  if (notFound) return <NotFound />;

  return (
    <div className="min-h-full">
      {currentRoutedProperty ? (
        <>
          <EditProfilePrompt property={currentRoutedProperty} />
          <PropertyProfileEditScreensModal property={currentRoutedProperty} />

          <PropertyProfileContent
            display="page"
            property={currentRoutedProperty}
          />
        </>
      ) : (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute left-0 top-0 h-full w-full bg-white/90"
          >
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
              <SearchSpinner label="Fetching property..." />
            </div>
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
}

export default PropertyProfile;
