const min = 0;
const max = 10000000;
const defaultValues = [25, 34];
const steps = [
  { gt: 50000, step: 25000 },
  { gt: 500000, step: 50000 },
  { gt: 1000000, step: 250000 },
];

let storedValues = null;

const values = () => {
  if (storedValues) return storedValues;
  const rangeValues = [];
  let currentValue = min;

  for (let i = 0; i < steps.length; i++) {
    const { gt, step } = steps[i];
    let nextThreshold = i < steps.length - 1 ? steps[i + 1].gt : max;
    while (currentValue < nextThreshold) {
      rangeValues.push(currentValue);
      currentValue += step;
    }
  }

  // Ensure the final value is the specified maximum (10,000,000)
  rangeValues.push(max);
  storedValues = rangeValues;
  return rangeValues;
};

const price_range = {
  min,
  max,
  steps,
  defaultValues,
  values: values(),
};

export default price_range;
