import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUserInfo, setUserToken } from "../store/features/user/slice";
import { getLoggedInUserInfo } from "@src/api/user";
import { getAuthTokenFromStorage } from "@src/utils/misc";

export const Initializer = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = getAuthTokenFromStorage();
    if (token) {
      const getUser = async () => {
        try {
          const response = await getLoggedInUserInfo();
          dispatch(setUserInfo(response));
          dispatch(setUserToken(token)); // This uses the `token` correctly
        } catch (error) {
          console.log(error);
        }
      };
      getUser();
    }
  }, []);

  return children;
};
